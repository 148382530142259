import React, {forwardRef, useRef, useImperativeHandle, useEffect} from 'react';
import TextField from "@material-ui/core/TextField";

function TextInput({ id, label, required, value,height=30, onChange,onKeyPress = null, onFocus, helperText, hidden = false, submitting,  type = "text", placeholder = "", multiline = false, rowsMax = 1 }, ref) {

    const inputRef = useRef();

    useImperativeHandle(ref, () => ({
        target: inputRef.current
    }))
    const kurdish = id.substr(0,2) == "kr" || id.substr(0,2) == "ku";
    const arabic = id.substr(0,2) == "ar" || id.substr(0,2) == "ar";
    return (
        <TextField
            inputRef={inputRef}
            id={id}
            label={label}
            required={required}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            hidden={hidden}
            onFocus={onFocus}
            onKeyPress={onKeyPress}
            multiline={multiline}
            margin="normal"
            className={(arabic || kurdish) && "kurdish_font"}
            InputLabelProps={{
                shrink: true,
            }}
            rowsMax={rowsMax}
            inputProps={{
                style:{height}
            }}
            type={type}
            helperText={helperText}
            disabled={submitting}
            fullWidth
        />
    );
}

export default forwardRef(TextInput);
