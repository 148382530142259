import React, {useContext} from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from "../../components/Navigation";
import {AuthContext} from "../../context/AuthContext";
import {RestaurantsContext} from "../../context/RestsurantsContext";

const SideBarContent = () => {

    const {selectedRestaurant} = useContext(RestaurantsContext);
    const {rules} = useContext(AuthContext);

    const navigationMenus = [
        {
            name: 'sidebar.dashboard',
            type: 'item',
            link: '/app/dashboard',
            icon: 'view-dashboard'
        },
        rules.restaurants && {
            name: 'app.restaurants',
            type: 'section',
            children: [
                rules.restaurants.view && {
                    name: 'app.restaurants.view',
                    type: 'item',
                    link: '/app/restaurants/view',
                    icon: 'view-dashboard'
                },

                rules.restaurants.add && {
                    name: 'app.restaurants.add',
                    type: 'item',
                    link: '/app/restaurants/add',
                    icon: 'view-dashboard'
                },

            ]
        },
        rules.settings && {
            name: 'app.settings',
            type: 'section',
            children: [
                rules.settings.view && {
                    name: 'app.settings',
                    type: 'item',
                    link: '/app/settings/view',
                    icon: 'view-dashboard'
                },
/*
                rules.settings.add && {
                    name: 'app.settings.add',
                    type: 'item',
                    link: '/app/settings/add',
                    icon: 'view-dashboard',
                },
*/

            ]
        },
        rules.users && {
            name: 'app.users',
            type: 'section',
            children: [
                rules.users.view && {
                    name: 'app.users.view',
                    type: 'item',
                    link: '/app/users/view',
                    icon: 'view-dashboard'
                },
                /*
                                rules.users.add && {
                                    name: 'app.users.add',
                                    type: 'item',
                                    link: '/app/users/add',
                                    icon: 'view-dashboard'
                                },
                */
            ]
        },
        (rules.orders && !!selectedRestaurant) && {
            name: 'app.orders',
            type: 'section',
            children: [
                rules.orders && {
                    name: 'app.orders.view',
                    type: 'item',
                    link: '/app/orders/view',
                    icon: 'view-dashboard'
                },
            ]
        },
        (rules.categories && !!selectedRestaurant) && {
            name: 'app.categories',
            type: 'section',
            children: [
                rules.categories.view && {
                    name: 'app.categories.view',
                    type: 'item',
                    link: '/app/categories/view',
                    icon: 'view-dashboard'
                },
                rules.categories.add && {
                    name: 'app.categories.add',
                    type: 'item',
                    link: '/app/categories/add',
                    icon: 'view-dashboard'
                },

            ]
        },
        (rules.products && !!selectedRestaurant) && {
            name: 'app.products',
            type: 'section',
            children: [
                rules.products.view && {
                    name: 'app.products.view',
                    type: 'item',
                    link: '/app/products/view',
                    icon: 'view-dashboard'
                },
                rules.products.add && {
                    name: 'app.products.add',
                    type: 'item',
                    link: '/app/products/add',
                    icon: 'view-dashboard'
                },

            ]
        },
        (rules.ads && !!selectedRestaurant) && {
            name: 'app.ads',
            type: 'section',
            children: [
                rules.ads.view && {
                    name: 'app.ads.view',
                    type: 'item',
                    link: '/app/ads/view',
                    icon: 'view-dashboard'
                },
                rules.ads.add && {
                    name: 'app.ads.add',
                    type: 'item',
                    link: '/app/ads/add',
                    icon: 'view-dashboard',
                },

            ]
        },
        (rules.importExport && !!selectedRestaurant) && {
            name: 'import_export.label',
            type: 'section',
            children: [
                rules.importExport.export && {
                    name: 'import_export.export',
                    type: 'item',
                    link: '/app/import_export/e',
                    icon: 'view-dashboard'
                },
                rules.importExport.import && {
                    name: 'import_export.import',
                    type: 'item',
                    link: '/app/import_export/i',
                    icon: 'view-dashboard'
                },
            ]
        },

    ];




    return (
        <CustomScrollbars className=" scrollbar">
            <Navigation menuItems={navigationMenus}/>
        </CustomScrollbars>
    );
};

export default SideBarContent;
