import * as firebase from 'firebase/app';
import {getMessaging, onMessage, getToken as generateToken, deleteToken} from 'firebase/messaging';
import NotificationsConfig,{channelsAndEvents} from '../constants/NotificationsConfig.js'
import {NotificationManager} from "react-notifications";


export default class FirebaseApp{

    fetchOrders = null;
    sendTokenToServer = null;


    constructor(fetchOrders, sendTokenToServer) {

        this.fetchOrders = fetchOrders;
        this.sendTokenToServer = sendTokenToServer;

    }

    firebaseSetup = () => {

        this.requestPermissions()
            .then(payload=>{

            })
            .catch(e=>{
            })
    }

    requestPermissions = async ()=>{
        await Notification.requestPermission();
    }

    initializingFirebaseApp = ()=>{

        firebase.initializeApp(NotificationsConfig.firebase.config);
        return getMessaging();

    }

    updateAuthUserToken = (messaging) => {
        return generateToken(messaging,{vapidKey: NotificationsConfig.firebase.public_key}).then((currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);

                this.sendTokenToServer(currentToken)
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                console.log('No registration token available. Request permission to generate one.');
                // shows on the UI that permission is required
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // catch error while creating client token
        });

    }

    onMessageListener = (messaging) => new Promise((resolve) => {
        onMessage(messaging,(payload) => {
            resolve(payload);
        });
    });


    pushNotification = (message) => {
        let link = null;
        // this.isDevelopment && console.log('_firebase-result-5', message)
        const notification = message.notification;

        const title = notification.title;
        const body = notification.body;

        try {
            const data = message.data;
            link = data.link ? data.link : null;
            // const type = data.type;
        } catch (e) {

        }

        this.displayNotification(body, title, link);
        typeof this.fetchOrders == "function" && this.fetchOrders();

    }

    displayNotification = (body, title, link = null) => {

        NotificationManager.success(body, title, 5000, () => {
            this.playRinging && this.playRinging.pause();
            link && window.location.replace(link);
        })
    }

}
