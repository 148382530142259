import React, {useContext, useEffect} from "react";
import {createTheme} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/styles";
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Redirect, Route, Switch} from "react-router-dom";
import {IntlProvider} from "react-intl";
import "assets/vendors/style";
import indigoTheme from "./themes/indigoTheme";
import AppLocale from "../lngProvider";
import SignIn from "./SignIn";
import {setInitUrl} from "../actions";
import RTL from "util/RTL";
import {AuthContext} from "../context/AuthContext";
import {CollectContext} from "../context/CollectContext";
import {getColorTheme} from "../constants/ThemeColors";
import AppLayout from "./AppLayout";
import {SettingsContext} from "../context/SettingsContext";
import ForgotPassword from "./ForgotPassword";
import {Normal} from "../constants/Normal";

const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
    <Route
        {...rest}
        render={props =>
            authUser
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/signin',
                        state: {from: props.location}
                    }}
                />}
    />;

const App = (props) => {


    const {themeColor, darkTheme,isDirectionRTL,locale} = useContext(SettingsContext);

    const {authUser, initURL} = useContext(AuthContext);
    const {setState} = useContext(CollectContext);
    const isDarkTheme = darkTheme;
    const {match,history, location} = props;



    useEffect(()=>{
        try {
            const lastRoute = Normal.getFromStorage('initURL',false,false);

            if(Normal.lastRoutePart(location.pathname).length > 100 || Normal.lastRoutePart(lastRoute).length > 100){
                localStorage.clear();
                history.push('/app/dashboard')
            }else{
                history.push(lastRoute)
            }

        }catch (e){

        }
    },[])
    useEffect(() => {
        window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
        if (initURL !== props.history.location.pathname) {
            setState(setInitUrl(props.history.location.pathname));
        }
        // logger("useEffect",props.history.location)
        // const params = new URLSearchParams(props.location.search);

    }, [initURL, props.history.location.pathname, props.location.search]);


    let applyTheme = createTheme(indigoTheme);
    if (isDarkTheme) {
        document.body.classList.add('dark-theme');
        applyTheme = createTheme(darkTheme)
    } else {
        applyTheme = getColorTheme(themeColor, applyTheme);
    }

    // logger("initURL",initURL)
    if (location.pathname === '/') {
        if (!authUser) {
            return (<Redirect to={'/signin'}/>);
        } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
            return (<Redirect to={'/app/dashboard'}/>);
        } else {
            return (<Redirect to={initURL}/>);
        }
    }

    if (isDirectionRTL) {
        applyTheme.direction = 'rtl';
        document.body.classList.add('rtl')
    } else {
        document.body.classList.remove('rtl');
        applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];

    return (
        <ThemeProvider theme={applyTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}>
                    <RTL>
                        <div className="app-main">
                            <Switch>
                                <RestrictedRoute path={`${match.url}app`} authUser={authUser}
                                                 component={AppLayout}/>
                                <Route path='/signin' component={SignIn}/>
                                <Route path='/forget_password' component={ForgotPassword}/>
                                {/*<Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))} />*/}
                            </Switch>
                        </div>
                    </RTL>
                </IntlProvider>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};


export default App;
