import React, {useContext} from "react";
import {Map, GoogleApiWrapper} from 'google-maps-react';


const apiKey = 'AIzaSyAs_ilBcx0I0Gb5AW1TFnspHzdvYj9UFa4';
const Tracking = () => {
    return (
        <div>


            <Map
                // google={this.props.google}
                zoom={14}
                // style={mapStyles}
                initialCenter={
                    {
                        lat: -1.2884,
                        lng: 36.8233
                    }
                }
            />
        </div>
    );

}

export default GoogleApiWrapper({
    apiKey: apiKey
})(Tracking);
