import React, {useState, createContext, useEffect} from 'react';
import {usersRules} from "../constants/Helper";
import {Server} from "../constants/Server";
import FullScreenLoading from "../localComponents/Loading/FullScreen";
import {Normal} from "../constants/Normal";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {


    const authUserCache = Normal.getFromStorage("authUser", true, true);
    const accessTokenCache = Normal.getFromStorage("accessToken", false, true);

    const initURLStateCache = Normal.getFromStorage("initURL", true, true);

    const [authUser, setAuthUser] = useState('no');

    const [accessToken, setAccessToken] = useState(null);
    const [initURL, setInitURL] = useState('');
    const [rules, setRules] = useState(usersRules(false,false))


    useEffect(() => {

        if (accessTokenCache && authUserCache) {
            login({user: authUserCache, access_token: accessTokenCache})

            Server.updateMe(null).then((res) => {
                login({
                    access_token: accessTokenCache,
                    user: res.data.data.user
                });
            }).catch((e) => {
                // logger('logout','catch')
                signOut();
            })
        } else {
            // logger('logout','else',authUserCache,accessTokenCache)
             signOut();
        }
        if (initURLStateCache) {
            setInitURL(initURLStateCache)
        }

    }, [])

    const signOut = () => {
        authUser && Server.logout().finally(() => {
            setAccessToken(null);
            setAuthUser(null);

            localStorage.removeItem("authUser");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("products_list");
            localStorage.removeItem("categories_list");
            localStorage.removeItem("offers_list");
            localStorage.removeItem("classes_list");
            localStorage.removeItem("orders_list");
            localStorage.removeItem("users");
        });
    }

    const login = (_authUser) => {
        console.log({_authUser})
        if (_authUser) {
            setAuthUser(_authUser.user);
            setAccessToken(_authUser.access_token);

            Normal.saveToStorage('accessToken', _authUser.access_token, true);
            Normal.saveToStorage('authUser', _authUser.user, true);
        }
    }

    const signIn = async ({email, username, primary_phone, password, restaurant_id}) =>
        await Server.login(email, username, primary_phone, password, restaurant_id);


    useEffect(() => {
        Normal.saveToStorage('initURL', initURL, true)
    }, [initURL])


    return (
        <AuthContext.Provider value={{
            signOut,
            rules,
            setRules,
            initURL,
            authUser,
            accessToken,
            setInitURL,
            login,
            signIn
        }}>
            {authUser === 'no' && <FullScreenLoading text={'Logging In'} />}
            {authUser !== 'no' && props.children}
        </AuthContext.Provider>
    );
};


export default AuthContextProvider;
