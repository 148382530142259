import React from 'react';
import Button from '@material-ui/core/Button';
import {getFormattedDate} from "constants/Helper";
import {getText, isValidArray} from "../../../constants/Functions";
import ProductImage from "../../../app/routes/products/routes/view/ProductImage";

const ProductListItem = ({
                             product,
                             orderView = false,
                             currentLanguage = 'en',
                             selectedRestaurant = null,
                             quantity,
                             _price,
                             onPressDelete = null,
                             comment,
                             history = null
                         }) => {
    const {
        id,
        en_name,
        prices,
        added_by,
        image_file_full_mini,
        image_file,
        category_name,
        is_active,
        created_at
    } = product;

    const restaurantCurrency = selectedRestaurant ? selectedRestaurant.currency : '';
    const lang = currentLanguage.toLowerCase();


    const date = getFormattedDate(created_at);

    let selectedPrice = '';

    if (orderView) {
        const {product_id, restaurant_id, price, prices_type} = _price;
        const {title} = prices_type;

        selectedPrice = title[lang] + ' ' + price + " " + restaurantCurrency + ' x' + quantity;
    } else {
        selectedPrice = isValidArray(prices) ? prices[0][`${lang}_name`] + ' ' + prices[0].price + " " + restaurantCurrency : 0;
    }


    return (
        <div className={`card product-item-vertical hoverable animation flipInX ${!is_active && 'disabled-card'}`}>
            <div className="row d-flex align-items-sm-center">
                <div className="col-xl-3 col-lg-4 col-md-3 col-12">
                    <div className="card-header border-0 p-0">
                        <div className="card-image">
                            <div className="grid-thumb-equal">
                                <span className="grid-thumb-cover jr-link">
                                   <ProductImage url_image={image_file_full_mini} image_file={image_file} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-6 col-lg-5 col-md-6 col-12">
                    <div className="card-body">
                        <div className="product-details">
                            <h3 className="card-title fw-regular">{product[`${lang}_name`]}
                                {/*<small className="text-grey text-darken-2">{", " + category_name}</small>*/}
                            </h3>
                            <div className="d-flex ">
                                <h3 className="card-title">{selectedPrice}</h3>
                            </div>
                            <div className="d-flex ">
                                <h3 className="card-title">{comment} </h3>
                            </div>
                            <div className="d-flex flex-row">
                                {/*
              <StarRatingComponent
                name=""
                value={rating}
                starCount={5}
                editing={false}/>
              <strong className="d-inline-block ml-2">{rating}</strong>
*/}
                            </div>
                            <p>{product[`${lang}_details`]}</p>
                        </div>
                    </div>
                </div>

                <div className="col-xl-2 col-lg-3 col-md-3 col-12">
                    <div className="card-footer border-0 text-center bg-white">
                        {!orderView && <div className="cart-btn mb-2">
                            {/*
              <Button variant="contained" className="bg-primary text-white"><IntlMessages
                id="eCommerce.addToCart"/></Button>
*/}

                            {history && <Button
                                onClick={() => {
                                    history.push('/app/products/edit/' + product.id);
                                    // return Redirect({'products/add'});
                                }}
                                color="primary"
                                variant="contained"
                                className="jr-btn text-uppercase text-white btn-block">
                                <i className="zmdi zmdi-edit"/>
                                <span>{getText('buttons.edit')}</span>
                            </Button>}
                            {typeof onPressDelete == "function" && <Button
                                onClick={() => {
                                    onPressDelete(product.id);
                                    // return Redirect({'products/add'});
                                }}
                                color="danger"
                                variant="contained"
                                className="jr-btn jr-btn-sm text-white bg-danger btn-block">
                                <i className="zmdi zmdi-delete"/>
                                <span>{getText('buttons.delete')}</span>
                            </Button>}

                            <Button
                                onClick={() => {
                                    history.push('/app/products/product/' + product.id);
                                    // return Redirect({'products/add'});
                                }}
                                color="default"
                                variant="contained"
                                className="jr-btn jr-btn-sm text-white bg-secondary btn-block">
                                {/*<i className="zmdi zmdi-delete"/>*/}
                                <span>{getText('app.orders.open')}</span>
                            </Button>

                        </div>}

                        {/*<Button color="primary"><IntlMessages id="eCommerce.readMore"/></Button>*/}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProductListItem;

