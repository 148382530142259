import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import Form from "./routes/form";
import View from "./routes/view";


const Restaurants = ({match}) =>(
        <div className="app-wrapper">
            <Switch>
                <Redirect exact from={`${match.url}`} to={`${match.url}/view`}/>
                <Route path={`${match.url}/add`} component={Form}/>
                <Route path={`${match.url}/edit/:resId`} component={Form}/>
                <Route path={`${match.url}/view`} component={View}/>
                <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
            </Switch>
        </div>
    );

export default Restaurants;
