import ApiURL from "./ApiURL";
import axios from "axios";
import { Normal } from "./Normal";

export class Server {
  static getSubDomain($default = null) {
    var full = window.location.host;
    //window.location.host is subdomain.domain.com
    var parts = full.split(".");

    let sub, domain, type;
    if (parts[0] === "www") {
      sub = parts[1];
      domain = parts[2];
      type = parts[3];
    } else {
      sub = parts[0];
      domain = parts[1];
      type = parts[2];
    }

    //sub is 'subdomain', 'domain', type is 'com'
    return !$default ? sub : $default;
  }

  static isProduction() {
    return process.env.NODE_ENV === "production";
  }

  static isOnline() {
    return process.env.NODE_ENV === "production";
  }

  static config = (
    api,
    fun,
    restaurantRequired = true,
    contentType = "multipart/form-data"
  ) => {
    let accessToken = Normal.getFromStorage("accessToken", false, true);
    let authUser = Normal.getFromStorage("authUser", true, true); //{}
    let usertype =
      authUser && typeof authUser == "object" && authUser.hasOwnProperty("type")
        ? authUser.type
        : null; //admin
    let selectedRestaurant =
      Normal.getFromStorage("selectedRestaurant", true, true) ||
      Normal.getFromStorage("restaurantJoined", true, true);
    selectedRestaurant = selectedRestaurant ? selectedRestaurant : null;

    let selectedRestaurant_id = selectedRestaurant
      ? selectedRestaurant.id
      : null;

    if (restaurantRequired && !selectedRestaurant_id) {
      throw new Error(`Restaurant is not selected\n${api}\n${fun}`);
    }

    let subdomain;

    if (this.isProduction()) {
      if (selectedRestaurant && usertype == "senior") {
        subdomain = selectedRestaurant.domain_prefix;
      } else {
        subdomain = Server.getSubDomain();
      }
    } else {
      if (selectedRestaurant && usertype == "senior" && fun !== "login") {
        subdomain = selectedRestaurant.domain_prefix;
      } else {
        subdomain = "admin";
      }
    }

    let headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
      "Access-Control-Allow-Credentials": true,
      // withCredentials:true,
      "content-type": contentType,
      "LIVE-TOKEN": Normal.getFromStorage("LIVE-TOKEN", false, false),
      NAME: subdomain,
    };

    if (accessToken) {
      headers.Authorization = "Bearer " + accessToken;
    }

    return {
      headers,
    };
  };

  static getAllProducts() {
    const api = ApiURL.getProducts;
    return axios.get(api, this.config("post", "getAllProducts", true));
  }

  static deleteProduct(product_id) {
    const api = ApiURL.deleteProduct + "/" + product_id;

    return axios.delete(api, this.config("delete", "deleteProduct", true));
  }

  static sortCategory(formData) {
    const api = ApiURL.sortCategories;
    return axios.post(api, formData, this.config("post", "sortCategory", true));
  }

  static sortProducts(formData) {
    const api = ApiURL.sortProducts;
    return axios.post(api, formData, this.config("post", "sortProducts", true));
  }

  static addNewProduct(data) {
    const api = ApiURL.addProduct;
    return axios.post(api, data, this.config("post", "addNewProduct", true));
  }

  static addNewAd(data) {
    const api = ApiURL.addAd;
    return axios.post(api, data, this.config(api, "addNewAd", true));
  }

  static getAllAds() {
    const api = ApiURL.getAds;
    return axios.get(api, this.config(api, "getAllAds", true));
  }

  static deleteAd(ad_id) {
    const api = ApiURL.deleteAd + "/" + ad_id;
    return axios.delete(api, this.config(api, "deleteAd", true));
  }

  static getRestaurant(restaurant_id) {
    let response = null;

    const api = ApiURL.getRestaurant + restaurant_id;
    axios
      .get(api)
      .then((res) => {
        response = res.data;
      })
      .catch((error) => {
        response = error;
      });

    return response;
  }

  static addNewRestaurant(data) {
    const api = ApiURL.addRestaurant;
    return axios.post(api, data, {
      headers: {
        ...this.config(api, "addNewRestaurant", false).headers,
        "content-type": "multipart/form-data",
      },
    });
  }

  static deleteRestaurant(restaurant_id, key = "deactivate") {
    const api = ApiURL.deactivateRestaurant + `/${restaurant_id}/${key}`;
    return axios.delete(api, this.config(api, "deleteRestaurant", false));
  }

  static deactivateRestaurant(restaurant_id) {
    const api = ApiURL.deactivateRestaurant + "/" + restaurant_id;
    return axios.delete(api, this.config(api, "deactivateRestaurant", false));
  }

  static getAllRestaurants() {
    const api = ApiURL.allRestaurants;
    return axios.get(api, this.config(api, "getAllRestaurants", false));
  }

  static fetchSettings() {
    const api = ApiURL.getSettings;
    return axios.get(api, this.config(api, "fetchSettings", false));
  }

  static saveAllSettings(data) {
    const api = ApiURL.saveSettings;
    return axios.post(api, data, this.config(api, "saveSettings", false));
  }

  static getAllOrders(_from, _to) {
    const formData = new FormData();
    formData.append("from", _from.toString());
    formData.append("take", _to.toString());
    const api = ApiURL.getOrders;
    return axios.post(api, formData, this.config("post", "getOrders", true));
  }

  static updateOrderStatus(
    order_id,
    status,
    estimated_delivery_time,
    selectedDriverId
  ) {
    const formData = new FormData();
    formData.append("status", status.toString());
    formData.append("order_id", order_id.toString());
    estimated_delivery_time &&
      formData.append("estimated_delivery_time", estimated_delivery_time);
    selectedDriverId &&
      formData.append("driver_id", selectedDriverId.toString());
    const api = ApiURL.updateOrderStatus;
    return axios.post(
      api,
      formData,
      this.config("post", "updateOrderStatus", true)
    );
  }

  static getAllCategories() {
    const api = ApiURL.getCategories;
    return axios.get(api, this.config(api, "getCategories", true));
  }

  static addNewCategory(formData) {
    const api = ApiURL.addCategory;
    return axios.post(api, formData, this.config(api, "addNewCategory", true));
  }

  static deleteCategory(category_id) {
    const api = ApiURL.deleteCategory;
    return axios.delete(
      api + "/" + category_id,
      this.config(api, "deleteCategory", true)
    );
  }

  static getAllUsers() {
    const api = ApiURL.getUsers;
    return axios.get(api, this.config(api, "getAllUsers", false));
  }

  static deleteUser(user_id) {
    const api = ApiURL.deleteUser;
    return axios.delete(
      api + "/" + user_id,
      this.config(api, "deleteUser", false)
    );
  }

  static disableUser(user_id) {
    const api = ApiURL.disableUser;
    return axios.delete(
      api + "/" + user_id,
      this.config(api, "deleteUser", false)
    );
  }

  static getUserLocation(setLat, setLng) {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        },
        (error) => {}
      );
    }
  }

  static registerUser(formData, method = "create") {
    const api = method === "create" ? ApiURL.register : ApiURL.userUpdate;
    return axios.post(api, formData, this.config(api, "registerUser", false));
  }

  static updateToken(formData) {
    const api = ApiURL.updateToken;
    return axios.post(api, formData, this.config(api, "updateToken", false));
  }

  static updateMe(formData = null) {
    const api = ApiURL.getMyInfo;
    return axios.post(api, formData, this.config(api, "updateMe", false));
  }

  static updateLanguage(formData = null) {
    const url = ApiURL.updateLanguage;
    return axios.post(url, formData, this.config(url, "updateLanguage", false));
  }

  static refreshAccessToken(formData = null) {
    const api = ApiURL.refreshAccessToken;
    return axios.post(
      api,
      formData,
      this.config(api, "refreshAccessToken", false)
    );
  }

  static login_anonymous() {
    const api = ApiURL.login_anonymous;

    const formData = new FormData();
    formData.append("source", "dashboard");
    formData.append("bundle_id", null);
    // formData.append("subdomain", 'admin');
    formData.append(
      "subdomain",
      Server.isProduction() ? Server.getSubDomain() : "admin"
    );
    formData.append("device_id", Normal.getDeviceInfo().device_id);
    formData.append("unique_id", Normal.getDeviceInfo().unique_id);
    formData.append("model", Normal.getDeviceInfo().device_model);
    formData.append("brand", Normal.getDeviceInfo().device_type);
    formData.append(
      "system_version",
      Normal.getDeviceInfo().device_system_number
    );
    formData.append("system_name", Normal.getDeviceInfo().device_system_name);

    return axios.post(
      api,
      formData,
      this.config(api, "login_anonymous", false)
    );
  }

  static login(
    email,
    username,
    primary_phone,
    password,
    joinedRestaurant = null
  ) {
    const api = ApiURL.login;

    let formData = new FormData();
    email && formData.append("email", email);
    primary_phone && formData.append("phone_number", primary_phone);
    username && formData.append("username", username);

    formData.append("password", password);
    formData.append("device_info", JSON.stringify(Normal.getDeviceInfo()));

    if (joinedRestaurant && joinedRestaurant.hasOwnProperty("id")) {
      if (joinedRestaurant.id.length !== 36) {
        formData.append("restaurant_id", "");
      } else {
        formData.append("restaurant_id", joinedRestaurant.id);
      }
    }

    return axios.post(api, formData, this.config(api, "login", false));
  }

  static getRestaurantInfo() {
    let response = null;
    const api = ApiURL.getRestaurantInfo;

    return axios.get(api, this.config(api, "getRestaurantInfo", false));
  }

  static logout() {
    const api = ApiURL.logout;
    return axios.post(api, [], this.config(api, "logout", false));
  }

  //ImportExport
  static importProducts(jsonObj) {
    const api = ApiURL.importProducts;

    return axios.post(
      api,
      jsonObj,
      this.config(
        api,
        "importProducts",
        true,
        "application/x-www-form-urlencoded"
      )
    );
  }

  static exportProducts(jsonObj) {
    const api = ApiURL.exportProducts;

    return axios.post(
      api,
      jsonObj,
      this.config(
        api,
        "exportProducts",
        true,
        "application/x-www-form-urlencoded"
      )
    );
  }

  //-------------------------------------------
  static getSettingsInfo() {
    const api = ApiURL.getSettingsInfo;

    return axios.get(api, this.config(api, "getSettingsInfo", false));
  }

  static getAllCountries() {
    const api = ApiURL.getCountries;
    return axios.get(api, this.config(api, "getAllCountries", false));
  }

  static getAllCities(country_id) {
    const api = ApiURL.getCities + "/" + country_id;
    return axios.get(api, this.config(api, "getAllCities", false));
  }

  static getAllLanguages() {
    const api = ApiURL.getLanguages;
    return axios.get(api, this.config(api, "getAllLanguages", false));
  }

  static getApiKey() {
    const api = ApiURL.getApiKey;
    return axios.get(api);
  }

  static sendMessage(formData) {
    const api = ApiURL.sendMessage;
    return axios.post(api, formData, this.config(api, "sendMessage", true));
  }

  static onCatch(
    axiosCall,
    then,
    fileName,
    functionName,
    logout = null,
    data = null
  ) {
    axiosCall
      .then((response) => {
        // then && then(response);
        // Helper.consoleServerResults(response)
      })
      .catch((error) => {
        console.log(error);
        // Helper.consoleServerResults(error.response)
        // const {config, request, response, isAxiosError, toJSON, line, column, sourceURL} = error;
        // const {url, method, transformRequest, transformResponse, timeout, adapter, validateStatus} = config;
        // const {data, status, statusText, headers} = response; //config, request

        // status == 405 && Helper.consoleServerResults(error.response);
      });
  }
}
