import React, {useContext} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import {AuthContext} from "../../../context/AuthContext";
import View from "./routes/view";


const Orders = ({match}) => {

    const {rules} = useContext(AuthContext);

    return(
        <div className="app-wrapper">
            <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}view`}/>
{/*
                {rules.orders.add &&
                <Route path={`${match.url}/add`} component={asyncComponent(() => import('./routes/form'))}/>}
                {rules.orders.add && <Route path={`${match.url}/edit/:categoryId`}
                                                       component={asyncComponent(() => import('./routes/form'))}/>}
*/}
                {rules.orders &&
                <Route path={`${match.url}/view`} component={View}/>}
                <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
            </Switch>
        </div>
    )
};

export default Orders;
