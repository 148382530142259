import React, {useContext, useEffect, useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Box, Checkbox} from '@material-ui/core';
import {getText, isValidArray, playRinging} from "../../constants/Functions";
import CardBox from "../../components/CardBox";
import {AuthContext} from "../../context/AuthContext";
import IntlMessages from "../../util/IntlMessages";
import Select from "react-select";
import {UsersContext} from "../../context/UsersContext";
import {RestaurantsContext} from "../../context/RestsurantsContext";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextInput from "../TextInput/TextInput";
import ReactJson from "react-json-view";
import ImageInput from "../../components/ImageInput";
import {show_sweet_message} from "../../actions/Restaurant";
import {CollectContext} from "../../context/CollectContext";
import {Server} from "../../constants/Server";
import {Identity} from "../../constants/Identity";


const sampleMessage = {
    message_title: {},
    message_body: {},
    image_file: null,
    receivers_users: [],
    receivers_drivers: []
}

export default function NotificationsForm() {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const {authUser} = useContext(AuthContext);
    const {usersList, fetchUsers, changeMerchantRequestStatus} = useContext(UsersContext);
    const {selectedRestaurant} = useContext(RestaurantsContext);
    const {rules} = useContext(AuthContext);
    const [message, setMessage] = useState({...sampleMessage})
    const {setState, fetchAll} = useContext(CollectContext);
    const [selectAll,setSelectedAll] =useState({
        users:false,
        drivers:false
    });


    const {message_title, message_body, image_file, receivers_users, receivers_drivers} = message;
    const s = {
        ok: getText('buttons.ok'),
        done: getText('buttons.done'),
        fill_required_fields: getText('messages.fill_required_fields'),
        sent: getText('app.notifications.sent'),
        unsent: getText('app.notifications.unsent'),
    }

    const handleChange = (key, name) => event => {
        let value = event;

        if (key === "receivers_drivers") {
            _setState('receivers_drivers', value)
        } else if (key === "receivers_users") {
            _setState('receivers_users', value)
        } else {

            value = value.target.value
        }
        if (key === "name") {
            let proNames = {
                ...message_title,
                [name]: value
            };

            _setState('message_title', proNames)
        } else if (key === "description") {
            let proDescriptions = {
                ...message_body,
                [name]: value
            };

            _setState('message_body', proDescriptions)
        }
    }
    // const play = useRef(playRinging());
    const _setState = (key, value) => {
        setMessage(prevState => ({
            ...prevState,
            [key]: value
        }))
    }

    useEffect(() => {
        if (!open) {
            setMessage(sampleMessage)
        }
    }, [open]);

    const handleClickOpen = () => {
        // const f  = new FirebaseApp();
        // f.displayNotification('Test','test',null,play.current);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const filterUsers = ()=>{
        const _userList = isValidArray(usersList) ? usersList.filter(user => {
            return user.restaurant_id == selectedRestaurant.id && user.firebase_token;
        }).map(user => {

            let label = user.full_name;
            if (label.length < 2) {
                label = `0${user.primary_phone}`;
            }
            return {value: user.id, label, type: user.user_type}
        }) : [];

        return {
            all:_userList,
            users:_userList.filter(u => u.type == 'user').sort((x, y)=>{
            if (x.label < y.label) {return -1;}
            if (x.label > y.label) {return 1;}
            return 0;
        }),
            drivers:_userList.filter(u => u.type == 'driver').sort((x, y)=>{
                if (x.label < y.label) {return -1;}
                if (x.label > y.label) {return 1;}
                return 0;
            }),
        }
    }


    const validateFields = () => {

        let errorMessage = false;

        selectedRestaurant.languages && selectedRestaurant.languages.map((language, index) => {
            if (language.code !== "en") return;
            const key = message_title[language.code];
            if ((typeof key == "undefined" || key === "") && !errorMessage) {
                errorMessage = true;
            }
        })


        if (!errorMessage) {
            if (!isValidArray(receivers_users) && !isValidArray(receivers_drivers) && !selectAll.users && !selectAll.drivers) {
                errorMessage = true;
            }
        }

        if (errorMessage) {
            setState(show_sweet_message({
                open: true,
                type: "error",
                confirmBtnText: s.done,
                title: "Error",
                body: s.fill_required_fields
            }))
        }
        return !errorMessage;
    }

    const onSubmit = () => {
        setLoading(true);
        if (!validateFields()) {
            setLoading(false);
            return;
        }


        // console.log({filterUsers:filterUsers()})
        //
        // return;
        const formData = new FormData();

        let receivers = [];

        if(selectAll.drivers){
           Array.isArray(filterUsers().drivers) && filterUsers().drivers.map(i=>{
                receivers.push(i)
            })
        }else{
            receivers = receivers.concat(receivers_drivers)
        }
        if (selectAll.users){
            Array.isArray(filterUsers().users) && filterUsers().users.map(i=>{
                receivers.push(i)
            })

        }else{
            receivers = receivers.concat(receivers_users)
        }

        receivers.map(item => {
            console.log({item})
            formData.append('receivers[]', JSON.stringify(item));
        })

        selectedRestaurant.languages && selectedRestaurant.languages.map((language, index) => {
            message_title[language.code] && formData.append(language.code + "_name", message_title[language.code]);
        });

        selectedRestaurant.languages && selectedRestaurant.languages.map((language, index) => {
            message_body[language.code] && formData.append(language.code + "_description", message_body[language.code]);
        });

        image_file && formData.append('image_file', image_file);


        Server.sendMessage(formData)
            .then(res => {
                setState(show_sweet_message({
                    type: "success",
                    title: s.sent,
                    showCancel: false,
                    body: s.done,
                    confirmBtnText: s.ok,
                    onConfirm: () => {
                        setOpen(false)
                    },
                }))
            })
            .catch(error => {
                setState(show_sweet_message({
                    open: true,
                    type: "error",
                    confirmBtnText: s.ok,
                    showCancel: false,
                    title: "Error",
                    body: error?.response?.data?.message
                }))
            })
            .finally(() => {
                setLoading(false);
            })

    }

    return (
        <Box>
            {selectedRestaurant && rules.notifications.edit &&
            <Button variant="contained" color="default" onClick={handleClickOpen}>
                {getText('notifications.send')} ({getText('notifications.beta')})
            </Button>}
            {selectedRestaurant && rules.notifications.edit &&
            <Dialog disableBackdropClick={loading} open={open} onClose={handleClose}
                    aria-labelledby="form-dialog-title">
                {loading && <LinearProgress/>}
                <DialogTitle id="form-dialog-title">{getText('notifications.send')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {getText('notifications.send.details')}
                    </DialogContentText>

                    <div className="row">
                        {/*receivers*/}
                        <div className={"col-md-12 col-12"}>
                            <h4 className="font-weight-regular"><IntlMessages
                                id="notifications.ourClients"/></h4>
                            <div className={"row"}>
                                <div className={"col-md-10 col-12"}>
                                    <Select
                                        isMulti
                                        value={receivers_users}
                                        options={filterUsers().users}
                                        isDisabled={loading || selectAll.users}
                                        isLoading={loading}
                                        onChange={handleChange("receivers_users")}
                                        style={{textTransform: "capitalize"}}
                                    />
                                </div>
                                <div className={"col-md-2 col-12"} style={{display: 'flex', alignItems: 'center'}}>
                                    <FormControlLabel
                                        className={'justify-content-center'}
                                        control={<Checkbox checked={selectAll.users} onChange={(event, checked)=>{

                                            setSelectedAll(prevState => ({
                                                ...prevState,
                                                users: checked

                                            }))}
                                        }name="checkedB"
                                                           color="primary"/>}
                                        label={getText('app.orders.all')}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={"col-md-12 col-12"} style={{marginTop: 20}}>
                            <h4 className="font-weight-regular"><IntlMessages
                                id="notifications.ourDrivers"/></h4>
                            <div className={"row"}>
                                <div className={"col-md-10 col-12"}>
                                    <Select
                                        isMulti
                                        value={receivers_drivers}
                                        options={filterUsers().drivers}
                                        isDisabled={loading || selectAll.drivers}
                                        isLoading={loading}
                                        onChange={handleChange("receivers_drivers")}
                                        style={{textTransform: "capitalize"}}
                                    />
                                </div>
                                <div className={"col-md-2 col-12"} style={{display: 'flex', alignItems: 'center'}}>
                                    <FormControlLabel
                                        className={'justify-content-center'}
                                        control={<Checkbox checked={selectAll.drivers} onChange={(event, checked)=>{
                                        setSelectedAll(prevState => ({
                                            ...prevState,
                                            drivers: checked

                                        }))}
                                        } name="checkedB"
                                                           color="primary"/>}
                                        label={getText('app.orders.all')}
                                    />
                                </div>

                            </div>
                        </div>

                        {/*Message Title*/}
                        <div className="col-md-12 col-12" style={{marginTop: 20}}>
                            <h4 className="font-weight-regular"><IntlMessages
                                id="notification.titleHere"/></h4>
                            <div className="row">
                                {
                                    selectedRestaurant.languages && selectedRestaurant.languages.map((language, index) => {
                                        return (
                                            <div key={language.label.toUpperCase() + "_name"}
                                                 className="col-md-4 col-12">
                                                <TextInput
                                                    id={language.code + "_name"}
                                                    value={message_title[language.code]}
                                                    required={language.code === 'en'}
                                                    onChange={handleChange("name", language.code)}
                                                    submitting={loading}
                                                    label={language.label.toUpperCase()}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>

                        {/*Message Body*/}
                        <div className="col-md-12 col-12" style={{marginTop: 20}}>
                            <h4 className="font-weight-regular"><IntlMessages
                                id="sweetAlerts.description"/></h4>
                            <div className="row">

                                {
                                    selectedRestaurant.languages && selectedRestaurant.languages.map((language, index) => {
                                        return (
                                            <div key={language.label.toUpperCase() + "_description"}
                                                 className="col-md-4 col-12">
                                                <TextInput
                                                    id={language.code + "_description"}
                                                    value={message_body[language.code]}
                                                    onChange={handleChange("description", language.code)}
                                                    submitting={loading}
                                                    multiline
                                                    height={80}
                                                    rowsMax="4"
                                                    label={language.label.toUpperCase()}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className="col-md-12 col-12">
                            <ImageInput
                                loading={loading}
                                allowVideos={false}
                                allowImages
                                maxSize={20}
                                // onLine
                                fileInfo={e => {
                                    // setMetadata({
                                    //     height: e.height,
                                    //     width: e.width
                                    // });
                                }}
                                source={image_file}
                                label={getText('app.products.media_file')}
                                returnString
                                onChange={(image) => {
                                    _setState('image_file', image)
                                }}/>
                        </div>
                    </div>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={loading} color="danger">
                        {getText('buttons.close')}
                    </Button>
                    <Button onClick={onSubmit} disabled={loading} color="primary">
                        {getText('buttons.submit')}
                    </Button>
                </DialogActions>

                {loading && <LinearProgress/>}
            </Dialog>}

            {open && Identity.isSenior(authUser) &&
            <div style={{width: 400, height: 400, position: 'absolute', right: 100, backgroundColor: 'white'}}>
                <CardBox cardStyle={{height: "auto"}} styleName={"col-lg-12"}
                         heading={<IntlMessages
                             id="app.products.form"/>}>

                    <ReactJson src={message}/>
                </CardBox>
            </div>}
        </Box>
    );
}
