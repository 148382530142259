import React, {useState, createContext, useEffect} from 'react';
import {NotificationContainer, NotificationManager} from "react-notifications";
import {logger} from "../constants/Helper";

export const MainContext = createContext();
const MainContextProvider = (props) => {

    const [bind, setBind] = useState(null);
    const [pageName, setPageName] = useState(null);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [serverResponse, setServerResponse] = useState(null);


    const createNotification = (payload) => {
        const {type, title, message, callback} = payload;
        setLoading(false);
        switch (type) {
            case 'info':
                NotificationManager.info(message, title, 5000, callback);
                break;
            case 'success':
                NotificationManager.success(message, title, 5000, callback);
                break;
            case 'warning':
                NotificationManager.warning(message, title, 5000, callback);
                break;
            case 'error':
                NotificationManager.error(message, title, 5000, callback);
                break;
        }


    }
    const showMiniMessage = (payload) => {
        // setShowMessage(true);
        createNotification(payload)
        // setAlertMessage(message)

    }

    const hideMiniMessage = () => {
        setShowMessage(false);
        setAlertMessage('')
    }


    useEffect(() => {
        if (serverResponse) {
           try {
               const {status, display} = serverResponse.data;
               const {title, body} = display;
               createNotification({
                   type: status, title, message: body, callback: () => {

                   }
               })
           }catch (e){
               logger("Error",e)
           }
        }
    }, [serverResponse])

    return (
        <MainContext.Provider value={{
            bind,
            pageName,
            setBind,
            setPageName,
            alertMessage,
            showMessage,
            loading,
            setLoading,
            setServerResponse,
            showMiniMessage,
            hideMiniMessage,
        }}>


            {props.children}
            <NotificationContainer/>

        </MainContext.Provider>
    );
};

export default MainContextProvider;
