import React, {useState, useEffect, useContext} from 'react';
import './style.scss';
import {ProductsContext} from "../../../../../context/ProductsContext";
import {SettingsContext} from "../../../../../context/SettingsContext";
import {getText, isValidArray} from "../../../../../constants/Functions";
import {CategoriesContext} from "../../../../../context/CategoriesContext";
import {Helper} from "../../../../../constants/Helper";
import {RestaurantsContext} from "../../../../../context/RestsurantsContext";
import CommentsCell from "../../../../../components/dashboard/news/CommentsCell";
import {Badge} from "@material-ui/core";

const ProductView = ({match, history, location}) => {

    const s = {
        ok: getText('buttons.ok'),
        done: getText('buttons.done'),
        extra_choices: getText('app.products.extra_choices'),
        prices: getText('app.products.prices'),
        other_names: getText('app.products.other_names'),
        category: getText('app.products.product_category'),
        reviews: getText('app.products.reviews'),
        active: getText('app.categories.isActive'),
        inactive: getText('app.categories.isInActive'),
    }


    const productId = match.params.productId;

    const {productsList} = useContext(ProductsContext);
    const {categoriesList, searchForCategory, fetchCategoriesServer} = useContext(CategoriesContext);
    const {locale} = useContext(SettingsContext);
    const {restaurantCurrency, selectedRestaurant} = useContext(RestaurantsContext);

    const [selectedProduct, setSelectedProduct] = useState(null);

    const searchForProduct = () => {
        let sp = productsList && productsList.filter((p) => {
            return p.id === productId;
        })

        if (Array.isArray(productsList) && sp.length > 0) {
            return sp[0];
        }
        return null;
    }

    useEffect(() => {
        const p = searchForProduct();
        if (!p) {
            history.push('/app/products/view');
            return;
        }
        setSelectedProduct(p);
    }, [productsList]);

    if (!selectedProduct) {
        return <div/>;
    }
    const {
        product_id,
        category_id,
        en_name,
        image_file_full,
        is_active,
        product_name,
        is_offer,
        prices,
        rating_view,
        product_description,
        choices
    } = selectedProduct;


    const category = () => {
        const c = categoriesList.filter(c => c.id == category_id);
        return isValidArray(c) ? c[0] : {};
    };


    const cssClass = is_active == 1 ? "text-white bg-primary" : "text-white bg-danger";


    return (
        <div id={'product-view'} className="container-fluid">
            <div className="row">
                <div className="col-md-3">
                    <img alt={en_name} className={'product_image'} src={image_file_full}/>
                </div>
                <div className="col-md-9">

                    <h1>
                        {selectedProduct[`${locale.locale}_name`]} - <small><cite>{category()[`${locale.locale}_name`]}</cite></small>
                    </h1>

                    <div
                        className={` badge text-uppercase ${cssClass}`}>{getText(is_active == 1 ? 'app.categories.isActive' : 'app.categories.isInActive')}</div>
                    <p>
                        {selectedProduct[`${locale.locale}_details`]}
                    </p>
                    <blockquote className="blockquote">
                        <p className="mb-0">
                            {s.other_names}
                        </p>
                        {
                            selectedRestaurant.languages && selectedRestaurant.languages.map((language, index) => {
                                if (language.code != locale.locale) {
                                    return (
                                        <footer className="blockquote-footer">
                                            ({language.label})<span
                                            style={{marginInline: '10px'}}>   :   </span>{selectedProduct[`${language.code}_name`]}
                                        </footer>
                                    )
                                }

                            })
                        }
                    </blockquote>
                    {isValidArray(prices) && <blockquote className="blockquote">
                        <p className="mb-0">
                            {s.prices}
                        </p>
                        {
                            prices.map(pp => {
                                return (
                                    <footer className="blockquote-footer">
                                        {pp[`${locale.locale}_name`]}<span style={{marginInline: '10px'}}>   :   </span>{pp.price} {restaurantCurrency}
                                    </footer>
                                )
                            })
                        }

                    </blockquote>}

                    {isValidArray(choices) && <blockquote className="blockquote">
                        <p className="mb-0">
                            {s.extra_choices}
                        </p>
                        {
                            choices.map(cc => {
                                return (
                                    <footer className="blockquote-footer">
                                        {cc[`${locale.locale}_name`]}: {cc.price} {restaurantCurrency}
                                    </footer>
                                )
                            })
                        }
                    </blockquote>}


                    {/*<ReactJson src={{selectedProduct, category: categoriesList[0]}}/>*/}
                </div>
            </div>
            <div className="row" id={'comments-list'}>
                <h1>{s.reviews}</h1>
                <div className="col-md-12">
                    {
                        isValidArray(rating_view) && rating_view.map(rating => {

                            return (
                                <CommentsCell data={rating}/>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ProductView;
