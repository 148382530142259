import React, {useEffect, useState} from 'react';
import Pagination from "react-bootstrap/Pagination";

const AioPagination = ({count, page, rowsPerPage, onChangePage}) => {

    const [indexes,setIndexes] = useState(new Array(0).fill(0));

    useEffect(() => {

        const pagesNumber = Math.round(count/rowsPerPage)+1;
        setIndexes(new Array(pagesNumber).fill(0))
    }, [count]);

    return (
        <div style={{justifyContent:'center',display:'flex',width:'100%'}}>
            <Pagination>
                <Pagination.First disabled={page === 0} onClick={() => {
                    onChangePage(0)
                }}/>
                <Pagination.Prev disabled={page <= 0} onClick={() => {
                    onChangePage(page - 1)
                }}/>

                {
                    indexes.map((o,index)=>{
                        return(
                    <Pagination.Item active={index===page} onClick={() => {
                        onChangePage(index)
                    }}>{index+1}</Pagination.Item>
                        )
                    })
                }

                {/*<Pagination.Ellipsis/>*/}

                <Pagination.Next disabled={page <= indexes.length-1} onClick={() => {
                    onChangePage(page + 1)
                }}/>
                <Pagination.Last disabled={page === indexes.length-1} onClick={() => {
                    onChangePage(indexes.length-1)
                }}/>
            </Pagination>
        </div>
    );
}

export default AioPagination;
