import React, {useContext, useEffect, useState} from "react";
import {Area, AreaChart, ResponsiveContainer} from "recharts";
import ChartCard from "./ChartCard";
import ContainerHeader from "components/ContainerHeader/index";

import {increamentData} from "./mdata";
import {Redirect, withRouter} from "react-router";
import {changePageName} from "../../../actions";
import {pages} from "../../../constants/Variables";
import {AuthContext} from "../../../context/AuthContext";
import {MainContext} from "../../../context/MainContext";
import {CollectContext} from "../../../context/CollectContext";
import {useIntl} from "react-intl";
import {RestaurantsContext} from "../../../context/RestsurantsContext";
import {SettingsContext} from "../../../context/SettingsContext";
import Accordion from '@material-ui/core/Accordion';
import {AccordionSummary as ExpansionPanelSummary, AccordionDetails as ExpansionPanelDetails} from '@material-ui/core'
// import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Identity} from "../../../constants/Identity";
import {isValidArray} from "../../../constants/Functions";
import {UsersContext} from "../../../context/UsersContext";

const Dashboard = ({match, history}) => {


    const [expanded, setExpended] = useState('total');
    const {pageName} = useContext(MainContext);
    const {usersList} = useContext(UsersContext);
    const {authUser, rules} = useContext(AuthContext);
    const {setState} = useContext(CollectContext);
    const {statistics} = useContext(SettingsContext);
    const {restaurantJoined,selectedRestaurant, setSelectedRestaurant, restaurantsList} = useContext(RestaurantsContext);

    const i = useIntl();
    const intl = (id) => {
        return i.formatMessage({id})
    };

    // const isSenior = authUser.type === "senior";
    // const isAdmin = authUser.type === "admin";

    const handleChange = panel => (event, expanded) => {
        setExpended(expanded ? panel : false);
    };
    let total = {
        restaurants: statistics && statistics.length,
        products: 0,
        categories: 0,
        ads: 0,
        users:usersList.filter(user=>user.user_type == 'user' && user.restaurant_id == selectedRestaurant.id).length
    };
    statistics && statistics.map((item, index) => {
        total.categories += parseInt(item.categories);
        total.products += parseInt(item.products);
        total.ads += parseInt(item.ads);
        // total.orders += parseInt(item.orders);
    })

    useEffect(() => {
        pageName !== pages.home.main && setState(changePageName(pages.home.main))
    }, [])


    const NumberCard = ({route, title, prize, restaurant_id = null}) => {
        if (restaurant_id && isValidArray(restaurantsList)) {
            setSelectedRestaurant(restaurantsList.filter(r => r.id === restaurant_id)[0])
        }
        return (
            <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <ChartCard
                    history={history}
                    route={route}
                    chartProperties={{
                        title,
                        prize,
                        icon: 'stats',
                        bgColor: 'indigo',
                        styleName: 'up',
                        desc: '',
                        percent: '',
                    }}
                    children={<ResponsiveContainer width="100%" height={75}>
                        <AreaChart data={increamentData}
                                   margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                            <Area dataKey='pv' strokeWidth={0} stackId="2" stroke='#273894' fill="#273894"
                                  fillOpacity={1}/>
                        </AreaChart>
                    </ResponsiveContainer>}
                />

            </div>
        )
    }

    return (
        <div className="app-wrapper">
            <div className="dashboard animated slideInUpTiny animation-duration-3">

                <ContainerHeader match={match} title={"Dashboard"}/>


                <Accordion expanded={expanded === 'total'} onChange={handleChange('total')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography>Total</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div className="row col-12">
                            {/*؛Restaurants*/}
                            {rules.restaurants &&
                            <NumberCard prize={total.restaurants} route={"/app/restaurants/view"}
                                        title={intl('app.restaurants')}/>
                            }
                            {/*؛Products*/}
                            {rules.products &&
                            <NumberCard prize={total.products} route={"/app/products/view"}
                                        title={intl('app.products')}/>
                            }
                            {/*Categories*/}
                            {rules.categories &&
                            <NumberCard prize={total.categories} route={"/app/categories/view"}
                                        title={intl('app.categories')}/>
                            }
                            {/*ads*/}
                            {rules.ads &&
                            <NumberCard prize={total.ads} route={"/app/ads/view"} title={intl('app.ads')}/>
                            }
                            {/*Users*/}
                            {rules.users &&
                            <NumberCard prize={total.users} route={"/app/users/view"} title={intl('app.users.user')}/>
                            }
                        </div>
                    </ExpansionPanelDetails>
                </Accordion>
                {
                    !restaurantJoined && Identity.isSenior(authUser) && statistics && statistics.map(res => {
                        return (
                            <Accordion expanded={expanded === res.name} onChange={handleChange(res.name)}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography>{res.name}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div className="row col-12">
                                        {/*؛Products*/}
                                        {rules.products &&
                                        <NumberCard prize={res.products} route={"/app/products/view"}
                                                    restaurant_id={res.id}
                                                    title={intl('app.products')}/>
                                        }
                                        {/*Categories*/}
                                        {rules.categories &&
                                        <NumberCard prize={res.categories} route={"/app/categories/view"}
                                                    restaurant_id={res.id}
                                                    title={intl('app.categories')}/>
                                        }
                                        {/*ads*/}
                                        {rules.ads &&
                                        <NumberCard prize={res.ads} route={"/app/ads/view"} restaurant_id={res.id}
                                                    title={intl('app.ads')}/>
                                        }
                                        {/*Users*/}
                                        {rules.users &&
                                        <NumberCard prize={total.users} route={"/app/users/view"} restaurant_id={res.id}
                                                    title={intl('app.users.user')}/>
                                        }
                                    </div>
                                </ExpansionPanelDetails>
                            </Accordion>

                        )
                    })
                }


            </div>


        </div>
    );

}


export default withRouter(Dashboard);
