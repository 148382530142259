
export class Identity {


    // 'admin','senior','driver', 'reader', 'user', 'advertiser'

    static isRestaurantNamed = (selectedRestaurant = null, restaurant_name = null) => {
        return selectedRestaurant ? selectedRestaurant.restaurant_name ===  restaurant_name : false;
    }

    static isStandardRestaurant = (selectedRestaurant = null) => {
        return selectedRestaurant ? selectedRestaurant.type === 'standard' : null;
    }
    static isDeliveryRestaurant = (selectedRestaurant = null) => {
        return selectedRestaurant ? selectedRestaurant.type === 'delivery' : null;
    }

    static isSenior = (authUser = null) => {
        return authUser ? authUser.type === 'senior' : null;
    }
    static isAdmin = (authUser = null) => {
        return authUser ? authUser.type === 'admin' : null;
    }
    static isDriver = (authUser = null) => {
        return authUser ? authUser.type === 'driver' : null;
    }
    static isReader = (authUser = null) => {
        return authUser ? authUser.type === 'reader' : null;
    }
    static isDataEntry = (authUser = null) => {
        return authUser ? authUser.type === 'data' : null;
    }
    static isUser = (authUser = null) => {
        return authUser ? authUser.type === 'user' : null;
    }
    static isAdvertiser = (authUser = null) => {
        return authUser ? authUser.type === 'advertiser' : null;
    }


}
