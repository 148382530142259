import React, {useState} from 'react';
import NoImage from "../../../../../localComponents/NoImage";
import {Normal} from "../../../../../constants/Normal";

const ProductImage = ({url_image,image_file}) => {
    const [isError,setIsError] = useState(false);


    if(!isError){
        return(<img alt={image_file} className="img-fluid" src={url_image} onError={error=>{
            setIsError(true)
        }
        }/>)
    }


    return(<div style={{
        display: "flex",
        width: "100%",
        height: "100%",
        padding: 20,
        justifyContent: "center",
        alignItems: "center"
    }}>

        <NoImage/>
    </div>)




}

export default ProductImage;
