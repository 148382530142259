import React, {useState, createContext, useEffect, useContext} from 'react';
import {Helper, ucfirst} from "../constants/Helper";

import {AuthContext} from "./AuthContext";
import {
    FIXED_DRAWER,
    INSIDE_THE_HEADER,
    VERTICAL_NAVIGATION
} from "../constants/ActionTypes";
import {DARK_INDIGO} from "../constants/ThemeColors";
import {MainContext} from "./MainContext";
import {Server} from "../constants/Server";
import {pages} from "../constants/Variables";
import ApiURL from "../constants/ApiURL";

export const SettingsContext = createContext();
const SettingsContextProvider = ({children}) => {

    const {pageName} = useContext(MainContext);
    const [statistics, setStatistics] = useState(null);
    const [pricesTypes, setPricesTypes] = useState(null);
    const [languagesOptions, setLanguagesOptions] = useState([]);
    const [countriesOptions, setCountriesOptions] = useState([]);
    const [citiesOptions, setCitiesOptions] = useState([]);
    const {authUser, signOut} = useContext(AuthContext);
    const {setLoading, setServerResponse} = useContext(MainContext);
    const [isDirectionRTL, setIsDirectionRTL] = useState(false);
    const [isDev, setIsDev] = useState(false);
    const [locale, setLocale] = useState({
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us'
    });
    const initialSettings = {
        navCollapsed: false,
        drawerType: FIXED_DRAWER,
        themeColor: DARK_INDIGO,
        darkTheme: false,
        width: window.innerWidth,
        navigationStyle: VERTICAL_NAVIGATION,
        horizontalNavPosition: INSIDE_THE_HEADER,
    };

    const [darkTheme,setDarkTheme] = useState(initialSettings.darkTheme)
    const [themeColor,setThemeColor] = useState(initialSettings.themeColor)
    const [drawerType,setDrawerType] = useState(initialSettings.drawerType)
    const [horizontalNavPosition,setHorizontalNavPosition] = useState(initialSettings.horizontalNavPosition)
    const [navigationStyle,setNavigationStyle] = useState(initialSettings.navigationStyle)
    const [width,setWidth] = useState(initialSettings.width)
    const [navCollapsed,setNavCollapsed] = useState(initialSettings.navCollapsed)

    const fetchLanguages = () => {
        Server.getAllLanguages().then(response => {
            let languages = [];
            response.data.data.map((langauge) => {
                languages.push({value: langauge.id, label: langauge.name, code: langauge.code})
            })
            setLanguagesOptions(languages);
            Helper.consoleServerResults(response)
        }).catch(e => {
            Helper.consoleServerResults(e.response)
        })
    }
    const fetchCountries = () => {
        Server.getAllCountries().then(response => {
            let countries = [];
            response.data.data.map((country) => {
                country[1] && countries.push({value: country[0], label: ucfirst(country[1])})
            })
            setCountriesOptions(countries)
            Helper.consoleServerResults(response)
        }).catch(e => {
            Helper.consoleServerResults(e.response)
        })
    }
    const fetchCities = (country_id = null, fun = null) => {
        country_id && Server.getAllCities(country_id).then(response => {
            let cities = [];
            response.data.data.map((city) => {
                cities.push({value: city, label: city})
            })

            setCitiesOptions(cities);

            typeof fun == "function" && fun();
            Helper.consoleServerResults(response)
        }).catch(e => {
            Helper.consoleServerResults(e.response)
        })

    }
    const fetchSettings = () => {
        Server.fetchSettings().then(response => {
            const data = response.data.data;
            const {statistics, prices_types, pages_themes} = data;

            setStatistics(statistics)
            setPricesTypes(prices_types)
            Helper.consoleServerResults(response)
        }).catch(e => {
            Helper.consoleServerResults(e.response)
            Helper.consoleServerResults({authUser})
        })
    }

    const updateLanguageOnServer = (language) => {

        const formData = new FormData();
        formData.append('language', language)


        Server.updateLanguage(formData).then(r => {
        });
    }


    const saveSettings = (prices_types) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('prices_types', JSON.stringify(prices_types))

        Server.saveAllSettings(formData).then(response => {
            setServerResponse(response);
            Helper.consoleServerResults(response)
        }).catch(e => {
            Helper.consoleServerResults(e.response)
        }).finally(() => {
            setLoading(false);
        })
    }

    const onChangeThemeColor = (color)=>{
        setThemeColor(color);
        setDarkTheme(false);
    }
    const onChangeDarkTheme = ()=>{
        setDarkTheme(prevState => !prevState);
    }


    useEffect(() => {
        authUser && updateLanguageOnServer(locale.locale)
    }, [locale])
    useEffect(() => {
        authUser && fetchSettings();
    }, [authUser])

    useEffect(() => {

        if(pageName === pages.restaurants.form){
            authUser && fetchCountries();
            authUser && fetchLanguages();
        }

        if(pageName === pages.settings.form){
            authUser && fetchLanguages();
        }
    }, [pageName,authUser])


    useEffect(()=>{
        setIsDev(ApiURL.webServer.indexOf('development') > -1)
    },[])

    const changeDirection = () => {
        setIsDirectionRTL(!isDirectionRTL)
    }
    return (
        <SettingsContext.Provider value={{
            darkTheme,
            themeColor,
            drawerType,
            setDrawerType,
            horizontalNavPosition,
            setHorizontalNavPosition,
            navCollapsed,
            setNavCollapsed,
            navigationStyle,
            setNavigationStyle,
            width,
            setWidth,
            // fetchSettings,
            // fetchLanguages,
            fetchCountries,
            fetchCities,
            saveSettings,
            onChangeDarkTheme,
            onChangeThemeColor,
            pricesTypes,
            setPricesTypes,
            statistics,
            changeDirection,
            languagesOptions,
            countriesOptions,
            citiesOptions,
            isDirectionRTL, setIsDirectionRTL,
            isDev,
            locale,
            setLocale
        }}>

            {children}
        </SettingsContext.Provider>
    );
};

export default SettingsContextProvider;
