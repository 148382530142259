export default {
    products: [
        {
            key: `id`,
            hint: 'eaf7ebee-35c3-4d7d-b6f7-58a126a57ee2'
        },


        {
            key: `image_file`,
            hint: 'image-file.jpg'
        },
        {
            key: `image_file_full_mini`,
            hint: 'https://domain.ext/images/image-file.jpg'
        },
        {
            key: `category_id`,
            hint: '2dfdfa54-e1c5-4018-bc44-a84e48817f1a'
        },
        {
            key: `is_active`,
            hint: 'true / false'
        },
        {
            key: `is_offer`,
            hint: 'true / false'
        },
        {
            key: `kr_name`,
            hint: 'مريشكى برژاند'
        },
        {
            key: `kr_details`,
            hint: ''
        },
        {
            key: `ar_name`,
            hint: 'شيش طاووق'
        },
        {
            key: `ar_details`,
            hint: ''
        },
        {
            key: `en_name`,
            hint: 'Grilled Chicken',
            required:true
        },
        {
            key: `en_details`,
            hint: ''
        },
        {
            key: `tr_name`,
            hint: 'Izgara tavuk'
        },
        {
            key: `tr_details`,
            hint: ''
        },
        {
            key: `ku_name`,
            hint: ''
        },
        {
            key: `ku_details`,
            hint: ''
        },
        {
            id:'375a2f9d-237a-45e5-bb15-6f38adc1b12a',
            key:'quarter_price',
            hint:'40 $/IQD'
        },
        {
            id:'4e42c9f7-1857-44f0-822c-5806b706e8da',
            key:'double_price',
            hint:'40 $/IQD'
        },
        {
            id:'582f39eb-3b45-45e1-b333-f30d9ba79bb8',
            key:'bottle_price',
            hint:'40 $/IQD'
        },
        {
            id:'591e1a6f-025a-4460-b9cd-5aaa4630894b',
            key:'mug_price',
            hint:'40 $/IQD'
        },
        {
            id:'6544a451-b3b4-43b0-989a-584eccad8688',
            key:'small_price',
            hint:'40 $/IQD'
        },
        {
            id:'789d6c91-b337-4fe7-86df-395ee0ee7de2',
            key:'medium_price',
            hint:'40 $/IQD'
        },
        {
            id:'955f9998-5b4b-4d03-b90b-f13ed8014bfa',
            key:'normal_price',
            hint:'40 $/IQD'
        },
        {
            id:'a23eff9b-cc44-43a6-8967-3a015fac31a4',
            key:'fresh_price',
            hint:'40 $/IQD'
        },
        {
            id:'b3c30782-02c6-4031-8fbc-e60bae641cf0',
            key:'main_price',
            hint:'40 $/IQD'
        },
        {
            id:'b4bff374-5407-4721-8d1b-da125005341a',
            key:'combo_price',
            hint:'40 $/IQD'
        },
        {
            id:'c93c9519-dbaa-4e0c-a2b8-951a80c1ec88',
            key:'large_price',
            hint:'40 $/IQD'
        },
        {
            id:'f1e083a7-2223-4894-a637-5186d306052f',
            key:'glass_price',
            hint:'40 $/IQD'
        },
    ]
}


export const demo = [
    {
        "Type": "simple, downloadable, virtual",
        "SKU": "WOO-SINGLE-2",
        "Name": "Woo Single #2",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": 2,
        "Regular price": 3,
        "Categories": "Music, Music > Singles",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/cd_6_angle.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/cd_6_flat.jpg",
        "Download limit": 10,
        "Download expiry days": 90,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "Song #2",
        "Download 1 URL": "https://woocommerce.files.wordpress.com/2017/06/woo-single-2.ogg",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "simple, downloadable, virtual",
        "SKU": "WOO-SINGLE-1",
        "Name": "Woo Single #1",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 3,
        "Categories": "Music, Music > Singles",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/cd_4_angle.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/cd_4_flat.jpg",
        "Download limit": -1,
        "Download expiry days": -1,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "Song #1",
        "Download 1 URL": "https://woocommerce.files.wordpress.com/2017/06/woo-single-1.ogg",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "grouped",
        "SKU": "WOO-SINGLES",
        "Name": "Woo Singles",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": null,
        "Categories": "Music, Music > Singles",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/cd_6_flat.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "WOO-SINGLE-1,WOO-SINGLE-2",
        "Upsells": "WOO-ALBUM-1,WOO-ALBUM-2",
        "Cross-sells": "WOO-ALBUM-3",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "simple, virtual",
        "SKU": "WOO-ALBUM-4",
        "Name": "Woo Album #4",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 9,
        "Categories": "Music > Albums, Music",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/cd_5_angle.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/cd_5_flat.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "simple, virtual",
        "SKU": "WOO-ALBUM-3",
        "Name": "Woo Album #3",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 9,
        "Categories": "Music > Albums, Music",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/cd_3_angle.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/cd_3_flat.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "simple, virtual",
        "SKU": "WOO-ALBUM-2",
        "Name": "Woo Album #2",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 9,
        "Categories": "Music > Albums, Music",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/cd_2_angle.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/cd_2_flat.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "simple, virtual",
        "SKU": "WOO-ALBUM-1",
        "Name": "Woo Album #1",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 9,
        "Categories": "Music > Albums, Music",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/cd_1_angle.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/cd_1_flat.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "simple",
        "SKU": "POSTER-WOO-LOGO",
        "Name": "Woo Logo",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 15,
        "Categories": "Posters",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/poster_5_up.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/Poster_5_flat.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "T-SHIRT-WOO-LOGO,HOODIE-WOO-LOGO",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "simple",
        "SKU": "POSTER-WOO-NINJA",
        "Name": "Woo Ninja",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 15,
        "Categories": "Posters",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/poster_4_up.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/Poster_4_flat.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "T-SHIRT-WOO-NINJA,HOODIE-WOO-NINJA",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "simple",
        "SKU": "POSTER-PREMIUM-QUALITY",
        "Name": "Premium Quality",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": 12,
        "Regular price": 15,
        "Categories": "Posters",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/poster_3_up.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/Poster_3_flat.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "T-SHIRT-PREMIUM-QUALITY",
        "Cross-sells": "",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "simple",
        "SKU": "POSTER-FLYING-NINJA",
        "Name": "Flying Ninja",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": 12,
        "Regular price": 15,
        "Categories": "Posters",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/poster_2_up.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/Poster_2_flat.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "simple",
        "SKU": "POSTER-SHIP-YOUR-IDEA",
        "Name": "Ship Your Idea",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 15,
        "Categories": "Posters",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/poster_1_up.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/Poster_1_flat.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "T-SHIRT-SHIP-YOUR-IDEA,HOODIE-SHIP-YOUR-IDEA",
        "Cross-sells": "T-SHIRT-SHIP-YOUR-IDEA,HOODIE-SHIP-YOUR-IDEA",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "simple",
        "SKU": "HOODIE-WOO-LOGO",
        "Name": "Woo Logo",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 35,
        "Categories": "Clothing, Clothing > Hoodies",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/hoodie_6_front.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/hoodie_6_back.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "T-SHIRT-WOO-LOGO",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "external",
        "SKU": "HOODIE-NINJA-SILHOUETTE",
        "Name": "Ninja Silhouette",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 0,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 30,
        "Categories": "Clothing, Clothing > Hoodies",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/hoodie_5_front.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/hoodie_5_back.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "T-SHIRT-NINJA-SILHOUETTE",
        "External URL": "https://mercantile.wordpress.org/product/wordpress-logo-black-zip-hoodie/",
        "Button text": "Buy on WordPress Swag Store",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "simple",
        "SKU": "HOODIE-HAPPY-NINJA",
        "Name": "Happy Ninja",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 35,
        "Categories": "Clothing, Clothing > Hoodies",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/hoodie_4_front.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/hoodie_4_back.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "T-SHIRT-HAPPY-NINJA",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "simple",
        "SKU": "HOODIE-PATIENT-NINJA",
        "Name": "Patient Ninja",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 35,
        "Categories": "Clothing, Clothing > Hoodies",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/hoodie_3_front.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/hoodie_3_back.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "HOODIE-HAPPY-NINJA",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "simple",
        "SKU": "HOODIE-WOO-NINJA",
        "Name": "Woo Ninja",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 35,
        "Categories": "Clothing, Clothing > Hoodies",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/hoodie_2_front.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/hoodie_2_back.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "T-SHIRT-WOO-NINJA",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "variable",
        "SKU": "HOODIE-SHIP-YOUR-IDEA",
        "Name": "Ship Your Idea",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 30,
        "Categories": "Clothing, Clothing > Hoodies",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/hoodie_7_front.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/hoodie_7_back.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/hoodie_1_back.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/hoodie_1_front.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "T-SHIRT-SHIP-YOUR-IDEA",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "Color",
        "Attribute 1 value(s)": "Black, Blue",
        "Attribute 1 visible": 0,
        "Attribute 1 global": 1,
        "Attribute 2 name": "Size",
        "Attribute 2 value(s)": "L, XL",
        "Attribute 2 visible": 1,
        "Attribute 2 global": 0,
        "Attribute 1 default": "Black",
        "Attribute 2 default": "L"
    },
    {
        "Type": "variation",
        "SKU": "HOODIE-SHIP-YOUR-IDEA-BLUE-XL",
        "Name": "Ship Your Idea - Blue, XL",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "",
        "Description": "",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": 30,
        "Regular price": 35,
        "Categories": "",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/hoodie_1_front.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "HOODIE-SHIP-YOUR-IDEA",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "T-SHIRT-SHIP-YOUR-IDEA",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "Color",
        "Attribute 1 value(s)": "Blue",
        "Attribute 1 visible": null,
        "Attribute 1 global": 1,
        "Attribute 2 name": "Size",
        "Attribute 2 value(s)": "XL",
        "Attribute 2 visible": null,
        "Attribute 2 global": 0,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "variation",
        "SKU": "HOODIE-SHIP-YOUR-IDEA-BLACK-L",
        "Name": "Ship Your Idea - Black, L",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "",
        "Description": "",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 35,
        "Categories": "",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/hoodie_7_front.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "HOODIE-SHIP-YOUR-IDEA",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "T-SHIRT-SHIP-YOUR-IDEA",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "Color",
        "Attribute 1 value(s)": "Black",
        "Attribute 1 visible": null,
        "Attribute 1 global": 1,
        "Attribute 2 name": "Size",
        "Attribute 2 value(s)": "L",
        "Attribute 2 visible": null,
        "Attribute 2 global": 0,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "simple",
        "SKU": "T-SHIRT-HAPPY-NINJA",
        "Name": "Happy Ninja",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 18,
        "Categories": "Clothing, Clothing > T-shirts",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_7_front.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_7_back.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "HOODIE-HAPPY-NINJA",
        "Cross-sells": "T-SHIRT-WOO-NINJA,T-SHIRT-NINJA-SILHOUETTE",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "simple",
        "SKU": "T-SHIRT-WOO-NINJA",
        "Name": "Woo Ninja",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 20,
        "Categories": "Clothing, Clothing > T-shirts",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_6_front.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_6_back.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "HOODIE-WOO-NINJA",
        "Cross-sells": "T-SHIRT-HAPPY-NINJA,T-SHIRT-NINJA-SILHOUETTE",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "simple",
        "SKU": "T-SHIRT-NINJA-SILHOUETTE",
        "Name": "Ninja Silhouette",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 20,
        "Categories": "Clothing, Clothing > T-shirts",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_5_front.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_5_back.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "HOODIE-NINJA-SILHOUETTE",
        "Cross-sells": "T-SHIRT-WOO-NINJA,T-SHIRT-HAPPY-NINJA",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "variable",
        "SKU": "T-SHIRT-SHIP-YOUR-IDEA",
        "Name": "Ship Your Idea",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 20,
        "Categories": "Clothing, Clothing > T-shirts",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_4_front.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_3_back.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_3_front.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_4_back.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_4_front1.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "HOODIE-SHIP-YOUR-IDEA",
        "Cross-sells": "",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "Color",
        "Attribute 1 value(s)": "Black, Green",
        "Attribute 1 visible": 0,
        "Attribute 1 global": 1,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "variation",
        "SKU": "T-SHIRT-SHIP-YOUR-IDEA-GREEN",
        "Name": "Ship Your Idea - Green",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "",
        "Description": "",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 20,
        "Categories": "",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_3_front.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "T-SHIRT-SHIP-YOUR-IDEA",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "Color",
        "Attribute 1 value(s)": "Green",
        "Attribute 1 visible": null,
        "Attribute 1 global": 1,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "variation",
        "SKU": "T-SHIRT-SHIP-YOUR-IDEA-BLACK",
        "Name": "Ship Your Idea - Black",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "",
        "Description": "",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 20,
        "Categories": "",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_4_front1.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "T-SHIRT-SHIP-YOUR-IDEA",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "Color",
        "Attribute 1 value(s)": "Black",
        "Attribute 1 visible": null,
        "Attribute 1 global": 1,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "simple",
        "SKU": "T-SHIRT-PREMIUM-QUALITY",
        "Name": "Premium Quality",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": null,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": null,
        "Regular price": 20,
        "Categories": "Clothing, Clothing > T-shirts",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_2_front.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_2_back.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "",
        "Cross-sells": "",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    },
    {
        "Type": "simple",
        "SKU": "T-SHIRT-WOO-LOGO",
        "Name": "Woo Logo",
        "Published": 1,
        "Is featured?": 0,
        "Visibility in catalog": "visible",
        "Short description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Description": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        "Date sale price starts": "",
        "Date sale price ends": "",
        "Tax class": "taxable",
        "In stock?": 1,
        "Stock": 5,
        "Backorders allowed?": 0,
        "Sold individually?": 0,
        "Weight (kg)": "",
        "Length (cm)": "",
        "Width (cm)": "",
        "Height (cm)": "",
        "Allow customer reviews?": 1,
        "Purchase note": "",
        "Sale price": 18,
        "Regular price": 20,
        "Categories": "Clothing, Clothing > T-shirts",
        "Tags": "",
        "Shipping class": "",
        "Images": "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_1_front.jpg, http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_1_back.jpg",
        "Download limit": null,
        "Download expiry days": null,
        "Parent": "",
        "Grouped products": "",
        "Upsells": "HOODIE-WOO-LOGO",
        "Cross-sells": "",
        "External URL": "",
        "Button text": "",
        "Download 1 name": "",
        "Download 1 URL": "",
        "Attribute 1 name": "",
        "Attribute 1 value(s)": "",
        "Attribute 1 visible": null,
        "Attribute 1 global": null,
        "Attribute 2 name": "",
        "Attribute 2 value(s)": "",
        "Attribute 2 visible": null,
        "Attribute 2 global": null,
        "Attribute 1 default": "",
        "Attribute 2 default": ""
    }
]
