import React, {useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {withRouter} from "react-router-dom";
import {compareTwoObjects, filterCategories} from "../../../../../constants/Helper";
import FormControl from '@material-ui/core/FormControl';
import {TreeSelect} from 'antd';
import 'antd/dist/antd.css';
import {CollectContext} from "../../../../../context/CollectContext";
import {AuthContext} from "../../../../../context/AuthContext";
import {RestaurantsContext} from "../../../../../context/RestsurantsContext";
import {CategoriesContext} from "../../../../../context/CategoriesContext";
import {ProductsContext} from "../../../../../context/ProductsContext";
import {show_sweet_message} from "../../../../../actions/Restaurant";
import {SettingsContext} from "../../../../../context/SettingsContext";
import {getText, isValidArray} from "../../../../../constants/Functions";
import {Server} from "../../../../../constants/Server";
import {changePageName} from "../../../../../actions";
import {pages} from "../../../../../constants/Variables";
import {ButtonGroup} from "reactstrap";
import {AccountTree, Refresh, ViewList, ViewModule} from "@material-ui/icons";
import {MainContext} from "../../../../../context/MainContext";
import {Normal} from "../../../../../constants/Normal";
import GridView from "./GridView";
import ListView from "./ListView";
import TreeView from "./TreeView";
import AioPagination from "../../../../../localComponents/AioPagination";
import FullScreenLoading from "../../../../../localComponents/Loading/FullScreen";

const View = ({history, match}) => {

    const s = {
        ok: getText('buttons.ok'),
        done: getText('buttons.done'),
        fill_required_fields: getText('messages.fill_required_fields'),
        updated: getText('app.products.updated'),
        created: getText('app.products.created'),
        deleted: getText('app.products.deleted'),
        confirmDelete: getText('messages.delete_confirm')
    }

    const {pageName} = useContext(MainContext);
    const {setState} = useContext(CollectContext);
    const {productsList, fetchProductsServer, productsListLoading} = useContext(ProductsContext);
    const {categoriesList} = useContext(CategoriesContext);
    const {authUser} = useContext(AuthContext);
    const {locale,isDirectionRTL} = useContext(SettingsContext);
    const {selectedRestaurant} = useContext(RestaurantsContext);


    const [activePage, setActivePage] = useState(0);
    const [pagesLength, setPagesLength] = useState(0);


    const [displayMode, setDisplayMode] = useState('list');

    const [localProductList, setLocalProductList] = useState([]);
    const [categoryId, setCategoryId] = useState("all");
    const [loading, setLoading] = useState(false);


    const loopCategories = (categories = categoriesList) => {
        let j = [];
        if (Array.isArray(categories)) {
            j = categories.filter((c) => {
                if (c.key !== "all") {
                    c.title = c[locale.locale + '_name'] + ` (${c.products_number})`;
                    c.value = c.id;
                    c.key = c.id;
                    if (c.children_number > 0) {
                        c.children = loopCategories(categoriesList.filter(cc => cc.parent_id == c.id))
                    }
                    return c;
                }
            })
        }
        return j;
    }



    let _categoriesList = Normal.buildCategories(categoriesList, null, locale.locale);

    if (_categoriesList.length === 0 || (isValidArray(_categoriesList, 1) && _categoriesList[1].id !== "offers")) {
        _categoriesList.unshift({
            "id": "offers",
            "title": "Offers",
            "value": "offers",
            "key": "offers",
            "children": []
        })
    }

    if (_categoriesList.length === 0 || (isValidArray(_categoriesList) && _categoriesList[0].id !== "all")) {
        _categoriesList.unshift({
            "id": "all",
            "title": "All",
            "value": "all",
            "key": "all",
            "children": []
        })
    }


    useEffect(() => {
        setState(changePageName(pages.products.view))

        if (authUser.type === "company") {
            const getOfferCategory = filterCategories(categoriesList, authUser);
            if (getOfferCategory.length > 0) {
                setCategoryId(getOfferCategory[0].id);
            }
        }

        const lastCategoryFilter = localStorage.getItem("last_category_filter");
        if (_categoriesList.filter(c => c.id == lastCategoryFilter).length > 0) {
            setCategoryId(lastCategoryFilter);
        } else {
            setCategoryId('all');
        }
    }, []);

    useEffect(() => {
        activePage === 0 ? splitResults() : setActivePage(0)
        localStorage.setItem("last_category_filter", categoryId);
    }, [categoryId]);

    useEffect(() => {
        (localProductList.length === 0 || !compareTwoObjects(productsList,localProductList)) && splitResults();
    }, [productsList]);

    useEffect(() => {
        productsList.length > 0 && splitResults();
    }, [activePage]);


    const splitResults = () => {
        const skip = activePage * 8;
        const take = (activePage + 1) * 8;
        const filteredProducts = fetchProductsByCategory();

        filteredProducts.length != pagesLength && setPagesLength(filteredProducts.length)

        setLocalProductList(filteredProducts.slice(skip, take))

    }
    const fetchProductsByCategory = () => {
        return Array.isArray(productsList) ? productsList.filter((product, index) => {

            if (categoryId == "all") {
                return true
            } else if (categoryId === "offers") {
                return product.is_offer == 1;
            } else {
                return product.category_id == categoryId

            }
        }) : []
    }

    useEffect(() => {
        console.log({l: localProductList.length})
    }, [localProductList]);

    const deleteRecord = (id) => {
        setLoading(true);
        Server.deleteProduct(id).then(r => {
            setState(show_sweet_message({
                type: "success",
                title: s.deleted,
                showCancel: false,
                confirmBtnText: s.ok,
                onConfirm: () => {
                    fetchProductsServer();
                    pageName !== pages.products.view && setState(changePageName(pages.products.view))
                },
            }))
        }).catch((error) => {
            setState(show_sweet_message({
                type: "error",
                title: error.response.data.display.title,
                body: error.response.data.display.body,
                showCancel: false,
                confirmBtnText: s.ok,
            }))
        })
            .finally(() => {
                setLoading(false);
            });

    };

    return (
        <div>
            <div className={"col-lg-12 col-md-12 col-sm-12 row"} style={{marginBottom: 22}}>
                <div className="d-flex flex-row justify-content-lg-start mb-2" style={{[`margin${isDirectionRTL?'Left':'Right'}`]: 22}}>
                    <h4 className="mr-2" style={{alignSelf: "center"}}>{getText('app.categories.list')}</h4>
                    <FormControl className="w-25 mb-2" style={{justifyItems: "center"}}>
                        <TreeSelect
                            style={{width: 300}}
                            treeDataSimpleMode
                            treeDefaultExpandAll
                            value={categoryId}
                            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                            treeData={_categoriesList}
                            placeholder="Please select"
                            onChange={(value) => {
                                setCategoryId(value)
                            }}
                        />
                    </FormControl>
                </div>
                <div className="d-flex flex-row justify-content-lg-start mb-2">
                    <ButtonGroup vertical={false}>
                        <Button onClick={() => {
                            setDisplayMode('grid')
                        }} className={displayMode !== 'grid' ? "jr-btn active" : "jr-btn"}><ViewModule/></Button>
                        <Button onClick={() => {
                            setDisplayMode('list')
                        }} className={displayMode !== 'list' ? "jr-btn active" : "jr-btn"}><ViewList/></Button>
                        {!Server.isProduction() && <Button onClick={() => {
                            setDisplayMode('tree')
                        }} className={displayMode !== 'tree' ? "jr-btn active" : "jr-btn"}><AccountTree/></Button>}

                        <Button
                            style={{margin: '0px 12px  !important'}}
                            onClick={() => {
                                fetchProductsServer();
                            }}
                            variant="contained"
                            color="secondary"
                            className={"jr-btn active"}><Refresh/></Button>

                    </ButtonGroup>
                </div>
            </div>
            {displayMode !== 'tree' && <AioPagination
                count={pagesLength}
                page={activePage}
                rowsPerPage={8}
                onChangePage={setActivePage}
            />}


            {productsListLoading && productsList.length  == 0 && <FullScreenLoading />}

            <div style={{width:'100%'}}
                className={displayMode == 'list' ? "animated slideInUpTiny animation-duration-3 " : "animated slideInUpTiny animation-duration-3 row"}>

                {!Server.isProduction() && displayMode == 'tree' &&
                <TreeView locale={locale} localProductList={fetchProductsByCategory().filter(i=>i.restaurant_id==selectedRestaurant.id)} history={history}
                          selectedRestaurant={selectedRestaurant} deleteRecord={deleteRecord} lang={s}
                          setState={setState}/>}

                {displayMode == 'list' &&
                <ListView locale={locale} localProductList={localProductList.filter(i=>i.restaurant_id==selectedRestaurant.id)} history={history}
                          selectedRestaurant={selectedRestaurant} deleteRecord={deleteRecord} lang={s}
                          setState={setState}/>}

                {displayMode == 'grid' &&
                <GridView locale={locale} localProductList={localProductList.filter(i=>i.restaurant_id==selectedRestaurant.id)} history={history}
                          selectedRestaurant={selectedRestaurant} deleteRecord={deleteRecord} lang={s}
                          setState={setState}/>}
            </div>

            {displayMode !== 'tree' && <AioPagination
                count={pagesLength}
                page={activePage}
                rowsPerPage={8}
                onChangePage={setActivePage}
            />}

        </div>
    );
};


export default withRouter(View);

