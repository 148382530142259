import React, {useEffect, useState} from 'react'
import {SketchPicker} from 'react-color'


const _color = {
    r: '241',
    g: '112',
    b: '19',
    a: '1',
};

const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: 1
    } : null;
};


const Basic = ({initialColor = null, onChange = null}) => {

    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [myColor, setColor] = useState(_color);






    useEffect(()=>{

        setInitialColor()

    },[initialColor]);

    useEffect(()=>{


    },[myColor]);


    useEffect(() => {

        setInitialColor();

        if (onChange !== null) {
            if (typeof onChange !== "function") {
                throw new Error("onChange type is invalid")
            }
        }

    }, []);


    const setInitialColor = ()=>{
        if (initialColor !== null) {
            if (typeof initialColor === "string") {
                setColor(hexToRgb(initialColor));
            } else if (typeof initialColor === "object") {
                setColor(initialColor);
            } else {
                throw new Error("Color type is invalid")
            }
        }
    }
    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleChange = (color) => {
        if (color !== null && color !== "null") {
            setColor(color.rgb);
            onChange(color)
        }
    };


    const background = myColor?`rgba(${myColor.r}, ${myColor.g}, ${myColor.b}, ${myColor.a})`:`rgba(${_color.r}, ${_color.g}, ${_color.b}, ${_color.a})`;

    return (
        <div>
            <div className="cp-swatch" onClick={handleClick}>
                <div className="cp-color"
                     style={{backgroundColor: background}}/>
            </div>
            {displayColorPicker ? <div className="cp-popover">
                <div className="cp-cover" onClick={handleClose}/>
                <SketchPicker color={myColor?myColor:_color} onChange={handleChange}/>
            </div> : null}

        </div>
    )
}

export default Basic
