import React, {useEffect, useState, useContext} from 'react';
import IntlMessages from 'util/IntlMessages';
import 'antd/dist/antd.css';
import Fab from "@material-ui/core/Fab";
import TextInput from "../../localComponents/TextInput/TextInput";
import {pages} from "../../constants/Variables";
import {changePageName} from "../../actions";
import {CollectContext} from "../../context/CollectContext";
import './style.scss';

const Extras = ({languages,choices,setExtraChoices}) => {
    const {setState} = useContext(CollectContext);

    const extraChoicesSample = () => {
        const s = {
            id: "",
            isDeletable: true,
            deleted: false,
            price:0,
            title: {}
        }

        languages.map((language, index) => {
            s.title[language.code] = '';
        })

        return s;
    }

    useEffect(() => {
        setState(changePageName(pages.settings.form))
    }, [])



    const addRow = () => {
        setExtraChoices([...choices, {...extraChoicesSample()}])
    }
    const deleteRow = (deletedIndex) => {
        setExtraChoices(choices.filter((i, n) => {
            if (n === deletedIndex && i.id) {
                i.deleted = true;
                return n;
            }
            return n !== deletedIndex
        }))

    }
    const onChangeRow = (value, language, index) => {
        const {code} = language;

        const n = [
            ...choices
        ]
        n[index].title[code] = value;


        setExtraChoices(n)

    }

    const extraItemRow = (type, index) => {
        const {title,price, isDeletable, deleted} = type;

        return (
            <div
                className={`flex-row row justify-content-start align-items-center fade show ${deleted && 'alert-secondary'}`}
                key={ "extra_items_" + index}>
                <div className={'extra-item-row'}>
                {
                    languages.map((language, _index) => {
                        const _id = language.label+'extra_item_';

                        return (
                            <div key={_id + _index}
                                 className={'flex-1'}>
                                <TextInput
                                    id={_id + _index}
                                    value={title[language.code]}
                                    placeholder={type?.type}
                                    onChange={event => {
                                        let value = event.target.value;
                                        onChangeRow(value, language, index)
                                    }}
                                    // submitting={loading}
                                    label={language.label}
                                />
                            </div>
                        )
                    })
                }
                </div>
                <div className={'col-lg-2 col-md-4 col-sm-6'}>
                    <TextInput
                        id={'price_e_'+index}
                        value={price}
                        placeholder={'Price'}
                        onChange={event => {
                            let value = event.target.value;
                            const n = [
                                ...choices
                            ]
                            n[index].price = value;


                            setExtraChoices(n)
                        }}
                        // submitting={loading}
                        label={'Price'}
                    />
                </div>


                {isDeletable && !deleted && <div className={'col-lg-2 col-md-4 col-sm-6'}>
                    <Fab
                        onClick={() => {
                            deleteRow(index)
                        }}
                        className="jr-fab-btn bg-secondary text-white jr-btn-fab-sm pull-right">
                        <i className="zmdi zmdi-delete zmdi-hc-lg"/>
                    </Fab>
                </div>}

                {
                    deleted && <span>Click save to to save changes</span>
                }
            </div>
        )
    }

    return (
        <div className="col-md-12 col-12">
            <h4 className="font-weight-light"><IntlMessages
                id="app.products.extra_choices"/></h4>
            {
                choices.map(extraItemRow)
            }
            <Fab onClick={addRow}
                 className="jr-fab-btn bg-secondary text-white jr-btn-fab-sm pull-right">
                <i className="zmdi zmdi-plus zmdi-hc-lg"/>
            </Fab>
        </div>

    );
};


export default Extras;

