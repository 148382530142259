import React, {useState, createContext, useEffect, useContext} from 'react';
import {compareTwoObjects, getFromStorage, Helper, logger} from "../constants/Helper";
import {Server} from "../constants/Server";
import {AuthContext} from "./AuthContext";
import {RestaurantsContext} from "./RestsurantsContext";
import {MainContext} from "./MainContext";
import {pages} from "../constants/Variables";
import FullScreenLoading from "../localComponents/Loading/FullScreen";
import {Normal} from "../constants/Normal";

export const CategoriesContext = createContext();
const CategoriesContextProvider = ({children}) => {
        const {pageName, loading, setLoading} = useContext(MainContext);
        const {authUser} = useContext(AuthContext);
        const {selectedRestaurant} = useContext(RestaurantsContext);
        const categoriesListState = Normal.getFromStorage("categories_list", true, true);
        const [categoriesList, setCategoriesList] = useState([]);


        const fetchCategoriesServer = () => {
            setLoading(true)
            Server.getAllCategories().then(response => {
                setCategoriesList(response.data.data);
                Helper.consoleServerResults(response)
            }).catch(e => {
                Helper.consoleServerResults(e.response)
            }).finally(()=>{
                setLoading(false)
            })
        }

        useEffect(() => {
            categoriesListState && setCategoriesList(categoriesListState)
        }, [])

        useEffect(() => {
            const requestRefresh = pageName === pages.products.view || pageName === pages.products.form || pageName === pages.categories.form || pageName === pages.categories.view;
            requestRefresh && selectedRestaurant && authUser && fetchCategoriesServer()
        }, [selectedRestaurant, pageName, authUser]);

        const searchForCategory = (category_id, _categoriesList = categoriesList) => {

            let ret = null;
            Array.isArray(_categoriesList) && _categoriesList.map((category, index) => {
                if (ret !== null) return;
                if (category.id == category_id) {
                    ret = category;
                } else {
                    if (category.children_numbers > 0) {
                        ret = searchForCategory(category_id, category.children);
                    }
                }
            })

            return ret;
        }

        return (
            <CategoriesContext.Provider value={{categoriesList, setCategoriesList, fetchCategoriesServer, searchForCategory}}>
                {children}
            </CategoriesContext.Provider>
        );
    }
;

export default CategoriesContextProvider;
