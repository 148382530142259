import React, {useEffect, useContext, useState} from 'react';
import CardBox from 'components/CardBox/index';
import DataTable from '../../../../../components/Datatables';
import {ButtonGroup} from "reactstrap";
import Button from "@material-ui/core/Button";
import {Delete, Edit, ViewModule, Refresh, AccountTree} from "@material-ui/icons";
import {withRouter} from "react-router";
import {RestaurantsContext} from "../../../../../context/RestsurantsContext";
import {CategoriesContext} from "../../../../../context/CategoriesContext";
import {CollectContext} from "../../../../../context/CollectContext";
import {show_sweet_message, something_changed} from "../../../../../actions/Restaurant";
import {Server} from "../../../../../constants/Server";
import {changePageName} from "../../../../../actions";
import {pages} from "../../../../../constants/Variables";
import {getText} from "../../../../../constants/Functions";
import EmptyPage from "../../../../../components/EmptyPage";
import NoImage from "../../../../../localComponents/NoImage";
import {SettingsContext} from "../../../../../context/SettingsContext";
import Sorter from "../../../../../localComponents/Sorter";
import {Normal} from "../../../../../constants/Normal";

const View = ({history, match}) => {

    const s = {
        prefix: getText('app.categories.prefix'),
        ok: getText('buttons.ok'),
        done: getText('buttons.done'),
        fill_required_fields: getText('messages.fill_required_fields'),
        updated: getText('app.categories.deleted'),
        created: getText('app.categories.created'),
        deleted: getText('app.categories.deleted'),
        undeleted: getText('app.categories.undeleted'),
        delete_alert: getText('app.categories.delete_alert'),
        confirmDelete: getText('messages.delete_confirm')
    }

    // const selectedRestaurant = useSelector(state => state.auth.selectedRestaurant);

    const {setState, fetchAll} = useContext(CollectContext);
    const {locale} = useContext(SettingsContext);
    const {restaurantCurrency} = useContext(RestaurantsContext);
    const {categoriesList, searchForCategory, fetchCategoriesServer} = useContext(CategoriesContext);

    const [tableData, setTableData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [categoryWillDelete, setCategoryWillDelete] = useState(null);
    const [deleteAlertActive, setDeleteAlertActive] = useState(false);
    const [message, setMessage] = useState({
        open: false,
        type: "success",
        btnText: "Ok",
        title: "Title",
        text: "Some Text"
    });

    const [treeView, setTreeView] = useState(false);

    const columns = [
        {
            id: `${locale.locale.toLowerCase()}_name`,
            align: true,
            disablePadding: false,
            label: 'Category Name',
        },
        // {id: 'added', align: true, disablePadding: false, label: 'Added By'},
        {id: 'products_number', align: true, disablePadding: false, label: 'Products'},
        {
            id: 'parent_name', align: true, disablePadding: false, label: 'Parent', renderedComponent: (row, index) => {
                const getCategory = searchForCategory(row.parent_id);
                return !!getCategory ? getCategory[`${locale.locale.toLowerCase()}_name`] : 'Root';
            }
        },
        {
            id: 'is_active', align: true, disablePadding: false, label: 'Active',
            renderedComponent: ({is_active}, index) => {

                const cssClass = is_active == 1 ? "text-white bg-primary" : "text-white bg-danger";


                return (
                    <div
                        className={` badge text-uppercase ${cssClass}`}>{getText(is_active == 1 ? 'app.categories.isActive' : 'app.categories.isInActive')}</div>
                )
            }
        },
        {
            id: 'image', align: true, disablePadding: false, label: 'Image', renderedComponent: (row, index) => {
                return (
                    <div className={'category-image'}>
                        {row.image_file_full_mini &&
                        <img style={{height: '100%', alignSelf: "center"}} src={row.image_file_full_mini}
                             alt={'category_image'}/>}

                        {!row.image_file_full_mini && <NoImage/>}
                    </div>
                )
            }
        },
        {
            id: 'actions', align: true, disablePadding: false, label: '#', renderedComponent: (row, index) => {

                return (
                    <ButtonGroup vertical={false}>
                        <Button style={{marginLeft: 5}}
                                variant="contained"
                                color="primary"
                                onClick={() => {

                                    history.push('/app/categories/edit/' + row.id);

                                }}
                                href={""}>
                            <Edit/>
                        </Button>
                        <Button style={{marginLeft: 5}}
                                variant="contained"
                                className="bg-danger text-white"
                                onClick={() => {

                                    setCategoryWillDelete(row.id);
                                    setDeleteAlertActive(true);

                                }}
                                href={""}>
                            <Delete/>
                        </Button>
                    </ButtonGroup>

                )

            }
        },
    ];

    useEffect(()=>{
        if(deleteAlertActive){
            setState(show_sweet_message({
                type: "warning",
                title: s.confirmDelete,
                confirmBtnText: s.ok,
                onConfirm: () => {
                    deleteRecord();
                },
                onCancel: () => {
                    setCategoryWillDelete(null);
                    setDeleteAlertActive(false);
                }
            }))

        }
    },[deleteAlertActive])

    useEffect(() => {
        setState(changePageName(pages.categories.view))
        setTableColumns(columns);
    }, []);

    useEffect(() => {
        if (!treeView) {
            fetchCategoriesServer();
        }
    }, [treeView])

    useEffect(() => {
        fetchCategories();
    }, [categoriesList]);

    const loopCategories = (categoriesList) => {

        // alert(restaurants_list.length)
        let categories_data = [];
        typeof categoriesList == "object" && categoriesList.map((category, index) => {

            categories_data.push(category);
            categories_data = categories_data.concat(loopCategories(category.children));

        });


        return categories_data;


    };

    const fetchCategories = () => {
        if (categoriesList) {
            setTableData(categoriesList);
        }
    };

    const deleteRecord = () => {

        setLoading(true);

        Server.deleteCategory(categoryWillDelete).then(r => {
            setState(something_changed());
            fetchCategories();
            setMessage({
                ...message,
                type: "success",
                btnText: "Ok",
                title: "Deleted",
                text: "Category has been deleted",
                open: true
            });
        }).catch(e => {
            setMessage({
                ...message,
                type: "error",
                btnText: "Ok",
                title: "Undeleted",
                text: "Category has not been deleted",
                open: true
            });
        }).finally(() => {
            setLoading(false);
            setDeleteAlertActive(false);
            setCategoryWillDelete(null);
        })
    };


    return (
        <EmptyPage data={categoriesList} className="animated slideInUpTiny animation-duration-3">
            <div className={"col-lg-12 col-md-12 col-sm-12 row"} style={{marginBottom: 22}}>
                <div className="d-flex flex-row justify-content-lg-start mb-2">
                    <ButtonGroup vertical={false}>
                        <Button onClick={() => {
                            setTreeView(false)
                        }} className={treeView ? "jr-btn active" : "jr-btn"}><ViewModule/></Button>
                       {/* <Button onClick={() => {
                            setTreeView(true)
                        }} className={!treeView ? "jr-btn active" : "jr-btn"}><AccountTree/></Button>*/}
                        <Button
                            style={{margin: '0px 12px  !important'}}
                            onClick={() => {
                                fetchCategoriesServer()
                            }}
                            variant="contained"
                            color="secondary"
                            className={"jr-btn active"}><Refresh/></Button>
                    </ButtonGroup>
                </div>
            </div>


            <div className="row">
                <CardBox styleName="col-lg-12"
                         heading={getText('app.categories.list')}>

                   {/* {treeView && <Sorter
                        sourceData={Normal.buildCategories(categoriesList)}
                        onSuccess={fetchCategoriesServer}
                        onClickDelete={i => {
                            setState(show_sweet_message({
                                type: "warning",
                                title: s.confirmDelete,
                                confirmBtnText: s.ok,
                                onConfirm: () => {
                                    deleteRecord(i.id)
                                },
                            }))
                        }}
                        onClickEdit={i => {
                            history.push('/app/categories/edit/' + i.id);
                        }}
                        sortServerHandle={(d) => {
                            return Server.sortCategory(d)
                        }}
                        keys={{
                            array_key: "newTreeData",
                            selected_node_id: "selected_category_id",
                            new_parent_id: "new_parent_id"
                        }}/>}*/}

                    {!treeView && <DataTable
                        columns={tableColumns}
                        isActiveKey={'is_active'}
                        defaultOrder={'index'}
                        defaultSortBy={'index'}
                        // selectedItem={selectedCompany}
                        columnsData={tableData}
                        // tableLabel={"Categories Table"}
                        clickable={false}
                    />}
                </CardBox>

            </div>


        </EmptyPage>
    );
};


export default withRouter(View);

