import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        // margin: theme.spacing.unit,
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        color: grey[50],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

class CircularFab extends React.Component {
    state = {
        loading: false,
        success: false,
    };
    handleButtonClick = () => {
        if (!this.state.loading) {
            this.setState(
                {
                    success: false,
                    loading: true,
                },
                () => {
                    this.timer = setTimeout(() => {
                        this.setState({
                            loading: false,
                            success: true,
                        });
                    }, 2000);
                },
            );
        }
    };
    timer = undefined;

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        const {success} = this.state;
        const {classes, label, onClick, loading} = this.props;
        const buttonClassname = classNames({
            [classes.buttonSuccess]: success,
        });

        const _label = label ? label : "Accept terms";
        return (
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <Fab color="primary" className={buttonClassname} onClick={onClick ? onClick : this.handleButtonClick}>
                        {success ? <CheckIcon/> : <SaveIcon/>}
                    </Fab>
                    {loading && <CircularProgress size={68} className={classes.fabProgress}/>}
                </div>
                <div className={classes.wrapper}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={buttonClassname}
                        disabled={loading}
                        onClick={onClick ? onClick : this.handleButtonClick}
                    >
                        {_label}
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                </div>
            </div>
        );
    }
}

CircularFab.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    onClick: PropTypes.func,
    loading:PropTypes.bool
};

export default withStyles(styles)(CircularFab);
