import React, {useContext, useEffect, useState} from 'react';

import ContainerHeader from 'components/ContainerHeader/index';
import CardBox from '../../../../../components/CardBox/index';

import IntlMessages from '../../../../../util/IntlMessages';
import FormControl from "@material-ui/core/FormControl";
import {consoleLog} from "../../../../../constants/Helper";
import {Button} from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import {withRouter} from "react-router";
import {TreeSelect} from 'antd';
import 'antd/dist/antd.css';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import TextInput from "../../../../../localComponents/TextInput/TextInput";
import {RestaurantsContext} from "../../../../../context/RestsurantsContext";
import {AuthContext} from "../../../../../context/AuthContext";
import {CategoriesContext} from "../../../../../context/CategoriesContext";
import {SettingsContext} from "../../../../../context/SettingsContext";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import {CollectContext} from "../../../../../context/CollectContext";
import {something_changed} from "../../../../../actions/Restaurant";
import {getText, isValidArray} from "../../../../../constants/Functions";
import {Server} from "../../../../../constants/Server";
import {changePageName} from "../../../../../actions";
import {pages} from "../../../../../constants/Variables";
import {MainContext} from "../../../../../context/MainContext";
import ReactJson from "react-json-view";


// Register the plugins


const Form = ({match, history}) => {


    const {setState} = useContext(CollectContext);
    const {selectedRestaurant} = useContext(RestaurantsContext);
    const {authUser} = useContext(AuthContext);
    const {categoriesList, searchForCategory} = useContext(CategoriesContext);
    const {pricesTypes, locale} = useContext(SettingsContext);
    const {loading} = useContext(MainContext);

    const catId = match.params.categoryId;
    const categorySample = {
        category_id: catId,
        prices_types_ids: [],
        theme_name: null,
        parent_id: 'root',
        is_active: 1,
        image_file: null,
        category_name: {},
        category_description: {}
    };
    const [categoryDetails, setCategoryDetails] = useState({
        ...categorySample
    })

    const [imagePreview, setImagePreview] = useState(null);

    const [message, setMessage] = useState({
        open: false,
        type: "success",
        btnText: "Ok",
        title: "Title",
        text: "Some Text"
    });


    const [submitting, setSubmitting] = useState(false);


    useEffect(() => {
        if (categoryDetails.category_id) {
            setFormData();
        }

        setState(changePageName(pages.categories.form))

    }, []);
    const translations = (i, key) => {
        const j = {};
        selectedRestaurant.languages && selectedRestaurant.languages.map((language) => {

            const nameKey = language.code + key;
            const _value = i[nameKey];

            j[language.code] = _value
        })
        return j;
    }
    const setFormData = () => {
        var c = searchForCategory(categoryDetails.category_id);

        if (!c) {
            return;
        }


        setCategoryDetails(prevState => {
            return {
                ...prevState,
                category_id: c.id,
                theme_name: c.theme_name,
                parent_id: c.parent_id == null ? "root" : c.parent_id,
                image_file: c.image_file_full,
                is_active: c.is_active,
                category_name: {
                    ...translations(c, '_name')
                },
                category_description: {
                    ...translations(c, '_details')
                },
                prices_types_ids: c.prices_types ? c.prices_types.split(',') : []
            }
        })
        // setSpecialKey(selectedCategory.singleKey);
        setImagePreview(c.image_file_full);

    }

    const validateFields = () => {

        let ErrorMessage = null;


        selectedRestaurant.languages && selectedRestaurant.languages.map((language, index) => {

            if (language.code !== "en") return;
            const key = categoryDetails.category_name[language.code];
            if ((typeof key == "undefined" || key === "") && ErrorMessage == null) {
                ErrorMessage = language.label + " name must be filled";
            }
        });

        // if (parent_id !== "root") {
        //     if (this.searchForCategory(parent_id).subcategories_has_images && this.pond.getFiles().length === 0 && category_id == null) {
        //         ErrorMessage = "You must upload an image";
        //     }
        // }

        if (ErrorMessage !== null) {
            setMessage({
                open: true,
                type: "error",
                btnText: "Ok",
                title: "Opps!",
                text: ErrorMessage
            })
            return false;
        } else {
            return true;
        }
    }

    const addCategory = () => {
        const {
            category_name,
            category_description,
            category_id,
            is_active,
            parent_id,
            theme_name,
            prices_types_ids,
            image_file
        } = categoryDetails;
        setSubmitting(true);

        if (!validateFields()) {
            setSubmitting(false);
            return;
        }

        const formData = new FormData();
        if (category_id !== null) {
            formData.append('category_id', category_id);
        }

        formData.append('is_active', is_active);
        formData.append('parent_id', parent_id);
        formData.append('user_id', authUser.id);
        formData.append('theme_name', theme_name);

        selectedRestaurant.languages && selectedRestaurant.languages.map((language) => {
            if (category_name[language.code]) {
                formData.append(language.code + "_name", category_name[language.code]);
            }
        });
        selectedRestaurant.languages && selectedRestaurant.languages.map((language) => {
            if (category_description[language.code]) {
                formData.append(language.code + "_description", category_description[language.code]);
            }
        });
        formData.append('prices_types_ids', prices_types_ids.join(","));

        formData.append('image_file', image_file);

        Server.addNewCategory(formData).then(res => {
            setMessage({
                open: true,
                type: "success",
                btnText: "Ok",
                title: "Done",
                text: categoryDetails.category_id ? "Category has been updated" : "Category has been created",
                onConfirm: () => {
                    setState(something_changed());
                    if (categoryDetails.category_id !== null) {
                        history.push('/app/categories/view');
                    } else {
                        emptyForm();
                    }
                }
            })
        })
            .catch(error => {
                setMessage({
                    open: true,
                    type: "error",
                    btnText: "Ok",
                    title: "Error",
                    text: "Something went wrong"
                })
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    const emptyForm = () => {
        setCategoryDetails({
            ...categorySample
        })
    }

    const handleChange = (key, name) => event => {
        let value = event.target.value;

        if (key === "name") {
            let catNames = {
                ...categoryDetails.category_name,
                [name]: value
            }

            setCategoryDetails(prevState => {
                return {
                    ...prevState,
                    category_name: catNames
                }
            })
        } else {
            let catDescriptions = {
                ...categoryDetails.category_description,
                [name]: value
            }

            setCategoryDetails(prevState => {
                return {
                    ...prevState,
                    category_description: catDescriptions
                }
            })
        }
    }


    const renderImage = (e) => {

        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            setCategoryDetails(prevState => {
                return {
                    ...prevState,
                    image_file: file
                }
            })
            setImagePreview(reader.result)
        };

        reader.readAsDataURL(file)
    }

    let $imagePreviewRender = null;
    if (imagePreview) {
        $imagePreviewRender = (<img style={{width: "45%"}} src={imagePreview}/>);
    }


    let _categoriesList = [];
    if (Array.isArray(categoriesList)) {
        _categoriesList = categoriesList.filter((c) => {
            c.title = c[locale.locale.toLowerCase() + '_name'];
            c.value = c.id;
            c.key = c.id;
            return c.id !== "All";
        })

        if (_categoriesList.length === 0 || _categoriesList[0].id !== "root") {
            _categoriesList.unshift({
                "id": "root",
                "title": "Root",
                "value": "root",
                "key": "root",
                "children": []
            })

        }
    }

    let _pricesTypes = [];
    if (Array.isArray(pricesTypes)) {
        pricesTypes.map((c, i) => {
            consoleLog(i, c)
            _pricesTypes.push({
                "id": c.id,
                "title": c.title[locale.locale],
                "value": c.id,
                "key": c.id
            })
        })
    }


    const onChangeCategoryId = (value) => {
        if(value !== 'root'){
            const getCategory = searchForCategory(value);


            if (value == categoryDetails.category_id) {
                setMessage({
                    open: true,
                    type: "error",
                    btnText: "Ok",
                    title: "Error",
                    text: "You can't set parent category for the same category"
                })
                return;
            }
            if (getCategory.is_offer) {
                setMessage({
                    open: true,
                    type: "error",
                    btnText: "Ok",
                    title: "Error",
                    text: "You can't set category as parent for offer category"
                })
                return;
            }
        }

        setCategoryDetails(prevState => ({...prevState, parent_id: value}))
    }

    const ErrorHint = () => (
        <Typography variant="span" color={"error"} component="span"><IntlMessages
            id="error.error.image"/></Typography>
    );


    const {TreeNode} = TreeSelect;

    const {
        category_name,
        category_description,
        category_id,
        parent_id,
        theme_name,
        prices_types_ids,
        is_active,
        image_file
    } = categoryDetails;


    if (selectedRestaurant !== null && typeof selectedRestaurant === "object") {
        return (

            <div className="animated slideInUpTiny animation-duration-3">
                <ContainerHeader title={<IntlMessages id="app.categories.add"/>} match={match}/>

                <div className="row">
                    <CardBox styleName={"col-lg-6 justify-content-center"}>
                        <form className="row" noValidate autoComplete="off">
                            <div className="col-md-12 col-12">
                                <h4 className="font-weight-light"><IntlMessages
                                    id="app.categories.category_name"/></h4>
                                <div className="col-md-12 col-12 row">
                                    {
                                        selectedRestaurant.languages && selectedRestaurant.languages.map(({
                                                                                                              code,
                                                                                                              label,
                                                                                                              language,
                                                                                                              value
                                                                                                          }, index) => {

                                            if (!value || !code || !label) {
                                                return;
                                            }
                                            return (
                                                <div key={value + "_name"} className="col-md-4 col-12">
                                                    <TextInput
                                                        id={code + "_name"}
                                                        value={category_name[code]}
                                                        onChange={handleChange("name", code)}
                                                        submitting={submitting}
                                                        label={label.toUpperCase() + " Name"}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-md-12 col-12">
                                <h4 className="font-weight-light"><IntlMessages
                                    id="app.categories.category_description"/></h4>
                                <div className="col-md-12 col-12 row">
                                    {
                                        selectedRestaurant.languages && selectedRestaurant.languages.map(({
                                                                                                              code,
                                                                                                              label,
                                                                                                              language,
                                                                                                              value
                                                                                                          }, index) => {
                                            if (!value || !code || !label) {
                                                return;
                                            }
                                            return (
                                                <div key={value + "_description"} className="col-md-4 col-12">
                                                    <TextInput
                                                        id={code + "_description"}
                                                        value={category_description[code]}
                                                        onChange={handleChange("description", code)}
                                                        submitting={submitting}
                                                        multiline
                                                        rowsMax="4"
                                                        height={80}
                                                        label={label.toUpperCase()}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            {/*Activation*/}
                            <div className="col-md-12 col-12">
                                <FormControlLabel
                                    disabled={loading}
                                    control={
                                        <Switch color="primary"
                                                classes={{
                                                    checked: 'text-primary',
                                                    bar: 'bg-primary',
                                                }}
                                                checked={is_active == 1}
                                                onChange={(event, checked) => setCategoryDetails(prevState => ({
                                                    ...prevState,
                                                    is_active: checked ? 1 : 0
                                                }))}
                                        />
                                    }
                                    label={<IntlMessages id="app.categories.isActive"/>}
                                />
                            </div>

                            <div className="col-md-12 col-12">
                                <FormControl disabled={submitting} className="w-100 mb-2">
                                    <label htmlFor="parent_category">Parent Category</label>
                                    <TreeSelect
                                        id={"parent_category"}
                                        treeDataSimpleMode
                                        treeDefaultExpandAll
                                        value={categoryDetails.parent_id}
                                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                        treeData={_categoriesList}
                                        placeholder="Please select"
                                        onChange={onChangeCategoryId}
                                    />
                                </FormControl>
                            </div>

                            {authUser.type === "senior" && <div className="col-md-12 col-12">
                                <FormControl disabled={submitting} className="w-100 mb-2">
                                    <label htmlFor="parent_category">Theme Page</label>
                                    <TreeSelect
                                        id={"theme_page"}
                                        treeDataSimpleMode={false}
                                        treeDefaultExpandAll
                                        value={theme_name && theme_name.length>4?theme_name:'normal'}
                                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                        treeData={[
                                            {
                                                "title": "Default",
                                                "value": "normal",
                                                "key": "normal",
                                            },
                                            {
                                                "title": "Drinks",
                                                "value": "drinks",
                                                "key": "drinks",
                                            },
                                            {
                                                "title": "Shisha",
                                                "value": "shisha",
                                                "key": "shisha",
                                            }
                                        ]}
                                        placeholder="Please select"
                                        onChange={theme => {
                                            setCategoryDetails(prevState => {
                                                return {
                                                    ...prevState,
                                                    theme_name: theme
                                                }
                                            })
                                        }}
                                    />


                                </FormControl>
                            </div>}
                            <div className="col-md-12 col-12">
                                <FormControl disabled={submitting} className="w-100 mb-2">
                                    <label htmlFor="prices_types">Price Type</label>

                                    <FormGroup>
                                        {
                                            _pricesTypes.map(item => {
                                                return (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox color="primary"
                                                                      checked={isValidArray(prices_types_ids) && prices_types_ids.includes(item.id)}
                                                                      onChange={(y) => {
                                                                          const selected_id = y.target.value;
                                                                          const exist = prices_types_ids.includes(item.id);
                                                                          setCategoryDetails(prevState => {
                                                                              if (exist) {
                                                                                  return {
                                                                                      ...prevState,
                                                                                      prices_types_ids: prevState.prices_types_ids.filter(r => {
                                                                                          return r !== selected_id;
                                                                                      })
                                                                                  }

                                                                              } else {
                                                                                  return {
                                                                                      ...prevState,
                                                                                      prices_types_ids: [
                                                                                          ...prevState.prices_types_ids,
                                                                                          selected_id
                                                                                      ]
                                                                                  }
                                                                              }

                                                                          })


                                                                      }}
                                                                      value={item.id}
                                                            />
                                                        }
                                                        label={item.title}
                                                    />
                                                )
                                            })
                                        }


                                    </FormGroup>

                                </FormControl>
                            </div>

                            <div className="col-md-12 col-12" style={{marginTop: 10}}>

                                <TextInput
                                    id="image"
                                    label={getText('app.categories.image')}
                                    type={"file"}
                                    helperText={image_file == null && <ErrorHint/>}
                                    onChange={(e) => renderImage(e)}
                                    margin="normal"
                                    fullWidth
                                    submitting={submitting}
                                />
                                {/*setLogoFileName(file.file);*/}
                                {$imagePreviewRender}


                            </div>

                            <div className="col-md-12 col-12">
                                <Button disabled={submitting} title={"Submit"} href={null} onClick={() => {
                                    addCategory()
                                }}>Submit</Button>
                            </div>


                        </form>
                    </CardBox>

                    {authUser.type === "senior" && <CardBox cardStyle={{height: "auto"}} styleName={"col-lg-4"}
                                                            heading={<IntlMessages
                                                                id="app.categories.form"/>}>

                        <ReactJson src={categoryDetails}/>
                    </CardBox>}


                    <SweetAlert show={message.open}
                                error={message.type === "error"}
                                info={message.type === "info"}
                                warning={message.type === "warning"}
                                success={message.type === "success"}
                                confirmBtnText={message.btnText}
                                title={message.title}
                                confirmBtnStyle={{margin: 0}}
                                onConfirm={() => {

                                    if (message.hasOwnProperty("onConfirm")) {
                                        message.onConfirm();
                                    }
                                    setMessage({
                                        ...message,
                                        open: false
                                    });
                                }}>
                        {message.text}
                    </SweetAlert>
                </div>
            </div>
        );
    } else {
        return (<div><h2>{JSON.stringify(selectedRestaurant)}</h2></div>)
    }


}


export default withRouter(Form);

