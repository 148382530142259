import React from "react";


const NoImage = () => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            viewBox="0 0 400 400"
            height="100%"
        >
            <circle
                cx={200}
                cy={200}
                r={196.812}
                fill="none"
                stroke="#8c8c8c"
                strokeWidth={7.41}
            />
            <text
                data-name="No Image AVAIlable"
                transform="matrix(.919 0 0 .919 200 179.867)"
                style={{
                    textTransform: "uppercase",
                }}
                fontSize={62.5}
                fill="#686868"
                textAnchor="middle"
                fontFamily="Myriad Pro"
            >
                <tspan x={0}>{"No Image"}</tspan>
                <tspan x={0} dy={75}>
                    {"AVAIlable"}
                </tspan>
            </text>
        </svg>
    )
}


export default NoImage;
