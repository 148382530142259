import * as PusherPushNotifications from "@pusher/push-notifications-web";
import NotificationsConfig, {channelsAndEvents} from "../constants/NotificationsConfig";
import Pusher from "pusher-js";


export const pusher = ()=>{
    const beamsClient = new PusherPushNotifications.Client({
        instanceId: NotificationsConfig.pusher.beams.instanceId,
    });

    beamsClient
        .start()
        .then(() => beamsClient.getDeviceId())
        .then((deviceId) => {
            console.log(deviceId); // Will log something like web-1234-1234-1234-1234
        })
        .catch((e) => console.error("Could not get device id", e));
}


export const listenToCustomChannel = (channel_name, event_name, method)=>{
    const pusher = new Pusher(NotificationsConfig.pusher.channels.key, {
        //
        cluster: NotificationsConfig.pusher.channels.cluster,
    });
    const channel = pusher.subscribe(channel_name);

    channel.bind(event_name, function (dataFromServer) {

        typeof method == "function" && method(dataFromServer);

    });
}
