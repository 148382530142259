import React, {useState, createContext, useEffect, useContext} from 'react';
import {
    getFromStorage, Helper,
    logger,
    saveToStorage
} from "../constants/Helper";
import {onCatch, onSuccess} from "../constants/serverHelper";
import {AuthContext} from "./AuthContext";
import {pages} from "../constants/Variables";
import {MainContext} from "./MainContext";
import {serverErrorCatcher} from "../constants/ErrorCodes";
import {Server} from "../constants/Server";
import {Normal} from "../constants/Normal";

export const UsersContext = createContext();
const UsersContextProvider = ({children}) => {

    const [usersList, setUsersList] = useState([]);
    const {rules, signOut} = useContext(AuthContext);

    const {pageName, bind, setServerResponse,setLoading} = useContext(MainContext);


    const fetchUsers = (_from = 0, _take = 20) => {
        setLoading(true);

        Server.getAllUsers().then(response => {
            setUsersList(response.data.data)
            setLoading(false);
            Helper.consoleServerResults(response)
        }).catch(e => {
            Helper.consoleServerResults(e.response)
        })

        // serverErrorCatcher(, 'UsersContext', 'fetchUsers', null, signOut);
    }

    const blockUser = (user) => {
        alert(JSON.stringify(user))
    }

    useEffect(() => {
        const usersListState = Normal.getFromStorage("users_list", true, true);

        if (usersListState) {
            setUsersList(usersListState);
        }
    }, [])

    useEffect(() => {
        (pageName === pages.users.view || pageName === pages.orders.view) && fetchUsers();
    }, [pageName, bind])

    useEffect(() => {

        Array.isArray(usersList) && usersList.length > 0 && Normal.saveToStorage("users_list", usersList, true);
    }, [usersList])


    const submitUser = (formJSON, method, setLoading, onSubmit = null) => {

        // logger("formJSON",formJSON);
        Server.registerUser(formJSON, method)
            .then(response => {
                onSubmit && onSubmit();
                fetchUsers();
                setServerResponse(response)
            })
            .catch(e => {
                setServerResponse(e.response)
            })
            .finally(() => {
                setLoading(false);
            })

    };


    return (
        <UsersContext.Provider
            value={{fetchUsers, usersList, blockUser, submitUser}}>
            {children}
        </UsersContext.Provider>
    );
};

export default UsersContextProvider;
