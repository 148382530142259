import React from 'react';
import ReactDOM from 'react-dom';
import { DndProvider } from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import Sortly, { ContextProvider } from 'react-sortly';

const ItemRenderer = (props) => {
    const { data: { name, depth }, drag, drop } = props;
    const ref = React.useRef(null);

    drag(drop(ref));

    return (
        <div ref={ref} style={{ marginLeft: depth * 20 }}>
            {name}
        </div>
    );
};

const MySortableTree = () => {
    const [items, setItems] = React.useState([
        { id: 1, name: 'Priscilla Cormier', depth: 0 },
        { id: 2, name: 'Miss Erich Bartoletti', depth: 0 },
        { id: 3, name: 'Alison Friesen', depth: 1 },
        { id: 4, name: 'Bernita Mayert', depth: 2 },
        { id: 5, name: 'Garfield Berge', depth: 0 },
    ]);
    const handleChange = (newItems) => {
        setItems(newItems);
    };

    return (
        <Sortly items={items} onChange={handleChange}>
            {(props) => <ItemRenderer {...props} />}
        </Sortly>
    );
};

const TreeView = ({history,lang,deleteRecord,categoryId,locale,setState,localProductList,selectedRestaurant}) => {
    return (
        <DndProvider backend={HTML5Backend}>
            <ContextProvider>
                <MySortableTree/>
            </ContextProvider>
        </DndProvider>
    );
}
export default TreeView;


/*

import React, {useState} from 'react';
import {show_sweet_message} from "../../../../../actions/Restaurant";
import {Server} from "../../../../../constants/Server";
import Sorter from "../../../../../localComponents/Sorter";
import {Normal} from "../../../../../constants/Normal";
import demoServer from "./demo";

const TreeView = ({history,lang,deleteRecord,categoryId,locale,setState,localProductList,selectedRestaurant}) => {

    return (
        <Sorter
            nameKey={'_name'}
            currentLanguage={locale.locale}
            sourceData={demoServer.data}
            dataRealLength={demoServer.data.filter(p => {
                return categoryId == 'all' ? true : categoryId == 'offers' ? !!p.is_offer : p.category_id == categoryId;
            }).length}
            // displayCondition={p => {
            //     return categoryId == 'all' ? true : categoryId == 'offers' ? !!p.is_offer : p.category_id == categoryId;
            // }}
            // canNodeHaveChildren={() => {
            //     return false;
            // }}
            onClickDelete={i => {
                setState(show_sweet_message({
                    type: "warning",
                    title: lang.confirmDelete,
                    confirmBtnText: lang.ok,
                    onConfirm: () => {
                        deleteRecord(i.id)
                    },
                }))
            }}
            onClickEdit={i => {
                history.push('/app/products/edit/' + i.id);
            }}
            onClickPreview={i => {
                history.push('/app/products/product/' + i.id);
            }}
            sortServerHandle={(d) => {
                return Server.sortProducts(d)
            }}
            keys={{
                array_key: "newTreeData",
                selected_node_id: "selected_product_id",
                new_parent_id: "new_parent_id",
            }}/>
    )
}

export default TreeView;

const styles = () => {
    return {
        container: {
            justifyContent: "center",
            alignItems: "center",
            flex: 1
        }
    }
}
*/
