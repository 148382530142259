import React, {useEffect, useState} from 'react';

import {Route, BrowserRouter, Switch} from 'react-router-dom';
import App from './containers/App';
import AuthContextProvider from "./context/AuthContext";
import UsersContextProvider from "./context/UsersContext";
import SweetAlertContextProvider from "./context/SweetAlertContext";
import MainContextProvider from "./context/MainContext";
import CollectContextProvider from "./context/CollectContext";
import CategoriesContextProvider from "./context/CategoriesContext";
import ProductsContextProvider from "./context/ProductsContext";
import RestaurantsContextProvider from "./context/RestsurantsContext";
import SettingsContextProvider from "./context/SettingsContext";
import AdsContextProvider from "./context/AdsContext";
import OrdersContextProvider from "./context/OrdersContext";
import FirebaseContextProvider from "./Notifications/FirebaseContext";
// import JSONViewer from "./localComponents/JSONViewer";
import {Normal} from "./constants/Normal";
import {Server} from "./constants/Server";

const MainApp = () => {

    const [startApp, setStartApp] = useState(false);
    useEffect(() => {
        Server.login_anonymous().then(res => {
            setStartApp(true);
            Normal.saveToStorage('LIVE-TOKEN', res.data.data.access_token);
        }).catch(e => {

        })

    }, []);

    if (!startApp) {
        return (<div/>);
    }



    return (
        <BrowserRouter>
            <MainContextProvider>
                <SweetAlertContextProvider>
                    <AuthContextProvider>
                        <SettingsContextProvider>
                            <RestaurantsContextProvider>
                                <CategoriesContextProvider>
                                    <ProductsContextProvider>
                                        <AdsContextProvider>
                                            <OrdersContextProvider>
                                                <UsersContextProvider>
                                                    <FirebaseContextProvider>
                                                        <CollectContextProvider>
                                                            <Switch>
                                                                <Route path="/" component={App}/>
                                                            </Switch>
                                                            {/*{!Server.isProduction() && <JSONViewer/>}*/}
                                                        </CollectContextProvider>
                                                    </FirebaseContextProvider>
                                                </UsersContextProvider>
                                            </OrdersContextProvider>
                                        </AdsContextProvider>
                                    </ProductsContextProvider>
                                </CategoriesContextProvider>
                            </RestaurantsContextProvider>
                        </SettingsContextProvider>
                    </AuthContextProvider>
                </SweetAlertContextProvider>
            </MainContextProvider>
        </BrowserRouter>
    )
}


export default MainApp;
