import React, {useContext, useEffect, useState} from 'react';

import ContainerHeader from 'components/ContainerHeader/index';
import CardBox from '../../../../../components/CardBox/index';


import IntlMessages from '../../../../../util/IntlMessages';
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Select from 'react-select';
import {Button} from "@material-ui/core";
import {logger} from "../../../../../constants/Helper";
import {withRouter} from "react-router";
import BasicColorPicker from '../../../../../components/BasicColorPicker';
import {CollectContext} from "../../../../../context/CollectContext";
import {AuthContext} from "../../../../../context/AuthContext";
import {RestaurantsContext} from "../../../../../context/RestsurantsContext";
import TextInput from "../../../../../localComponents/TextInput/TextInput";
import {getText, isValidArray} from "../../../../../constants/Functions";
import {SettingsContext} from "../../../../../context/SettingsContext";
import {MainContext} from "../../../../../context/MainContext";
import {pages} from "../../../../../constants/Variables";
import {changePageName} from "../../../../../actions";
import Switch from "@material-ui/core/Switch";
import ReactJson from "react-json-view";
import {show_sweet_message} from "../../../../../actions/Restaurant";




const currencies = [
    {
        value: 'USD',
        label: '$',
    },
    {
        value: 'IQD',
        label: 'IQ',
    },
];


const Form = ({match, history}) => {
    const s = {
        ok: getText('buttons.ok'),
        done: getText('buttons.done'),
        fill_required_fields: getText('messages.fill_required_fields'),
        updated: getText('app.restaurants.updated_success'),
        created: getText('app.restaurants.created_success'),
    }
    const {setState, fetchAll} = useContext(CollectContext);
    const {languagesOptions, countriesOptions, citiesOptions, fetchCities} = useContext(SettingsContext);
    const {authUser} = useContext(AuthContext);
    const {restaurantsList, fetchAllRestaurants, submitRestaurant, selectedRestaurant} = useContext(RestaurantsContext);
    const {loading, setLoading, pageName} = useContext(MainContext);

    const resId = match.params.resId;

    const [restaurantsFields, setRestaurantsFields] = useState({
        restaurant_id: null,
        restaurant_name: "",
        domain_prefix: "",
        firebase_authentication: "",
        bundle_app_ids: "",
        restaurant_type: "standard",
        restaurant_languages: [],
        app_version:1,
        restaurant_currency: "$",
        restaurant_color: "#ffffff",
        restaurant_country: "",
        restaurant_city: "",
        upgraded: resId ? true : false,
        has_posters: false,
        firebase_key: "",
        related_to: null,
        image_file: null,
    })
    const [restaurantId, setRestaurantId] = useState(resId);
    const [imagePreview, setImagePreview] = useState(null);

    const [restaurants, setRestaurants] = useState([]);

    const [validate, setValidate] = useState(false);


    useEffect(() => {
        if (restaurantId) {
            setFormData();
        }
        setState(changePageName(pages.restaurants.form))
        let _restaurants = [];

        restaurantsList && restaurantsList.length > 0 && restaurantsList.map((restaurant) => {

            _restaurants.push({value: restaurant.id, label: restaurant.restaurant_name})

        });
        setRestaurants(_restaurants);
    }, [restaurantsList])


    useEffect(() => {
        fetchAllRestaurants();
    }, [])

    const _setState = (key, value) => {
        setRestaurantsFields(prevState => ({
            ...prevState,
            [key]: value
        }))
    }
    const setFormData = () => {


        let selectedRes = restaurantsList ? restaurantsList.filter((res) => {
            return res.id == restaurantId;
        }) : [];

        if (selectedRes.length) {
            selectedRes = selectedRes[0];


            _setState('restaurant_name', selectedRes.restaurant_name)
            _setState('restaurant_type', selectedRes.type)
            _setState('restaurant_country', {"value": selectedRes.country[0], "label": selectedRes.country[1]})

            if (selectedRes.color !== null) {
                _setState('restaurant_color', selectedRes.color)
            }

            onChangeCountry(selectedRes.country[0], () => {
                _setState('restaurant_city', {"value": selectedRes.city, "label": selectedRes.city})
            });
            _setState('restaurant_city', {"value": selectedRes.city, "label": selectedRes.city})


            restaurantsList && restaurantsList.map((res) => {
                if (res.id === selectedRes.related_to_restaurant_id) {
                    _setState('related_to', {"value": res.id, "label": res.restaurant_name})
                }
            })
            _setState('restaurant_languages', selectedRes.languages.map(l=>{
                delete l.language;
                return l;
            }))
            _setState('restaurant_currency', {"value": selectedRes.currency, "label": selectedRes.currency})

            _setState('upgraded', selectedRes.upgraded == 1)
            _setState('app_version', selectedRes.app_version)
            _setState('has_posters', selectedRes.has_posters == 1)
            _setState('firebase_key', selectedRes.firebase_key)
            _setState('bundle_app_ids', selectedRes.bundle_app_ids)
            _setState('firebase_authentication', JSON.stringify(selectedRes.firebase_authentication))
            _setState('domain_prefix', selectedRes.domain_prefix)
            _setState('image_file', selectedRes.image_file)
            setImagePreview(selectedRes.logo_path);
        }

    }

    const validateFields = () => {
        const {
            restaurant_name,
            domain_prefix,
            restaurant_type,
            restaurant_languages,
            related_to,
            restaurant_currency,
            restaurant_color,
            restaurant_country,
            restaurant_city,
            image_file
        } = restaurantsFields;

        let ErrorMessage = null;

        if (restaurant_name === "") {
            ErrorMessage = getText('app.restaurants.restaurant_name_error');
        } else if (domain_prefix === "") {
            ErrorMessage = getText('app.restaurants.domain_prefix_error');
        } else if (image_file === null) {
            ErrorMessage = getText('app.restaurants.logo_error');
        } else if (restaurant_languages.length === 0) {
            ErrorMessage = getText('app.restaurants.languages_error');
        } else if (restaurant_country === null) {
            ErrorMessage = getText('app.restaurants.country_error');
        } else if (restaurant_city === null) {
            ErrorMessage = getText('app.restaurants.city_error');
        }

        if (ErrorMessage !== null) {
            setState(show_sweet_message({
                open: true,
                type: "error",
                confirmBtnText: s.ok,
                title: "Error",
                body: ErrorMessage
            }))

            return false;
        } else {
            return true;
        }

    }

    const addRestaurant = () => {

        const isBase64 = require('is-base64');

        setLoading(true);

        if (!validateFields()) {
            setLoading(false);
            return;
        }

        const {
            restaurant_name,
            domain_prefix,
            bundle_app_ids,
            firebase_authentication,
            firebase_key,
            related_to,
            restaurant_type,
            restaurant_languages,
            restaurant_currency,
            upgraded,
            app_version,
            has_posters,
            restaurant_color,
            restaurant_country,
            restaurant_city,
            image_file
        } = restaurantsFields;
        const formData = new FormData();
        if (restaurantId) {
            formData.append('restaurant_id', restaurantId);
        }
        formData.append('restaurant_name', restaurant_name);
        formData.append('domain_prefix', domain_prefix);
        formData.append('restaurant_type', restaurant_type);


        isValidArray(restaurant_languages) && restaurant_languages.map((item) => {
            formData.append('restaurant_languages[]', item.value);
        })
        firebase_authentication && formData.append('firebase_authentication', firebase_authentication);
        bundle_app_ids && formData.append('bundle_app_ids', bundle_app_ids);
        firebase_key && formData.append('firebase_key', firebase_key);
        formData.append('restaurant_currency', restaurant_currency.value);
        formData.append('restaurant_color', restaurant_color);
        formData.append('restaurant_country', restaurant_country.value);
        formData.append('restaurant_city', restaurant_city.value);
        formData.append('restaurant_app_version', app_version.toString());
        formData.append('upgraded', upgraded.toString());
        formData.append('has_posters', has_posters.toString());
        related_to && formData.append('related_to', related_to.value);

        const base64 = isBase64(image_file, {allowMime: true}) ? image_file.substr(image_file.indexOf(',') + 1, image_file.length) : null;

        base64 ? formData.append('image_file', image_file) : logger("failed", image_file);

        submitRestaurant(formData, () => {
            history.push('/app/restaurants/view');
        });


    }

    const onChangeCountry = (country_id, fun = null) => {
        fetchCities(country_id, () => {
            // _setState('restaurant_city', "")
        })
    }

    const handleChange = name => event => {
        let value;


        if (name === "related_to" ||
            name === "restaurant_country" ||
            name === "restaurant_city" ||
            name === "restaurant_languages" ||
            name === "restaurant_currency"
        ) {
            value = event;

            name === "restaurant_country" && onChangeCountry(value.value);
        } else if (name === "restaurant_color") {
            value = event.hex;
        } else {
            value = event.target.value;
        }
        if (name === "domain_prefix" || name === "app_version") {
            value = value.toLowerCase();
        }
        if (name === "firebase_authentication") {

            try {
                var objKeysRegex = /({|,)(?:\s*)(?:')?([A-Za-z_$\.][A-Za-z0-9_ \-\.$]*)(?:')?(?:\s*):/g;// look for object names

                value = event.target.value;
                value = value.replace(objKeysRegex, "$1\"$2\":");// all object names should be double quoted
                value = JSON.parse(value);
                value = JSON.stringify(value);


                value = value.replaceAll('\n','');
                value = value.replaceAll('  ','');
                value = value.trim();

                logger('firebase_authentication', value)
            } catch (e) {
                value = JSON.stringify(e);
            }
        }

        _setState(name, value);
    };


    const renderImage = (e) => {

        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onload = (e) => {
            _setState('image_file', e.target.result)
        }
        reader.onloadend = () => {
            setImagePreview(reader.result)
        };

        reader.readAsDataURL(file)
    }



    const ErrorHint = () => (
        <Typography variant="span" color={"error"} component="span"><IntlMessages
            id="sidebar.cards.form.validate"/></Typography>
    );

    const {
        restaurant_name,
        domain_prefix,
        bundle_app_ids,
        firebase_authentication,
        firebase_key,
        restaurant_type,
        restaurant_languages,
        restaurant_currency,
        upgraded,
        app_version,
        has_posters,
        related_to,
        restaurant_color,
        restaurant_country,
        restaurant_city,
        image_file
    } = restaurantsFields;

    let $imagePreviewRender = null;
    if (imagePreview) {
        $imagePreviewRender = (<img className={'image-previewer'} style={{backgroundColor: restaurant_color}} src={imagePreview}/>);
    }
    return (
        <div className="animated slideInUpTiny animation-duration-3">
            <ContainerHeader title={<IntlMessages id="app.restaurants.add"/>} match={match}/>

            {/*<h3>{JSON.stringify(restaurantsFields)}</h3>*/}
            <div className="row">
                <CardBox styleName={`${authUser.type == 'senior' ? 'col-lg-8' : 'col-lg-12'} justify-content-center`}>
                    <form className="row" noValidate autoComplete="off">
                        <div className={"col-lg-6"}>
                            {/*restaurant_name*/}
                            <div className="col-lg-12 col-md-12 col-12">
                                <h4 className="font-weight-light"><IntlMessages
                                    id="app.restaurants.restaurant_name"/> *</h4>
                                <Input
                                    id="restaurant_name"
                                    className="marginBottom"
                                    value={restaurant_name}
                                    disabled={loading}
                                    fullWidth
                                    onChange={handleChange('restaurant_name')}
                                />
                            </div>

                            {/*sub_domain*/}
                            <div className="col-lg-12 col-md-12 col-12">
                                <h4 className="font-weight-light"><IntlMessages
                                    id="app.restaurants.sub_domain"/> *</h4>
                                <Input
                                    id="domain_prefix"
                                    className="marginBottom"
                                    value={domain_prefix}
                                    disabled={loading}
                                    fullWidth
                                    onChange={handleChange('domain_prefix')}
                                    endAdornment={<InputAdornment position="end">.topdesign.pro</InputAdornment>}
                                />
                            </div>

                            {/*app_version*/}
                            <div className="col-lg-12 col-md-12 col-12">
                                <h4 className="font-weight-light"><IntlMessages
                                    id="app.restaurants.app_version"/> *</h4>
                                <Input
                                    id="domain_prefix"
                                    className="marginBottom"
                                    value={app_version}
                                    disabled={loading}
                                    fullWidth
                                    onChange={handleChange('app_version')}
                                />
                            </div>

                            {/*bundle_app_ids*/}
                            <div className="col-lg-12 col-md-12 col-12">
                                <h4 className="font-weight-light">Bundle App Ids</h4>
                                <Input
                                    id="bundle_app_ids"
                                    className="marginBottom"
                                    value={bundle_app_ids}
                                    disabled={loading}
                                    fullWidth
                                    onChange={handleChange('bundle_app_ids')}
                                />
                            </div>

                            {/*firebase_key*/}
                            <div className="col-lg-12 col-md-12 col-12">
                                <h4 className="font-weight-light">Firebase Key</h4>
                                <Input
                                    id="firebase_key"
                                    className="marginBottom"
                                    value={firebase_key}
                                    disabled={loading}
                                    fullWidth
                                    onChange={handleChange('firebase_key')}
                                />
                            </div>

                            {/*restaurant_type*/}
                            <div className="col-lg-12 col-md-12 col-12">
                                <FormControl component="fieldset" required>
                                    <h4 className="font-weight-light"><IntlMessages
                                        id="app.restaurants.restaurant_type"/></h4>
                                    <RadioGroup
                                        aria-label="restaurant_type"
                                        name="restaurant_type"
                                        className="marginBottom"
                                        value={restaurant_type}
                                        disabled={loading}
                                        onChange={handleChange('restaurant_type')}
                                    >
                                        <FormControlLabel value="standard" control={<Radio color="primary"/>}
                                                          label={getText('app.restaurants.restaurant_type.standard')}/>
                                        <FormControlLabel value="delivery" control={<Radio color="primary"/>}
                                                          label={getText('app.restaurants.restaurant_type.ordering')}/>
                                        {/*<FormControlLabel value="disabled" disabled control={<Radio />} label="Disabled" />*/}
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            {/*restaurant_upgraded*/}
                            <div className="col-md-12 col-12">
                                <FormControlLabel
                                    disabled={loading}
                                    control={
                                        <Switch color="primary"
                                                classes={{
                                                    checked: 'text-primary',
                                                    bar: 'bg-primary',
                                                }}
                                                checked={upgraded}
                                                onChange={(event, checked) => setRestaurantsFields(prevState => ({
                                                    ...prevState,
                                                    upgraded: checked
                                                }))}
                                        />
                                    }
                                    label={<IntlMessages id="app.restaurants.upgraded"/>}
                                />
                            </div>

                            {/*restaurant_has_posters*/}
                            <div className="col-md-12 col-12">
                                <FormControlLabel
                                    disabled={loading}
                                    control={
                                        <Switch color="primary"
                                                classes={{
                                                    checked: 'text-primary',
                                                    bar: 'bg-primary',
                                                }}
                                                checked={has_posters}
                                                onChange={(event, checked) => setRestaurantsFields(prevState => ({
                                                    ...prevState,
                                                    has_posters: checked
                                                }))}
                                        />
                                    }
                                    label={<IntlMessages id="app.restaurants.has_posters"/>}
                                />
                            </div>


                            {/*restaurant_related_to*/}
                            <div className="col-lg-12 col-md-12 col-12">
                                <FormControl className="w-100 mb-2">
                                    <h4 className="font-weight-light"><IntlMessages
                                        id="app.restaurants.restaurant_related_to"/></h4>
                                    <Select
                                        value={restaurants.filter(_restaurant => {
                                            return _restaurant.value == related_to
                                        })[0]}
                                        options={restaurants}
                                        disabled={loading}
                                        onChange={handleChange('related_to')}
                                        style={{textTransform: "capitalize"}}
                                    />
                                </FormControl>
                            </div>

                            {/*restaurant_currency*/}
                            <div className="col-lg-12 col-md-12 col-12">
                                <FormControl className="w-100 mb-2">
                                    <h4 className="font-weight-light"><IntlMessages
                                        id="app.restaurants.restaurant_currency"/></h4>
                                    <Select
                                        value={restaurant_currency}
                                        options={currencies}
                                        disabled={loading}
                                        onChange={handleChange('restaurant_currency')}
                                        style={{textTransform: "capitalize"}}
                                    />
                                </FormControl>
                            </div>

                            {/*firebase_authentication*/}
                            <div className="col-lg-12 col-md-12 col-12">
                                <h4 className="font-weight-light">Firebase Authentication</h4>
                                <Input
                                    id="firebase_authentication"
                                    className="marginBottom"
                                    value={firebase_authentication}
                                    disabled={loading}
                                    multiline
                                    fullWidth
                                    onChange={handleChange('firebase_authentication')}
                                />
                            </div>

                        </div>
                        <div className={"col-lg-6"}>
                            {/*restaurant_country*/}
                            <div className="col-lg-12 col-md-12 col-12">
                                <FormControl className="w-100 mb-2">
                                    <h4 className="font-weight-light"><IntlMessages
                                        id="app.restaurants.restaurant_country"/></h4>
                                    <Select
                                        value={restaurant_country}
                                        options={countriesOptions}
                                        disabled={loading}
                                        onChange={handleChange('restaurant_country')}
                                        style={{textTransform: "capitalize"}}
                                    />
                                </FormControl>
                            </div>

                            {/*restaurant_city*/}
                            <div className="col-lg-12 col-md-12 col-12">
                                <FormControl className="w-100 mb-2">
                                    <h4 className="font-weight-light"><IntlMessages
                                        id="app.restaurants.restaurant_city"/></h4>
                                    <Select
                                        value={restaurant_city}
                                        options={citiesOptions}
                                        disabled={loading}
                                        onChange={handleChange('restaurant_city')}
                                        style={{textTransform: "capitalize"}}
                                    />
                                </FormControl>
                            </div>

                            {/*restaurant_languages*/}
                            <div className="col-lg-12 col-md-12 col-12">
                                <h4 className="font-weight-light"><IntlMessages
                                    id="app.restaurants.restaurant_languages"/></h4>
                                <Select
                                    isMulti
                                    value={restaurant_languages}
                                    options={languagesOptions}
                                    disabled={loading}
                                    onChange={handleChange('restaurant_languages')}
                                    style={{textTransform: "capitalize"}}
                                />
                            </div>

                            {/*restaurant_logo*/}
                            <div className="col-lg-12 col-md-12 col-12">
                                <h4 className="font-weight-light"><IntlMessages
                                    id="app.restaurants.restaurant_logo"/></h4>
                                <TextInput
                                    id="image"
                                    type={"file"}
                                    required
                                    helperText={image_file == null && validate && <ErrorHint/>}
                                    onChange={(e) => renderImage(e)}
                                    margin="normal"
                                    fullWidth
                                    submitting={loading}
                                />
                                {$imagePreviewRender}


                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h4 className="font-weight-light"><IntlMessages
                                    id="app.restaurants.restaurant_color"/></h4>
                                <BasicColorPicker initialColor={restaurant_color}
                                                  onChange={handleChange('restaurant_color')}/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <Button title={getText('buttons.done')}
                                    disabled={loading} href={null} onClick={() => {
                                addRestaurant()
                            }}>{getText('buttons.submit')}</Button>
                        </div>
                    </form>
                </CardBox>

                {authUser.type === "senior" && <CardBox cardStyle={{height: "auto"}} styleName={"col-lg-4"}
                                                        heading={<IntlMessages
                                                            id="app.restaurants.form"/>}>

                    <ReactJson src={restaurantsFields}/>
                </CardBox>}

            </div>
        </div>
    );

}


export default withRouter(Form);

