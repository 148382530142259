import React, {useContext, useRef, useState} from 'react';
import '../style.scss';
import CSV from 'csvtojson';
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {Normal} from "../../../../constants/Normal";
import ReactJson from "react-json-view";
import db from '../dbFields';
import {getText} from "../../../../constants/Functions";
import {Server} from "../../../../constants/Server";
import dbFields from "../dbFields";
import {show_sweet_message} from "../../../../actions/Restaurant";
import {CollectContext} from "../../../../context/CollectContext";
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import {Alert} from "antd";


const hints = 'import_export.db.products_hints';


const Import = ({match}) => {
    const s = {
        ok: getText('buttons.ok'),
        done: getText('buttons.done'),
        error: getText('error.title'),
        fill_required_fields: getText('messages.fill_required_fields'),
        empty_error_price: getText('import_export.db.empty_error.price'),
        empty_error_name: getText('import_export.db.empty_error.en_name'),
    }

    const inputRef = useRef(null);

    const {setState} = useContext(CollectContext);

    const [csvFile, setCSVFile] = useState(null);
    const [csvContent, setCSVContent] = useState({});

    const [finalMatches, setFinalMatches] = useState({});

    const convertCSVToJSON = (contents) => {
        (async () => {
            const jsonObj = await CSV().fromString(contents)
            setCSVContent(jsonObj);
        })();
    }

    const fetchFileContents = (file) => {
        var reader = new FileReader();
        reader.onload = function () {
            convertCSVToJSON(reader.result);
        };
        // start reading the file. When it is done, calls the onload event defined above.
        reader.readAsBinaryString(file);
    }

    const onFileChange = (e) => {
        try {
            let file = e.target.files[0];
            setCSVFile(file);
            // var size = file.size / (1024 * 1024);

            fetchFileContents(file);
        } catch (e) {

        }

    }

    const clearFile = () => {
        setCSVFile(null);
        setCSVContent({})
    }

    const onChooseOptions = (key, value) => {

        if (value != 'Not Selected') {
            setFinalMatches({
                ...finalMatches,
                [key]: value
            })
        } else {
            delete finalMatches[key];
            setFinalMatches({
                ...finalMatches
            })
        }

    }

    const databaseFieldsDropdown = (dbKey) => {
        return (
            <Select
                native
                variant={'outlined'}
                value={finalMatches.hasOwnProperty(dbKey) ? finalMatches[dbKey] : null}
                onChange={(v) => onChooseOptions(dbKey, v.target.value)}
            >
                <option value={null}>Not Selected</option>

                {
                    Normal.isValidArray(csvContent) && Object.keys(csvContent[0]).map((key, index) => {
                        return (<option key={key}
                                        value={key}>{key}</option>)
                    })
                }
            </Select>
        )
    }

    const validate = () => {

        const keys = Object.keys(finalMatches);

        const checkEnglishName = keys.filter((key, index) => {
            return key.indexOf('en_name') > -1;
        })

        if (checkEnglishName.length === 0) {
            setState(show_sweet_message({
                open: true,
                type: "error",
                confirmBtnText: s.ok,
                title: s.error,
                body: s.empty_error_name
            }))
            return false;
        }
        const checkOnePrice = keys.filter((key, index) => {
            return key.indexOf('price') > -1;
        })
        if (checkOnePrice.length === 0) {
            setState(show_sweet_message({
                open: true,
                type: "error",
                confirmBtnText: s.ok,
                title: s.error,
                body: s.empty_error_price
            }))
            return false;
        }

        return true;
    }
    const onSubmit = () => {

        if (!validate()) {
            return;
        }

        const csvContentsArray = [];

        Normal.isValidArray(csvContent) && csvContent.map(item => {
            let j = {};
            const dbSelectedFields = Object.keys(finalMatches);


            dbSelectedFields.map(key => {
                j[key] = item[finalMatches[key]]
            })
            csvContentsArray.push({
                ...j
            })
        })


        Server.importProducts(csvContentsArray)
            .then(res => {


            })
            .catch(e => {

            })
    }


    return (
        <div className="app-wrapper" id={'csv-exporter'}>
            {/*<div className={'json-viewer'}>*/}
            {/*    <ReactJson src={finalMatches}/>*/}
            {/*</div>*/}

            <Alert type="warning" message={getText('import_export.db.warning')} />
            {csvFile && <div className={"selected-file-container"}>
                <div className={"selected-file"}>
                    <label>
                        {csvFile.name}
                    </label>
                    <button onClick={clearFile}>
                        {getText('import_export.clear')}
                    </button>
                </div>

                <div className={'compression-table-container'}>
                    <table>
                        <tr>
                            <th>{getText('import_export.our_fields')}</th>
                            <th>{getText('import_export.your_fields')}</th>
                        </tr>
                        {
                            db.products.map((item, index) => {
                                const priceField = item.key.indexOf("price") > -1;
                                const textField = item.key.indexOf("name") > -1 || item.key.indexOf("details") > -1;
                                return (
                                    <tr key={item.key}>
                                        <td className={priceField ? 'price-fields' : textField ? 'text-fields' : 'normal-fields'}>
                                            <span>{getText(`${hints}.${item.key}`)} </span>
                                            <span>({item.key})</span>
                                            <br/>
                                            <span>{getText('import_export.db.products_hints')}: </span>
                                            <span>{item.hint}</span>
                                        </td>
                                        <td>{databaseFieldsDropdown(item.key)}</td>
                                    </tr>
                                )
                            })
                        }
                    </table>
                </div>


                <div className={'footer'}>
                    <button onClick={onSubmit}>
                        {getText('buttons.submit')}
                    </button>
                </div>


            </div>}

            {!csvFile && <div className={'export-button'}>
                <button onClick={() => inputRef.current.click()}>
                    {getText('import_export.choose_csv_file')}
                </button>

                <input ref={inputRef} type={"file"}
                       onChange={event => onFileChange(event)}
                       hidden accept=".csv"/>


            </div>}
        </div>
    )
};

export default Import;
