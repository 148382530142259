import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Form from "./routes/form";
import View from "./routes/view";
import NotFound from 'app/routes/extraPages/routes/404';
import ProductView from "./routes/productView";

const Products = ({match}) => {

    return(
        <div className="app-wrapper">
            <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}/view`}/>
                <Route path={`${match.url}/add`} component={Form}/>
                <Route path={`${match.url}/edit/:productId`} component={Form}/>
                <Route path={`${match.url}/view`} component={View}/>
                <Route path={`${match.url}/product/:productId`} component={ProductView}/>
                <Route component={NotFound}/>
            </Switch>
        </div>
    );
}

export default Products;
