export function strippingDate(str, toInt = false) {
    const st = str.replace("/", "")
        .replace("/", "")
        .replace("Date", "")
        .replace("(", "")
        .replace(")", "");


    return toInt ? parseInt(st) : st;
} //From /Date(1572336000000)/ to 1572336000000

export function fixFormat(_date) {
    const isNotDateFormat = _date.length === 8;
    let date = _date;

    if (isNotDateFormat) {
        const yyyy = _date.substr(0, 4);
        const mm = _date.substr(4, 2);
        const dd = _date.substr(6, 2);

        date = yyyy + "-" + mm + "-" + dd;
    }


    return stringDateToFormat(date);
} //From 20190205 to any date format

export function stringDateToFormat(_date) {
    var d = new Date(_date);

    return d.toDateString();
}

/**
 * @return {string}
 */
export function CodeToTime(t) {
    var date = new Date(null);
    date.setMinutes(t); // specify value for SECONDS here
    return date.toISOString().substr(11, 5);
}

/**
 * @return {string}
 */
export function Unix_timestamp(t) {
    var timestamp = strippingDate(t, true);
    // return timestamp;
    var dt = new Date(timestamp);

    return `${dt.toDateString()}`;

}

function convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
}

function timeStampSplit(a){
    var year = a.getFullYear();
    var month = a.getMonth();
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();

    return{year,month,date,hour,min}
}
export function timeConverter(UNIX_timestamp, withTime = false, withDate = false) {
    // var timezone = DeviceInfo.getTimezone();
    var a = new Date(UNIX_timestamp);

    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    min = min === 0 ? "00" : min;

    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min;
    if (withTime && !withDate) {
        time = hour + ':' + min;
    }
    if (withDate && !withTime) {
        time = date + ' ' + month + ' ' + year;
    }
    return time;
}

export function timeConverterUTC(UNIX_timestamp, withTime = false, withDate = false) {
    // var timezone = DeviceInfo.getTimezone();



    var a = new Date(UNIX_timestamp);

    a = new Date(new Date(a).setHours(a.getHours() + 3));


    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    var month = months[timeStampSplit(a).month];
    var hour = timeStampSplit(a).hour;
    hour = hour.toString().length === 1 ? "0" + hour : hour;
    var min = timeStampSplit(a).min;
    min = min === 0 ? "00" : min;





    var time = timeStampSplit(a).date + ' ' + month + ' ' + timeStampSplit(a).year + ' - ' + hour + ':' + min;
    if (withTime && !withDate) {
        time = hour + ':' + min;
    }
    if (withDate && !withTime) {
        time = timeStampSplit(a).date + ' ' + month + ' ' + timeStampSplit(a).year;
    }
    return time;
}


export function getMinDate(data) {
    var $dateResult = null;
    data.map((ob) => {
        const toInt = ob.dtFrom;

        if ($dateResult === null || toInt < $dateResult) {
            $dateResult = toInt;
        }
    })

    return $dateResult;
}

export function getMaxDate(data) {
    var $dateResult = null;
    data.map((ob) => {
        const toInt = ob.dtTo;

        if ($dateResult === null || toInt > $dateResult) {
            $dateResult = toInt;
        }
    })

    return $dateResult;
}


function increaseDate(_min, _def) {
    return _min + (_def * 60000)
}

export function getTableHeads(_min, _max, _def) {
    let newArray = [];
    let newMin = _min;

    while (timeConverterUTC(newMin, true) < timeConverterUTC(_max, true)) {
        newArray.push({
            time: timeConverter(newMin, true)
        })
        newMin = increaseDate(newMin, _def);

    }

    newArray.push({
        time: timeConverter(_max, true)
    })

    return newArray;


}

export function timeStampToJson(dateTimeString) {
    var a = new Date(dateTimeString);

    var year = a.getUTCFullYear();
    var month = a.getUTCMonth();
    var day = a.getUTCDay();
    var hour = a.getUTCHours();
    var min = a.getUTCMinutes();

    return {
        year,
        month,
        day,
        hour,
        min
    }

}

export function isDifferent(_value1, _value2) {
    var value1 = new Date(_value1);
    var value2 = new Date(_value2);


    value1 = value1.setUTCHours(0, 0, 0, 0);
    value2 = value2.setUTCHours(0, 0, 0, 0);


    return value1 !== value2;


}

export function createRows(data, dates) {
    var $final = [];
    var $dateResult = [];
    var lastTimeStamp = null;

    // console.log(dates,"datescreateRows");


    data.map((ob) => {


        const dtFrom = ob.dtFrom;

        const toDT = ob.dtTo;
        if (lastTimeStamp === null || isDifferent(lastTimeStamp, dtFrom)) {


            lastTimeStamp = ob.dtFrom;


            const fromDT = lastTimeStamp;

            $dateResult.push([
                {
                    from: timeConverterUTC(fromDT, true),
                    to: timeConverterUTC(toDT, true),
                    date: timeConverterUTC(toDT, false, true),
                    lastTimeStamp,
                    count: $dateResult.length
                }
            ])
        } else {
            $dateResult[$dateResult.length - 1].push(
                {
                    from: timeConverterUTC(dtFrom, true),
                    to: timeConverterUTC(toDT, true),
                    lastTimeStamp,
                    count: $dateResult.length
                }
            )
        }
    })
    // console.log($dateResult,"$dateResult");

    dates.forEach(function (date_) {

        const findExist = $dateResult.filter(function (_date) {
            return _date[0].date == date_;
        });

        if (findExist.length) {
            $final.push(findExist[0])
        } else {
            $final.push([{
                from: null,
                to: null,
                date: date_,
                lastTimeStamp: null,
                count: $dateResult.length
            }])
        }
    });
    // console.log(dates,"dates");
    // console.log($final,"$final");

    return $final;
}

export function _createRows(data) {

    var $dateResult = [];
    var lastTimeStamp = null;

    data.map((ob) => {

        var dtFrom = ob.dtFrom;

        // var dtTo = timeStampToJson(ob.dtTo);


        const toDT = ob.dtTo;
        if (lastTimeStamp === null || isDifferent(lastTimeStamp, dtFrom)) {


            lastTimeStamp = ob.dtFrom;


            const fromDT = lastTimeStamp;


            $dateResult.push([
                {
                    from: timeConverterUTC(fromDT, true),
                    to: timeConverterUTC(toDT, true),
                    date: timeConverterUTC(toDT, false, true),
                    lastTimeStamp,
                    count: $dateResult.length
                }
            ])
        } else {
            $dateResult[$dateResult.length - 1].push(
                {
                    from: timeConverterUTC(dtFrom, true),
                    to: timeConverterUTC(toDT, true),
                    lastTimeStamp,
                    count: $dateResult.length
                }
            )
        }
    })

    return $dateResult;
}


export function getDate(key, data) {
    // console.log(data, key, "getDatedata");

    if (key === "min") {
        let arrayDate = [];
        data.map((ob) => {
            const time = new Date(ob.dtFrom);
            arrayDate.push(time);
        });

        // console.log(arrayDate, "getDatearrayDate");

        return Math.min.apply(null, arrayDate);
    } else {
        let arrayDate = [];
        data.map((ob) => {
            const time = new Date('01 01 2020 ' + timeConverterUTC(ob.dtTo, true));
            arrayDate.push(time.getTime());
        });

        // console.log(arrayDate, key, "getDatearrayDate");

        return Math.max.apply(null, arrayDate);

    }


}

export function getDateM(data, dailyScheduleDefault) {
    let min = null;
    let max = null;
    let fromValues = [];
    let toValues = [];

    // console.log(data, "data");

    data.map((ob) => {

        if (ob[0].from) {
            fromValues.push(ob[0].from);
        }

        if (ob[ob.length - 1].to) {
            toValues.push(ob[ob.length - 1].to);
        }

    });
    // console.log(dailyScheduleDefault, "dailyScheduleDefault");

    fromValues.sort();
    toValues.sort();

    min = fromValues[0];
    max = toValues[toValues.length - 1];


    // console.log(min, "min");
    // console.log(max, "max");
    // console.log('01-01-1999 ' + min + ':00');
    // console.log('01-01-2020 ' + max + ':00');

    const minTimeString = '01-01-2020 ' + min + ':00';
    const maxTimeString = '01-01-2020 ' + max + ':00';
    let minTime = makeDate(minTimeString);
    let maxTime = makeDate(maxTimeString);


    // console.log("minTimeString:", minTimeString);
    // console.log("maxTimeString:", maxTimeString);
    // console.log("minTime:", minTime, minTime.getTime());
    // console.log("maxTime:", maxTime, maxTime.getTime());
    return getTableHeads(minTime.getTime(), maxTime.getTime(), dailyScheduleDefault);

}

function makeDate(date) {
    return new Date(
        parseInt(date.slice(0, 4)),
        parseInt(date.slice(5, 7)) - 1,
        parseInt(date.slice(8, 10)),
        parseInt(date.slice(11, 13)),
        parseInt(date.slice(14, 16)),
        0,
        0
    );
}

export function checkTimeInRang(_time1, _time2, _time3) {

    if (_time3.length == 4) {
        _time3 = "0" + _time3;
    }

    return _time3 >= _time1 && _time3 < _time2;
}


export function getDates(startDate, endDate) {
    var dates = [],
        currentDate = startDate,
        addDays = function (days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };
    while (currentDate <= endDate) {
        dates.push(timeConverter(currentDate, false, true));
        currentDate = addDays.call(currentDate, 1);
    }
    return dates;
}
