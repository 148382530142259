import React, {useState, useEffect, useContext} from 'react';
import {useIntl} from "react-intl";
import {UsersContext} from "../../../../../context/UsersContext";
import './style.scss';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {Dialog, DialogContent} from "@material-ui/core";
import {getText, isValidArray} from "../../../../../constants/Functions";
import {timeConverterUTC} from "../../../../../constants/DatesManager";

const ViewContact = ({userId, viewUser, setViewUser}) => {

    const i = useIntl();
    const intl = (id) => {
        return i.formatMessage({id})
    };
    let filterOptions = [
        {
            id: 1,
            name: intl('app.users.all'),
            type: 'all',
            icon: 'zmdi-menu'
        }, {
            id: 2,
            name: intl('app.users.admin'),
            type: 'admin',
            icon: 'zmdi-time-restore'

        }, {

            id: 3,
            name: intl('app.users.user'),
            type: 'user',
            icon: 'zmdi-star'
        }, {

            id: 4,
            name: intl('app.users.driver'),
            type: 'driver',
            icon: 'zmdi-star'
        }, {

            id: 5,
            name: intl('app.users.advertiser'),
            type: 'advertiser',
            icon: 'zmdi-star'
        }, {

            id: 6,
            name: intl('app.users.senior'),
            type: 'senior',
            icon: 'zmdi-star'
        }
    ];


    const {usersList, fetchUsers, changeMerchantRequestStatus} = useContext(UsersContext);
    const [user, setUser] = useState(null);


    useEffect(() => {
        usersList.map((u) => {
            if (u.id == userId) {
                setUser(u);
            }
        })
    }, [userId]);

    useEffect(() => {

    }, [user])

    if (!user) {
        return (<div/>)
    }

    const {full_name,username,birthday,last_login,orders_number,created_at_human,language,created_at,is_active,addresses,user_type,phone_number,gender, image_file_full} = user;
    const _addresses = addresses?JSON.parse(addresses):[];

    return (
        <Dialog id={'contact-card'}
                fullWidth={true}
                maxWidth={'md'}
                aria-labelledby="max-width-dialog-title"
                open={viewUser} onClose={() => {
        }}>

            <AppBar className="position-relative">
                <Toolbar style={{backgroundColor: '#282828'}}>
                    <DialogTitle itemProp={{
                        style: {
                            color: '#fff !important'
                        }
                    }}>{full_name}</DialogTitle>

                    <IconButton onClick={() => {
                        setViewUser(null)
                    }} aria-label="Close">
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <div className={'row'}>
                    <div className="col-xl-4 col-md-5 col-lg-6 col-12">
                        <div className="media-body">
                            <span className="mb-0 text-grey jr-fs-md">{intl('app.users.username')}</span>
                            <p className="mb-3">
                                <span className="jr-link">{username}</span>
                            </p>
                        </div>
                        <div className="media-body">
                            <span className="mb-0 text-grey jr-fs-md">{intl('app.users.full_name')}</span>
                            <p className="mb-3">
                                <span className="jr-link">{full_name}</span>
                            </p>
                        </div>
                        <div className="media-body">
                            <span className="mb-0 text-grey jr-fs-md">{intl('app.users.gender')}</span>
                            <p className="mb-3">
                                <span className="jr-link">{getText('app.users.gender.'+gender)}</span>
                            </p>
                        </div>
                        <div className="media-body">
                            <span className="mb-0 text-grey jr-fs-md">{intl('app.users.birthday')}</span>
                            <p className="mb-3">
                                <span className="jr-link">{birthday}</span>
                            </p>
                        </div>
                        <div className="media-body">
                            <span className="mb-0 text-grey jr-fs-md">{intl('app.users.phone')}</span>
                            <p className="mb-3">
                                <span className="jr-link">0{phone_number}</span>
                            </p>
                        </div>
                        <div className="media-body">
                            <span className="mb-0 text-grey jr-fs-md">{intl('app.users.email')}</span>
                            <p className="mb-3">
                                <span className="jr-link">{user.email}</span>
                            </p>
                        </div>
                        {isValidArray(_addresses) && <div className="media-body">
                            <span className="mb-0 text-grey jr-fs-md">{intl('app.users.addresses')}</span>
                            {
                                _addresses.map((ad,index) => {
                                    return (
                                        <p className="mb-1">
                                            <span className="jr-link">{index + 1} - {ad.location}</span>
                                        </p>
                                    )
                                })
                            }

                        </div>}
                    </div>
                    <div className="col-xl-4 col-md-5 col-lg-6 col-12">
                        <div className="media-body">
                            <span className="mb-0 text-grey jr-fs-md">{intl('app.users.joined_date')}</span>
                            <p className="mb-3">
                                <span className="jr-link">{created_at_human}</span>
                            </p>
                            <p className="mb-1">
                                <span className="jr-link">{timeConverterUTC(created_at,false,true)}</span>
                            </p>
                        </div>
                        <div className="media-body">
                            <span className="mb-0 text-grey jr-fs-md">{intl('app.users.last_login')}</span>
                            <p className="mb-3">
                                <span className="jr-link">{timeConverterUTC(last_login,false,true)}</span>
                            </p>
                        </div>
                        <div className="media-body">
                            <span className="mb-0 text-grey jr-fs-md">{intl('app.users.status')}</span>
                            <p className="mb-3">
                                <span className="jr-link">{intl(is_active?`app.categories.isActive`:'app.categories.isInActive')}</span>
                            </p>
                        </div>
                        <div className="media-body">
                            <span className="mb-0 text-grey jr-fs-md">{intl('app.users.type')}</span>
                            <p className="mb-3">
                                <span className="jr-link">{intl(`app.users.${user_type}`)}</span>
                            </p>
                        </div>
                        <div className="media-body">
                            <span className="mb-0 text-grey jr-fs-md">{intl('app.users.language')}</span>
                            <p className="mb-3">
                                <span className="jr-link">{language.toUpperCase()}</span>
                            </p>
                        </div>
                        <div className="media-body">
                            <span className="mb-0 text-grey jr-fs-md">{intl('app.restaurants.restaurant_name')}</span>
                            <p className="mb-3">
                                <span className="jr-link">{user.restaurant_name}</span>
                            </p>
                        </div>
                        <div className="media-body">
                            <span className="mb-0 text-grey jr-fs-md">{intl('app.users.orders_number')}</span>
                            <p className="mb-3">
                                <span className="jr-link">{orders_number}</span>
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-2 col-lg-6 col-12" style={{alignItems:'center',display:'flex'}}>
                        <div className={'avatar-container'}>
                            <img src={image_file_full}/>
                        </div>


                    </div>
                </div>
            </DialogContent>
        </Dialog>

    )
}

export default ViewContact;
