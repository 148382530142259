import React, {useContext, useEffect, useState} from 'react';
import ContainerHeader from 'components/ContainerHeader/index';
import CardBox from '../../../../../components/CardBox/index';
import IntlMessages from '../../../../../util/IntlMessages';
import {Button} from "@material-ui/core";
import {
    checkParams,
    consoleLog,
    filterCategories,
    logger,
    searchInObject
} from "../../../../../constants/Helper";
import {show_sweet_message} from "../../../../../actions/Restaurant";
import {TreeSelect} from 'antd';
import 'antd/dist/antd.css';
import ImageInput from "../../../../../components/ImageInput";
import {CollectContext} from "../../../../../context/CollectContext";
import {RestaurantsContext} from "../../../../../context/RestsurantsContext";
import {CategoriesContext} from "../../../../../context/CategoriesContext";
import {ProductsContext} from "../../../../../context/ProductsContext";
import {AuthContext} from "../../../../../context/AuthContext";
import TextInput from "../../../../../localComponents/TextInput/TextInput";
import {SettingsContext} from "../../../../../context/SettingsContext";
import {getText, isValidArray} from "../../../../../constants/Functions";
import {withRouter} from "react-router";
import {Server} from "../../../../../constants/Server";
import {changePageName} from "../../../../../actions";
import {pages} from "../../../../../constants/Variables";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {Identity} from "../../../../../constants/Identity";
import ReactJson from "react-json-view";
import Extras from "../../../../../localComponents/Extras";
import {forEach} from "react-bootstrap/ElementChildren";



const Form = ({match, history, location}) => {

    const s = {
        ok: getText('buttons.ok'),
        done: getText('buttons.done'),
        fill_required_fields: getText('messages.fill_required_fields'),
        updated: getText('app.products.updated'),
        created: getText('app.products.created'),
    }

    const {setState, fetchAll} = useContext(CollectContext);
    const {authUser} = useContext(AuthContext);
    const {selectedRestaurant} = useContext(RestaurantsContext);
    const {categoriesList, searchForCategory} = useContext(CategoriesContext);
    const {productsList} = useContext(ProductsContext);
    const {locale, pricesTypes} = useContext(SettingsContext);


    const productId = match.params.productId;

    const sampleProduct = {
        product_id: productId,
        category_id: null,
        is_active: 1,
        product_name: {},
        product_description: {},
        is_offer: 0,
        image_file: null,
        prices: {},
        choices: []
    }

    const [productFields, setProductFields] = useState({...sampleProduct})

    const [categoryPricesTypes, setCategoryPricesTypes] = useState([]);

    const [loading, setLoading] = useState(false);


    const {
        product_id,
        category_id,
        image_file,
        is_active,
        product_name,
        is_offer,
        prices,
        product_description,
        choices
    } = productFields;


    const _setState = (key, value) => {
        setProductFields(prevState => ({
            ...prevState,
            [key]: value
        }))
    }

    const searchPricesTypes = (selectedCategory) => {
        setCategoryPricesTypes([]);
        logger("selectedCategory", selectedCategory)
        if (!selectedCategory) {
            return;
        }
        const ids =selectedCategory.prices_types? selectedCategory.prices_types:[];

        isValidArray(pricesTypes) && pricesTypes.map(i => {
            if (ids.includes(i.id)) {
                    /*
                                    setProductFields(prevState => ({
                                        ...prevState,
                                        prices: {
                                            [i.id]: prevState.prices[i.id] ? prevState.prices[i.id] : 0
                                        }
                                    }))
                    */
                    setCategoryPricesTypes(prevState => {
                        return [...prevState, i]
                    })
            }
        })

    }

    const translations = (i, key) => {
        const j = {};

        selectedRestaurant.languages && selectedRestaurant.languages.map((language, index) => {

            const nameKey = language.code + key;
            const name = i[nameKey];

            j[language.code] = name
        })
        return j;
    }
    const searchForProduct = () => {
        let sp = productsList && productsList.filter((p) => {
            return p.id === product_id;
        })

        if (Array.isArray(productsList) && sp.length > 0) {
            return sp[0];
        }
        return null;
    }

    const setProductDetails = () => {
        const p = searchForProduct();

        if (!p) {
            return;
        }



        let _prices = {};
        isValidArray(p.prices) && p.prices.map(res => {
            _prices[res.price_type_id] = res.price;
            _prices['sale_'+res.price_type_id] = res.sale_price;
        })

        setProductFields({
            product_id: p.id,
            category_id: p.category_id,
            is_active: p.is_active,
            product_name: {
                ...translations(p,'_name')
            },
            product_description: {
                ...translations(p,'_details')
            },
            is_offer: p.is_offer,
            image_file: p.image_file_full,
            prices: {
                ..._prices
            },
            choices: [
                ...p.choices.map(c=>{
                    return {
                        "id": c.extra_choice_id,
                        "product_id": c.product_id,
                        "restaurant_id": c.restaurant_id,
                        "price": c.price,
                        "created_at": c.created_at,
                        "updated_at": c.updated_at,
                        "slug": c.slug,
                        "title": translations(c,'_name'),
                        "isDeletable": true
                    }
                })
            ]
        })

        categoriesList && searchPricesTypes(searchForCategory(p.category_id, categoriesList))

    }

    useEffect(() => {
        setState(changePageName(pages.products.form))
    }, [])

    useEffect(() => {
        setProductDetails();
    }, [productsList, categoriesList]);

    const validateFields = () => {

        let errorMessage = false;

        selectedRestaurant.languages && selectedRestaurant.languages.map((language, index) => {
            if (language.code !== "en") return;
            const key = product_name[language.code];
            if ((typeof key == "undefined" || key === "") && !errorMessage) {
                errorMessage = true;
            }
        })


        if (!errorMessage) {
            if (!prices) {
                errorMessage = true;
            } else if (category_id === null) {
                errorMessage = true;
            }
        }

        if (errorMessage) {
            setState(show_sweet_message({
                open: true,
                type: "error",
                confirmBtnText: s.ok,
                title: "Error",
                body: s.fill_required_fields
            }))
        }
        return !errorMessage;
    }

    const addProduct = () => {
        setLoading(true);
        if (!validateFields()) {
            setLoading(false);
            return;
        }

        const formData = new FormData();

        if (product_id !== null) {
            formData.append('product_id', product_id);
        }

        const pricesJson = {};
        Object.keys(prices).map(item => {

            if(!item.includes('sale_') && !pricesJson.hasOwnProperty(item)){
                pricesJson[item] = {
                    id:item,
                    mainPrice:null,
                    salePrice:null
                }
            }

            if(item.includes('sale_')){
                pricesJson[item.replace("sale_","")].salePrice = prices[item];
            }else{
                pricesJson[item].mainPrice =  prices[item];
            }

        })
        Object.values(pricesJson).map(item => {
            formData.append('prices[]', JSON.stringify({
                ...item
            }));
        })

        choices.map(item => {
            formData.append('choices[]', JSON.stringify(item));
        })

        formData.append('category_id', category_id);
        formData.append('user_id', authUser.id);
        formData.append('is_offer', is_offer);
        formData.append('is_active', is_active);

        selectedRestaurant.languages && selectedRestaurant.languages.map((language, index) => {
            formData.append(language.code + "_name", product_name[language.code]);
        });

        selectedRestaurant.languages && selectedRestaurant.languages.map((language, index) => {
            formData.append(language.code + "_description", product_description[language.code]);
        });

        formData.append('image_file', image_file);


       Server.addNewProduct(formData)
            .then(res => {
                setState(show_sweet_message({
                    type: "success",
                    title: product_id ? s.updated : s.created,
                    showCancel: false,
                    body: s.done,
                    confirmBtnText: s.ok,
                    onConfirm: () => {
                        if (product_id !== null) {
                            history.push('/app/products/view');
                        } else {
                            emptyForm();
                        }
                    },
                }))
            })
            .catch(error => {
                setState(show_sweet_message({
                    open: true,
                    type: "error",
                    confirmBtnText: s.ok,
                    showCancel: false,
                    title: "Error",
                    body: error?.response?.data?.message
                }))
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const emptyForm = () => {
        setProductFields({
            ...sampleProduct
        })
    }

    const handleChange = (key, name) => event => {
        let value = event.target.value;

        if (key === "name") {
            let proNames = {
                ...product_name,
                [name]: value
            };

            _setState('product_name', proNames)
        } else if (key === "description") {
            let proDescriptions = {
                ...product_description,
                [name]: value
            };

            _setState('product_description', proDescriptions)
        } else if (key === "prices") {
            if (value < 0) {
                return;
            }
            value = value.replace(/[^0-9]/g, '');

            let prices = {
                ...productFields.prices,
                [name]: value,
            }
            _setState('prices', prices)
        } else {

        }
    }

    const onlyNumberKey = (evt) => {

        // Only ASCII character in that range allowed
        const  ASCIICode = (evt.which) ? evt.which : evt.keyCode
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
            return false;
        return true;
    }

    const categories_list = categoriesList && categoriesList.filter((c) => {
        return c.id !== "All";
    })

    const restaurantCurrency = selectedRestaurant ? selectedRestaurant.currency : '';

    if (selectedRestaurant !== null && typeof selectedRestaurant === "object") {
        return (
            <div className="animated slideInUpTiny animation-duration-3">
                <ContainerHeader title={<IntlMessages id="app.products.add"/>} match={match}/>

                <div className="row">
                    <CardBox
                        styleName={`${authUser.type == 'senior' ? 'col-lg-8' : 'col-lg-12'} justify-content-center`}>
                        <form className="row" noValidate autoComplete="off">
                            {/*Product Name*/}
                            <div className="col-md-12 col-12">
                                <h4 className="font-weight-light"><IntlMessages
                                    id="app.products.product_name"/></h4>
                                <div className="col-md-12 col-12 row">
                                    {
                                        selectedRestaurant.languages && selectedRestaurant.languages.map((language, index) => {
                                            return (
                                                <div key={language.label.toUpperCase() + "_name"}
                                                     className="col-md-4 col-12">
                                                    <TextInput
                                                        id={language.code + "_name"}
                                                        value={product_name[language.code]}
                                                        required={language.code === 'en'}
                                                        onChange={handleChange("name", language.code)}
                                                        submitting={loading}
                                                        label={language.label.toUpperCase() + " Name"}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </div>

                            {/*Product Description*/}
                            <div className="col-md-12 col-12">
                                <h4 className="font-weight-light"><IntlMessages
                                    id="app.products.product_description"/></h4>
                                <div className="col-md-12 col-12 row">

                                    {
                                        selectedRestaurant.languages && selectedRestaurant.languages.map((language, index) => {
                                            return (
                                                <div key={language.label.toUpperCase() + "_description"}
                                                     className="col-md-4 col-12">
                                                    <TextInput
                                                        id={language.code + "_description"}
                                                        value={product_description[language.code]}
                                                        onChange={handleChange("description", language.code)}
                                                        submitting={loading}
                                                        multiline
                                                        height={80}
                                                        rowsMax="4"
                                                        label={language.label.toUpperCase()}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            {/*Activation*/}
                            <div className="col-md-12 col-12">
                                <FormControlLabel
                                    disabled={loading}
                                    control={
                                        <Switch color="primary"
                                                classes={{
                                                    checked: 'text-primary',
                                                    bar: 'bg-primary',
                                                }}
                                                checked={is_active == 1}
                                                onChange={(event, checked) => setProductFields(prevState => ({
                                                    ...prevState,
                                                    is_active: checked ? 1 : 0
                                                }))}
                                        />
                                    }
                                    label={<IntlMessages id="app.categories.isActive"/>}
                                />
                            </div>
                            {/*{Identity.isDeliveryRestaurant(selectedRestaurant) &&*/}
                            {!Identity.isRestaurantNamed(selectedRestaurant,'Kiwimango') && <div className="col-md-12 col-12">
                                <FormControlLabel
                                    disabled={loading}
                                    control={
                                        <Switch color="primary"
                                                classes={{
                                                    checked: 'text-primary',
                                                    bar: 'bg-primary',
                                                }}
                                                checked={is_offer == 1}
                                                onChange={(event, checked) => setProductFields(prevState => ({
                                                    ...prevState,
                                                    is_offer: checked ? 1 : 0
                                                }))}
                                        />
                                    }
                                    label={<IntlMessages id="app.categories.isOffer"/>}
                                />
                            </div>}
                            {/*}*/}

                            {/*Category*/}
                            <div className="col-md-12 col-12" style={{marginTop: 20, marginBottom: 20}}>
                                {/*<h4 className="font-weight-light"><IntlMessages*/}
                                {/*    id="app.products.product_category"/></h4>*/}
                                <TreeSelect
                                    treeDataSimpleMode
                                    treeDefaultExpandAll
                                    placeholder={getText('app.products.product_category') + " *"}
                                    value={category_id}
                                    style={{width:'100%'}}
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto',width: '100%'}}
                                    treeData={filterCategories(categories_list, authUser, locale.locale)}
                                    onChange={(id) => {
                                        searchPricesTypes(searchInObject(categories_list, 'id', id))
                                        _setState('category_id', id)
                                    }}
                                />

                            </div>
                            {isValidArray(categoryPricesTypes) &&
                            <div className="col-md-12 col-12 row" style={{marginTop: 20, marginBottom: 20}}>
                                {
                                    categoryPricesTypes.map(o => {
                                        return (
                                            <div className="col-lg-8 col-md-8 col-12 row">
                                            <div className="col-lg-6 col-md-6 col-6">
                                                {/*<h4 className="font-weight-light">{o.title[locale.locale]}</h4>*/}
                                                <TextInput
                                                    id={o.id}
                                                    value={checkParams(prices, [o.id]).replace('.00', '')}
                                                    onChange={handleChange('prices', o.id)}
                                                    type={'text'}
                                                    onKeyPress={onlyNumberKey}
                                                    submitting={loading}
                                                    label={o.title[locale.locale] + ' ' + restaurantCurrency + " *"}
                                                />
                                            </div>
                                        <div className="col-lg-6 col-md-6 col-6">
                                            {/*<h4 className="font-weight-light">{o.title[locale.locale]}</h4>*/}
                                            <TextInput
                                                id={o.id}
                                                value={checkParams(prices, ['sale_'+o.id]).replace('.00', '')}
                                                onChange={handleChange('prices', 'sale_'+o.id)}
                                                type={'text'}
                                                onKeyPress={onlyNumberKey}
                                                submitting={loading || !checkParams(prices, [o.id]).replace('.00', '')}
                                                label={'Sale Price ' + restaurantCurrency + " *"}
                                            />
                                        </div>
                                        </div>
                                        )
                                    })
                                }
                            </div>}

                            <div className="col-md-12 col-12">
                                <ImageInput
                                    loading={loading}
                                    allowVideos
                                    allowImages
                                    maxSize={20}
                                    fileInfo={e => {
                                        // setMetadata({
                                        //     height: e.height,
                                        //     width: e.width
                                        // });
                                    }}
                                    source={image_file}
                                    label={getText('app.products.media_file')}
                                    errorFunc={(e) => {
                                        setState(show_sweet_message({
                                            open: true,
                                            type: "error",
                                            confirmBtnText: s.ok,
                                            showCancel: false,
                                            title: "Error",
                                            body: e.message
                                        }))
                                    }}
                                    onChange={(image) => {
                                        _setState('image_file', image)
                                    }}/>
                            </div>

                            {Identity.isDeliveryRestaurant(selectedRestaurant) &&
                            <Extras choices={choices} setExtraChoices={choices => {
                                setProductFields(prevState => ({
                                    ...prevState,
                                    choices
                                }))
                            }} languages={selectedRestaurant.languages ? selectedRestaurant.languages : []}/>}

                            <div className="col-md-12 col-12">
                                <Button disabled={loading} href={null} onClick={() => {
                                    addProduct()
                                }}>{getText('buttons.submit')}</Button>
                            </div>
                        </form>
                    </CardBox>

                    {authUser.type === "senior" && <CardBox cardStyle={{height: "auto"}} styleName={"col-lg-4"}
                                                            heading={<IntlMessages
                                                                id="app.products.form"/>}>

                        <ReactJson src={productFields}/>
                    </CardBox>}


                </div>
            </div>
        );
    } else {
        return (<div><h2>Select Restaurant</h2></div>)
    }


}


export default withRouter(Form);

