import React, {useContext, useEffect, useState} from 'react';
import CardBox from 'components/CardBox/index';
import IntlMessages from 'util/IntlMessages';
import DataTable from '../../../../../components/Datatables';
import {ButtonGroup} from 'reactstrap';
import Button from '@material-ui/core/Button';


import SweetAlert from 'react-bootstrap-sweetalert'
import {Delete, Done, Edit, ImportExport} from '@material-ui/icons';
import {withRouter} from "react-router";
import {setSelectedRestaurant} from "../../../../../actions/Restaurant";
import ContainerHeader from 'components/ContainerHeader/index';
import {RestaurantsContext} from "../../../../../context/RestsurantsContext";
import {AuthContext} from "../../../../../context/AuthContext";
import {CollectContext} from "../../../../../context/CollectContext";
import {getText} from "../../../../../constants/Functions";
import {changePageName} from "../../../../../actions";
import {pages} from "../../../../../constants/Variables";


const View = ({match, history}) => {


    const {setState, fetchAll} = useContext(CollectContext);
    const {authUser} = useContext(AuthContext);
    const {restaurantsList, deleteRestaurant, selectedRestaurant} = useContext(RestaurantsContext);

    const [name, setName] = useState("");
    const [subdomain, setSubdomain] = useState("");
    const [password, setPassword] = useState("");
    const [restaurantId, setRestaurantId] = useState("");
    const [deleteKey, setDeleteKey] = useState("deactivate");
    const [deleteAlertActive, setDeleteAlertActive] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [restaurantWillDelete, setRestaurantWillDelete] = useState("");


    const columns = [
        {
            id: 'logo',
            align: true,
            disablePadding: false,
            label: 'Logo',
            renderedComponent: (row, index) => {
                return (
                    <img style={{height: 50, alignSelf: "center"}} src={row.logo_path}/>
                )
            }
        },
        {id: 'restaurant_name', align: true, disablePadding: false, label: 'Restaurant Name'},
        {
            id: 'domain_prefix', align: true, disablePadding: false, label: 'Subdomain',
            renderedComponent: (row, index) => {

                const url = 'https://' + row.domain_prefix + '.topdesign.pro'
                return (
                    <a href={url} target="_blank">{url}</a>
                )
            }
        },
        {
            id: 'type', align: true, disablePadding: false, label: 'Type',
            renderedComponent: (row, index) => {
                return (
                    <span>{row.type === 'standard' ? getText('app.restaurants.restaurant_type.standard') : getText('app.restaurants.restaurant_type.ordering')}</span>
                )
            }
        },
        {id: 'currency', align: true, disablePadding: false, label: 'Currency'},
        {
            id: 'upgraded', align: true, disablePadding: false, label: 'Upgraded', renderedComponent: (row, index) => {

                return (
                    <div className={"upgraded-status"}>
                        {row.upgraded === '1' && <h4>√</h4>}
                        {row.upgraded === '0' && <h4>x</h4>}
                    </div>
                )

            }
        },
        {
            id: 'actions', align: true, disablePadding: false, label: 'Actions', renderedComponent: (row, index) => {


                return (

                    <ButtonGroup vertical={false}>
                        <Button
                            style={{marginLeft: 5}}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                history.push('/app/import_and_export/' + row.id);
                            }}
                            href={""}>
                            <ImportExport/>
                        </Button>
                        <Button
                            style={{marginLeft: 5}}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                history.push('/app/restaurants/edit/' + row.id);
                            }}
                            href={""}>
                            <Edit/>
                        </Button>

                        <Button style={{marginLeft: 5}}
                                variant="contained"
                                className="bg-success text-white"
                                onClick={() => {
                                    setState(setSelectedRestaurant(row));
                                }}
                                href={""}>

                            <Done/>
                        </Button>

                        <Button style={{marginLeft: 5}}
                                variant="contained"
                                className="bg-danger text-white"
                                onClick={() => {
                                    setRestaurantWillDelete(row.id);
                                    setDeleteAlertActive(true);
                                }}
                                href={""}>
                            <Delete/>
                        </Button>
                    </ButtonGroup>

                )

            }
        },
    ];

    useEffect(() => {
        setTableColumns(columns);
        checkRestaurantExist();
        setState(changePageName(pages.restaurants.view))


    }, []);


    useEffect(() => {
        setTableData(restaurantsList)
    }, [restaurantsList])

    const deleteRecord = () => {
        if (selectedRestaurant && selectedRestaurant.id == restaurantWillDelete) {
            setState(setSelectedRestaurant(null));
        }

        deleteRestaurant({
            restaurant_id: restaurantWillDelete,
            delete_key: deleteKey
        }, () => {
            checkRestaurantExist();
        })

        setDeleteKey("deactivate");
        setDeleteAlertActive(false);
    }


    const checkRestaurantExist = () => {

        if (selectedRestaurant && restaurantsList) {
            if (!restaurantsList.find(x => x.id === selectedRestaurant.id)) {
                setState(setSelectedRestaurant(null));
            }
        }

    }

    document.onkeyup = function (e) {
        // logger("key",e.which)
        if (e.ctrlKey && e.altKey && e.shiftKey && e.which == 85) {//u
            if (deleteAlertActive) {
                setDeleteKey("delete");
            }
        }
    };

    return (
        <div className="animated slideInUpTiny animation-duration-3">


            {selectedRestaurant &&
            <ContainerHeader title={<>Selected Restaurant {selectedRestaurant.restaurant_name}</>}
                             match={match}/>}
            {!selectedRestaurant && <ContainerHeader title={"Restaurants list"} match={match}/>}

            <div className="row">
                <CardBox styleName="col-lg-12">
                    <DataTable
                        columns={tableColumns}
                        isActiveKey={'blocked'}
                        selectedItem={selectedRestaurant}
                        columnsData={tableData}
                        tableLabel={"Restaurants list"}
                        clickable={false}
                    />
                </CardBox>
            </div>

            <SweetAlert show={deleteAlertActive}
                        warning
                        showCancel
                        confirmBtnText={<IntlMessages id="sweetAlerts.yesDeleteIt"/>}
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="default"
                        title={<IntlMessages id="sweetAlerts.areYouSure"/>}
                        onConfirm={deleteRecord}
                        onCancel={() => setDeleteAlertActive(false)}
            >
                <IntlMessages id="sweetAlerts.youWillNotAble"/>
            </SweetAlert>
        </div>

    );
};

export default withRouter(View);

