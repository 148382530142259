// @flow
import * as React from 'react';

const EmptyPage = ({data=[],className="",children})=>{


    if(Array.isArray(data) && data.length == 0){
        return(<div/>)
    }else{
        return(<div className={className}>{children}</div>)
    }
}


export default EmptyPage;
