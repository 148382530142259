import React, { useState, createContext, useEffect, useContext } from "react";
import { compareTwoObjects, Helper, logger } from "../constants/Helper";
import { Server } from "../constants/Server";
import { AuthContext } from "./AuthContext";
import { RestaurantsContext } from "./RestsurantsContext";
import { MainContext } from "./MainContext";
import { pages } from "../constants/Variables";
import { Normal } from "../constants/Normal";

export const AdsContext = createContext();
const AdsContextProvider = (props) => {
  const { pageName, setServerResponse } = useContext(MainContext);
  const { authUser } = useContext(AuthContext);
  const { selectedRestaurant } = useContext(RestaurantsContext);
  const adsListState = Normal.getFromStorage("categories_list", true, true);
  const [adsList, setAdsList] = useState([]);

  const fetchAds = () => {
    Server.getAllAds()
      .then((response) => {
        setAdsList(response.data.data);
        Helper.consoleServerResults(response);
      })
      .catch((e) => {
        console.log(JSON.stringify(e.response));
        Helper.consoleServerResults(e.response);
      });
  };

  useEffect(() => {
    adsListState && setAdsList(adsListState);
  }, []);

  useEffect(() => {
    const requestRefresh =
      pageName === pages.ads.view || pageName === pages.ads.form;
    requestRefresh && selectedRestaurant && authUser && fetchAds();
  }, [selectedRestaurant, pageName, authUser]);

  return (
    <AdsContext.Provider value={{ adsList, setAdsList, fetchAds }}>
      {props.children}
    </AdsContext.Provider>
  );
};
export default AdsContextProvider;
