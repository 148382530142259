import React, {useContext} from "react";
import {Route, Switch} from "react-router-dom";
import Dashboard from "./dashboard";
import Users from './users';
import Categories from './categories';
import Products from './products';
import asyncComponent from "../../util/asyncComponent";
import {withRouter} from "react-router";
import {AuthContext} from "../../context/AuthContext";
import Restaurants from "./restaurants";
import Settings from "./settings";
import ImportExport from "./importExport";
import Ads from "../../app/routes/ads";
import Orders from "./orders";

const Routes = ({match}) => {

    const {rules, authUser} = useContext(AuthContext);


    return (

        <Switch>
            <Route path={`${match.url}/dashboard`} component={Dashboard}/>
            {rules.restaurants && <Route path={`${match.url}/restaurants`} component={Restaurants}/>}
            {rules.categories && <Route path={`${match.url}/categories`} component={Categories}/>}
            {rules.products && <Route path={`${match.url}/products`} component={Products}/>}
            {rules.ads && <Route path={`${match.url}/ads`} component={Ads}/>}
            {rules.users && <Route path={`${match.url}/users`} component={Users}/>}
            {rules.settings && <Route path={`${match.url}/settings`} component={Settings}/>}
            {rules.importExport && <Route path={`${match.url}/import_export`} component={ImportExport}/>}
            {rules.orders && <Route path={`${match.url}/orders`} component={Orders}/>}

            <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
        </Switch>
    )
};

export default withRouter(Routes);
