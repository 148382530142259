import {
    SELECT_RESTAURANT,
    SELECT_CATEGORY,
    SELECT_PRODUCT,
    FETCH_RESTAURANTS,
    FETCH_CATEGORIES,
    FETCH_PRODUCTS,
    FETCH_ADS,
    FETCH_ORDERS,
    FETCH_OFFERS,
    SHOW_SWEET_ALERT,
    ADDED_SOMETHING, FETCH_USERS, CHANGE_CURRENCY
} from "../constants/ActionTypes";




export const setSelectedRestaurant = (restaurant) => {
    return {
        type: SELECT_RESTAURANT,
        payload: restaurant
    };
};
export const select_category = (category) => {
    return {
        type: SELECT_CATEGORY,
        payload: category
    };
};
export const select_product = (product) => {
    return {
        type: SELECT_PRODUCT,
        payload: product
    };
};
export const fetch_restaurants = (restaurants) => {
    return {
        type: FETCH_RESTAURANTS,
        payload: restaurants
    };
};
export const fetch_categories = (categories) => {

    return {
        type: FETCH_CATEGORIES,
        payload: categories
    };
};
export const fetch_products = (products) => {
    return {
        type: FETCH_PRODUCTS,
        payload: products
    };
};
export const fetch_ads = (ads) => {
    return {
        type: FETCH_ADS,
        payload: ads
    };
};
export const fetch_users = (users) => {
    return {
        type: FETCH_USERS,
        payload: users
    };
};
export const something_changed = () => {
    return {
        type: ADDED_SOMETHING
    };
};
export const change_currency = (payload = null) => {
    return {
        type: CHANGE_CURRENCY,
        payload
    };
};

export const fetch_orders = (orders) => {

    return {
        type: FETCH_ORDERS,
        payload: orders
    };
};
export const fetch_offers = (offer) => {
    return {
        type: FETCH_OFFERS,
        payload: offer
    };
};
export const show_sweet_message = (payload = {})=>{
    return {
        type: SHOW_SWEET_ALERT, payload
    }
}
