import React, {useState} from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Slider from '@material-ui/core/Slider';
import './style.scss';
import {getText} from "../../../../../../constants/Functions";

const Filter = ({filters}) => {


    const singleSelect = ({label, value, data, label_key, label_alternative_key = 'none', value_key, onSelect}) => {
        return (
            <div className="col-lg-2 col-sm-4 col-12">
                <FormControl className="w-100 mb-2">
                    <InputLabel htmlFor="age-simple">{label}</InputLabel>
                    <Select
                        value={value}
                        onChange={(event) => {
                            onSelect(event.target.value)
                        }}
                        input={<Input id="ageSimple1"/>}
                    >
                        <MenuItem value="">
                            <em>{getText('app.orders.all')}</em>
                        </MenuItem>
                        {
                            data.map((item, index) => {
                                return (<MenuItem
                                    value={item[value_key]}>{item[label_key].length > 1 ? item[label_key] : item[label_alternative_key]}</MenuItem>)
                            })
                        }
                    </Select>
                </FormControl>
            </div>
        )
    }

    const rangeSlider = ({
                             label,
                             style = {},
                             options_type = 'number',
                             valueLabelFormat = (value) => value,
                             step = 5000,
                             value = [20, 40],
                             min,
                             max,
                             onChange
                         }) => {
        return (
            <div style={style} className="col-lg-2 col-sm-4 col-12">
                <FormControl className="w-100 mb-2">
                    <InputLabel style={{marginTop: '-10%', marginLeft: '-8%'}} htmlFor="age-simple">{label}</InputLabel>
                    <Slider
                        id={'range-slider'}
                        min={min}
                        max={max}
                        step={step}
                        onChangeCommitted={(r, range) => {
                            onChange(range)
                        }}
                        valueLabelDisplay="on"
                        valueLabelFormat={valueLabelFormat}
                        marks={[{value: min}, {value: max}]}
                        // value={value}
                        track={false}
                        aria-labelledby="track-false-slider"
                        defaultValue={[min, max]}
                    />
                </FormControl>
            </div>
        )
    }

    const Filters = {
        'single-select': singleSelect,
        'range-select': rangeSlider,
    }


    return (
        <div id={'table-filter'}>

            <div className="row">
                <div className="col-lg-1 col-sm-2 col-12" style={{alignItems: 'center', display: 'flex'}}>
                    <InputLabel style={{marginTop: 0}} htmlFor="age-simple" style={{
                        fontSize: '25px',
                        color: 'black'
                    }
                    }>{getText('app.orders.filter')}:</InputLabel>
                </div>
                {
                    Array.isArray(filters) && filters.map((obj, index) => {

                        return Filters[obj.type](obj)
                    })
                }
            </div>
        </div>
    )
}


export default Filter;
