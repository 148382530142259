import React, {useContext, useEffect, useRef, useState} from 'react';
import '../style.scss';
import CSV from 'csvtojson';
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {Normal} from "../../../../constants/Normal";
import ReactJson from "react-json-view";
import db, {demo} from '../dbFields';
import {getText} from "../../../../constants/Functions";
import {Server} from "../../../../constants/Server";
import dbFields from "../dbFields";
import {show_sweet_message} from "../../../../actions/Restaurant";
import {CollectContext} from "../../../../context/CollectContext";
import {makeStyles} from '@material-ui/core/styles';
import {Box, TextField} from '@material-ui/core';
import {Alert} from "antd";
import CsvDownload from 'react-json-to-csv'
import {ProductsContext} from "../../../../context/ProductsContext";
import {RestaurantsContext} from "../../../../context/RestsurantsContext";
import {changePageName} from "../../../../actions";
import {pages} from "../../../../constants/Variables";


const hints = 'import_export.db.products_hints';


const Export = ({match}) => {
    const s = {
        ok: getText('buttons.ok'),
        done: getText('buttons.done'),
        cancel: getText('buttons.cancel'),
        error: getText('error.title'),
        file_ready: getText('import_export.export.file_ready'),
        download: getText('import_export.export.download'),
        fill_required_fields: getText('messages.fill_required_fields'),
        empty_error_price: getText('import_export.db.empty_error.price'),
        empty_error_name: getText('import_export.db.empty_error.en_name'),
    }

    const downloadRef = useRef(null);

    const {setState} = useContext(CollectContext);
    const {selectedRestaurant} = useContext(RestaurantsContext);
    const {productsList} = useContext(ProductsContext);

    const [matchFields, setMatchFields] = useState({});
    const [finalDataCollection, setFinalDataCollection] = useState([]);


    useEffect(() => {
        setState(changePageName(pages.products.export))

        const mat = {};
        db.products.map((item, index) => {
            mat[item.key] = item.key;
        })
        setMatchFields(mat);


    }, [])

    const validate = () => {


        return true;
    }

    const getPrices = (product_item, item) => {
        let value = null;
        product_item.prices.map((price_item, index3) => {
            if (item.key.indexOf(price_item.en_name.toLowerCase()) > -1) {
                value = price_item.price;
            }
        })

        return value;
    }
    const onSubmit = () => {

        if (!validate()) {
            return;
        }

        const data = [];
        productsList.map((product_item, index1) => {
            const obj = {};
            db.products.map((item, index2) => {
                let value = product_item[item.key];

                if (item.key.indexOf('price') > -1) {
                    value = getPrices(product_item, item)
                }
                obj[matchFields[item.key]] = value;
            })

            data.push(obj);
        })
        setFinalDataCollection(data)

        setState(show_sweet_message({
            type: "warning",
            title: s.file_ready,
            showCancel: false,
            body: <CsvDownload style={{
                backgroundColor: 'green',
                color: 'white',
                outline: 'none',
                border: 'none',
                padding: '4px 16px',
                borderRadius: '6px',
            }
            } ref={downloadRef} data={finalDataCollection}
                               filename={selectedRestaurant.restaurant_name + ".csv"}
            >{s.download}</CsvDownload>,
            confirmBtnText: s.cancel,
            onConfirm: () => {

            },
        }))

    }


    return (
        <div className="app-wrapper" id={'csv-exporter'}>
            {/*<div className={'json-viewer'}>*/}
            {/*    <ReactJson src={finalMatches}/>*/}
            {/*</div>*/}

            <Alert type="warning" message={getText('import_export.db.warning')}/>
            {Normal.isValidArray(productsList) && <div className={"selected-file-container"}>
                <div className={'compression-table-container'}>

                    <table>
                        <tr>
                            <th>{getText('import_export.match_fields')}</th>
                        </tr>
                        {
                            db.products.map((item, index) => {
                                const priceField = item.key.indexOf("price") > -1;
                                const textField = item.key.indexOf("name") > -1 || item.key.indexOf("details") > -1;
                                return (
                                    <tr key={item.key}>
                                        <td className={priceField ? 'price-fields' : textField ? 'text-fields' : 'normal-fields'}>
                                            <TextField
                                                label={getText(`${hints}.${item.key === 'id' ? 'main_id' : item.key}`)}
                                                style={{width: '50%'}}
                                                variant="filled"
                                                onChange={(event) => {
                                                    setMatchFields({
                                                        ...matchFields,
                                                        [item.key]: event.currentTarget.value
                                                    })
                                                }}
                                                onBlur={(event) => {
                                                    if (event.currentTarget.value.length < 2) {
                                                        setMatchFields({
                                                            ...matchFields,
                                                            [item.key]: item.key
                                                        })
                                                    }
                                                }}
                                                value={matchFields[item.key]}/>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </table>
                </div>


                <div className={'footer'}>
                    <button onClick={onSubmit}>
                        {getText('buttons.submit')}
                    </button>
                </div>


            </div>}
        </div>
    )
};

export default Export;
