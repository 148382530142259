import React, {useState} from 'react';
import {CircularProgress} from "@material-ui/core";

const FullScreenLoading = ({text}) => {


    return (
        <div style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <CircularProgress color="secondary"/>
            <h2>{text}</h2>
        </div>
    )
}

export default FullScreenLoading;


