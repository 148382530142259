import React, {useContext, useEffect, useState} from 'react';

import IntlMessages from '../../../../../util/IntlMessages';
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Select from 'react-select';
import {Button} from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import {cleanPhoneNumber, jsonToDataForm} from "../../../../../constants/Helper";
import {withRouter} from "react-router";
import {CollectContext} from "../../../../../context/CollectContext";
import {UsersContext} from "../../../../../context/UsersContext";
import {AuthContext} from "../../../../../context/AuthContext";
import {MainContext} from "../../../../../context/MainContext";
import {RestaurantsContext} from "../../../../../context/RestsurantsContext";
import {pages} from "../../../../../constants/Variables";
import {changePageName} from "../../../../../actions";
import {getText} from "../../../../../constants/Functions";
import {Identity} from "../../../../../constants/Identity";
import RequiredStar from "../../../../../localComponents/RequiredStar";


const profileSample = {
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    primary_phone: '',
    primary_phone_cc: '964',
    password: '',
    password_confirmation: '',
    type: '',
    restaurant_id: null,
}
const Form = ({editableUserId = null, onSubmit = null}) => {

    const s = {
        prefix: getText('app.users.prefix'),
        ok: getText('buttons.ok'),
        done: getText('buttons.done'),
        fill_required_fields: getText('messages.fill_required_fields'),
        password_not_match: getText('messages.password_not_match'),
        opps: getText('error.title'),
        user: getText('app.users.user'),
        admin: getText('app.users.admin'),
        data: getText('app.users.data'),
        senior: getText('app.users.senior'),
        driver: getText('app.users.driver'),
        advertiser: getText('app.users.advertiser'),
        reader: getText('app.users.reader'),
    }

    const {setState} = useContext(CollectContext);
    const {usersList, submitUser, fetchUsers, changeMerchantRequestStatus} = useContext(UsersContext);
    const {authUser} = useContext(AuthContext)
    const {restaurantsList, selectedRestaurant} = useContext(RestaurantsContext)
    const {pageName, setServerResponse} = useContext(MainContext)

    const usrId = editableUserId;

    const isDeliveryAuthUser = Identity.isAdmin(authUser) && Identity.isDeliveryRestaurant(selectedRestaurant) && authUser?.restaurant_id === selectedRestaurant.id;

    const [userId, setUserId] = useState(usrId);

    const [profileFields, setProfileFields] = useState({
        ...profileSample
    })
    const [showPassword, setShowPassword] = useState(false);
    const [restaurants, setRestaurants] = useState([]);
    const [loading, setLoading] = useState(false);

    const [message, setMessage] = useState({
        open: false,
        type: "success",
        btnText: "Ok",
        title: "Title",
        text: "Some Text"
    });


    const _setState = (key, value) => {
        setProfileFields(prevState => ({
            ...prevState,
            [key]: value
        }))
    }

    const searchForUsers = () => {
        let sp = Array.isArray(usersList) && usersList.filter((p) => {

            return p.id === userId;
        });

        if (Array.isArray(usersList) && sp.length > 0) {
            return sp[0];
        }
        return null;
    }

    useEffect(() => {
        let _restaurants = [];

        restaurantsList && restaurantsList.length > 0 && restaurantsList.map((restaurant) => {

            _restaurants.push({value: restaurant.id, label: restaurant.restaurant_name})

        });
        setRestaurants(_restaurants);
    }, [restaurantsList])
    useEffect(() => {
        emptyForm();

        pageName !== pages.users.form && setState(changePageName(pages.users.form))


        if (isDeliveryAuthUser) {
            setProfileFields(prevState => ({
                ...prevState,
                restaurant_id: authUser.restaurant_id
            }))
        }
        restoreFormData();
    }, []);

    const restoreFormData = () => {
        const selectedUser = searchForUsers();

        if (!selectedUser) {
            return;
        }


        _setState('first_name', selectedUser.first_name);
        _setState('last_name', selectedUser.last_name);
        _setState('username', selectedUser.username);
        _setState('email', selectedUser.email);
        _setState('primary_phone', selectedUser.primary_phone);
        _setState('type', selectedUser.type);
        _setState('restaurant_id', selectedUser.restaurant_id);

        setShowPassword(false);
        setLoading(false);
        setMessage({
            open: false,
            type: "success",
            btnText: "Ok",
            title: "Title",
            text: "Some Text"
        });
    }
    const validateFields = () => {

        let errorMessage = false;

        const {
            first_name,
            last_name,
            username,
            email,
            type,
            primary_phone,
            password_confirmation,
            password,
            restaurant_id
        } = profileFields;


        if (first_name === "" || last_name === "" || username === "" || (!primary_phone && (type == 'driver' || type == 'user')) || (!userId && password === "") || type === "" || (restaurant_id === "" && type !== "senior")) {
            errorMessage = s.fill_required_fields;
        }else if (password_confirmation !== password) {
            errorMessage = s.password_not_match;
        }

        if (errorMessage) {
            setMessage({
                open: true,
                type: "error",
                btnText: s.ok,
                title: s.opps,
                text: s.fill_required_fields
            })
        }

        return !errorMessage;

    }

    const addUser = async () => {
        setLoading(true);
        if (!validateFields()) {
            setLoading(false);
            return;
        }


        let method = "create";

        let formJSON = {
            ...profileFields,
        };

        if (typeof userId == "string" && userId.length === 36) {
            formJSON.user_id = userId;
            method = 'update'
        }

        formJSON = jsonToDataForm(formJSON);

        submitUser(formJSON, method, setLoading, onSubmit)

    };


    const handleChange = name => event => {
        // let value = null;

        if (name === "restaurant_id") {
            _setState('restaurant_id', event.value);
        } else if (name === "first_name") {
            _setState('first_name', event.target.value);
        } else if (name === "last_name") {
            _setState('last_name', event.target.value);
        } else if (name === "username") {
            _setState('username', event.target.value);
        } else if (name === "email") {
            _setState('email', event.target.value);
        } else if (name === "primary_phone") {
            _setState('primary_phone', cleanPhoneNumber(event.target.value));
        } else if (name === "password") {
            _setState('password', event.target.value);
        } else if (name === "password_confirmation") {
            _setState('password_confirmation', event.target.value);
        } else if (name === "type") {
            _setState('type', event.target.value);
            if (event.target.value === "senior") {
                _setState('restaurant_id', "");
            }
        } else {
            // value = event.target.value;
        }

    };

    const emptyForm = () => {
        _setState('restaurant_id', '');
        _setState('first_name', '');
        _setState('last_name', '');
        _setState('username', '');
        _setState('password', '');
        _setState('password_confirmation', '');
        _setState('type', 'senior');
    }


    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const isUser = profileFields.type === 'user';


    return (

        <div className="animated slideInUpTiny animation-duration-3">
            <div className="row">
                <div className={"col-lg-12 col-md-12 col-sm-12 justify-content-center"}>
                    <form className="row" noValidate autoComplete="off">
                        <div className="row col-12">
                            <div className="col-md-6 col-lg-6">
                                <h4 className="font-weight-light"><IntlMessages
                                    id="app.users.first_name"/> <RequiredStar /></h4>
                                <Input
                                    id="first_name"
                                    className="marginBottom"
                                    value={profileFields.first_name}
                                    disabled={loading}
                                    fullWidth
                                    onChange={handleChange('first_name')}
                                />
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <h4 className="font-weight-light"><IntlMessages
                                    id="app.users.last_name"/> <RequiredStar /></h4>
                                <Input
                                    id="last_name"
                                    className="marginBottom"
                                    value={profileFields.last_name}
                                    disabled={loading}
                                    fullWidth
                                    onChange={handleChange('last_name')}
                                />
                            </div>
                        </div>
                        <div className="col-md-12 col-12">
                            <h4 className="font-weight-light"><IntlMessages
                                id="app.users.username"/> <RequiredStar /></h4>
                            <Input
                                id="username"
                                className="marginBottom"
                                value={profileFields.username}
                                disabled={loading}
                                fullWidth
                                onChange={handleChange('username')}
                            />
                        </div>
                        <div className="col-md-12 col-12">
                            <h4 className="font-weight-light"><IntlMessages
                                id="app.users.email"/></h4>
                            <Input
                                id="email"
                                className="marginBottom"
                                value={profileFields.email}
                                disabled={loading}
                                fullWidth
                                onChange={handleChange('email')}
                            />
                        </div>
                        <div className="col-md-12 col-12">
                            <h4 className="font-weight-light"><IntlMessages
                                id="app.users.phone"/>{profileFields.type == 'driver' || profileFields.type == 'user' ? <RequiredStar /> : ''}
                            </h4>
                            <Input
                                id="phone_number"
                                className="marginBottom"
                                value={profileFields.primary_phone || ''}
                                disabled={loading}
                                type={'phone'}
                                fullWidth
                                placeholder={'0_ _ _ _ _ _ _ _ _ _ '}
                                onChange={handleChange('primary_phone')}
                            />
                        </div>
                        <div className="col-md-12 col-12">
                            <FormControl className="mb-3" fullWidth>
                                <h4 className="font-weight-light"><IntlMessages
                                    id="app.users.password"/> <RequiredStar /></h4>
                                <Input
                                    id="password-1"
                                    type={showPassword ? 'text' : 'password'}
                                    value={profileFields.password}
                                    disabled={loading}
                                    onChange={handleChange('password')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                        <div className="col-md-12 col-12">
                            <FormControl className="mb-3" fullWidth>
                                <h4 className="font-weight-light"><IntlMessages
                                    id="app.users.confirm_password"/> <RequiredStar /></h4>
                                <Input
                                    id="password-2"
                                    type={showPassword ? 'text' : 'password'}
                                    value={profileFields.password_confirmation}
                                    disabled={loading}
                                    onChange={handleChange('password_confirmation')}

                                />
                            </FormControl>
                        </div>
                        <div className="col-md-12 col-12">
                            <FormControl component="fieldset" required>
                                <h4 className="font-weight-light"><IntlMessages
                                    id="app.users.type"/> <RequiredStar /></h4>
                                <RadioGroup
                                    aria-label="type"
                                    name="type"
                                    row
                                    className="marginBottom"
                                    value={profileFields.type}
                                    disabled={loading}
                                    onChange={handleChange('type')}
                                >
                                    {profileFields.type == 'user' &&
                                    <FormControlLabel value="user" control={<Radio color="primary"/>}
                                                      label={s.user}/>}
                                    <FormControlLabel value="admin" control={<Radio color="primary"/>}
                                                      label={s.admin}/>
                                    {Identity.isSenior(authUser) &&
                                    <FormControlLabel value="senior" control={<Radio color="primary"/>}
                                                      label={s.senior}/>}
                                    <FormControlLabel value="driver" control={<Radio color="primary"/>}
                                                      label={s.driver}/>
                                    <FormControlLabel value="data" control={<Radio color="primary"/>}
                                                      label={s.data}/>
                                    {Identity.isSenior(authUser) &&
                                    <FormControlLabel value="advertiser" control={<Radio color="primary"/>}
                                                      label={s.advertiser}/>}
                                    {/*<FormControlLabel value="disabled" disabled control={<Radio />} label="Disabled" />*/}
                                </RadioGroup>
                            </FormControl>
                        </div>
                        {Identity.isSenior(authUser) &&
                        <div className="col-md-12 col-12" hidden={profileFields.type === "senior"}>
                            <FormControl className="w-100 mb-2">
                                <h4 className="font-weight-light"><IntlMessages
                                    id="app.users.restaurant"/> <RequiredStar /></h4>

                                <Select
                                    value={restaurants.filter(_restaurant => {
                                        return _restaurant.value == profileFields.restaurant_id
                                    })[0]}
                                    options={restaurants}
                                    disabled={loading}
                                    onChange={handleChange('restaurant_id')}
                                    style={{textTransform: "capitalize"}}
                                />
                            </FormControl>
                        </div>}


                        <div className="jr-btn-group mb-n3 pull-right">
                            {!isUser &&
                            <Button disabled={loading} variant="contained" className="jr-btn bg-white text-black"
                                    onClick={() => {
                                        addUser()
                                    }}>{getText('buttons.submit')}</Button>}
                            <Button disabled={loading} variant="contained" className="jr-btn bg-danger text-white"
                                    onClick={() => {
                                        onSubmit && onSubmit();
                                    }}>{getText('buttons.cancel')}</Button>
                        </div>
                    </form>

                    {/*
                {authUser.type == "senior" && <CardBox cardStyle={{height: "auto"}} styleName={"col-lg-4"}
                                                       heading={<IntlMessages id="component.textFields.textfield"/>}>
                    <div className="col-md-12 col-12">
                        <h4> Name: {name}</h4>
                        <h4> Username: {username}</h4>
                        <h4> Type: {type}</h4>
                        <h4> Password: {password}</h4>
                        <h4> Restaurant: {restaurantId}</h4>
                    </div>
                </CardBox>}
*/}

                </div>
            </div>
            <SweetAlert show={message.open}
                        error={message.type === "error"}
                        info={message.type === "info"}
                        warning={message.type === "warning"}
                        success={message.type === "success"}
                        confirmBtnText={message.btnText}
                        title={message.title}
                        confirmBtnStyle={{margin: 0}}
                        onConfirm={() => {
                            message.hasOwnProperty("onConfirm") && message.onConfirm();

                            setMessage({
                                ...message,
                                open: false
                            });
                        }}>
                {message.text}
            </SweetAlert>
        </div>
    );


}


export default withRouter(Form);

