import React,{useImperativeHandle,forwardRef} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(2),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function LoadingButton({title='Click Here',loading,onClick,display='both'}) {
    const classes = useStyles();

    const [success, setSuccess] = React.useState(false);

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });


    const handleButtonClick = () => {
        if (!loading) {
            setSuccess(false);

            if(typeof onClick == "function"){
                onClick(()=>{
                    setSuccess(true);
                },()=>{
                    setSuccess(false);
                })
            }
        }
    };

    return (
        <Box className={classes.root}>
            {(display == 'both' || display == 'circle') && <Box className={classes.wrapper}>
                <Fab aria-label="save" color="primary" className={buttonClassname} onClick={handleButtonClick}>
                    {success ? <CheckIcon/> : <SaveIcon/>}
                </Fab>
                {loading && <CircularProgress size={68} className={classes.fabProgress}/>}
            </Box>}
            {(display == 'both' || display == 'button') && <Box className={classes.wrapper}>
                <Button
                    variant="contained"
                    color="primary"
                    className={buttonClassname}
                    disabled={loading}
                    onClick={handleButtonClick}>
                    {title}
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
            </Box>}
        </Box>
    );
}
