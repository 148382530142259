import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {OrdersContext} from "../context/OrdersContext";
import {Server} from "../constants/Server";
import {RestaurantsContext} from "../context/RestsurantsContext";
import NotificationsConfig, {channelsAndEvents} from '../constants/NotificationsConfig.js'
import FirebaseApp, {getToken, onMessageListener} from './firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import {listenToCustomChannel, pusher} from "./pusher";
import ReactAudioPlayer from "react-audio-player";

export const FirebaseContext = createContext();

const useAudio = url => {
    const [audio] = useState(new Audio(url));

    const [playing, setPlaying] = useState(false);

    const toggle = () => {
        if (!playing) {
            audio.loop = true;
            audio.currentTime = 0;
            audio.play();
            setPlaying(true);
            setTimeout(() => {
                audio.pause();
                setPlaying(false);
            }, 5600)
        } else {
            audio.pause();
            setPlaying(false);
        }
    };


    return [playing, toggle];
};
const FirebaseContextProvider = (props) => {


    const {fetchOrders} = useContext(OrdersContext);
    const {restaurantJoined} = useContext(RestaurantsContext);
    const [pageIsActive, setPageIsActive] = useState(true);
    const [notificationsList, setNotificationsList] = useState([]);
    const [firebaseInstalled, setFirebaseInstalled] = useState(false);
    const [playing, toggle] = useAudio("https://backend-menus.topdesign.pro/sounds/notifications.mp3");

    const sendTokenToServer = (token = null) => {
        if (!token) {
            return;
        }


        const $form = new FormData();
        $form.append("firebase_token", token);


        Server.updateToken($form)
            .then(res => {

            })
            .catch(error => {

            })
    }

    const playTone = () => {
        toggle();
    }

    const checkAndAddToNotificationsList = (notification) => {
        const newNotificationsList = notificationsList;
        let isExist = false;

        newNotificationsList.map(item => {
            if (JSON.stringify(item) !== JSON.stringify(notification)) {
                newNotificationsList.push(notification)
            } else {
                isExist = true;
            }
        })

        setNotificationsList(newNotificationsList);

        return isExist;
    }
    let firebaseApp = null;
    if(NotificationsConfig){
        firebaseApp = new FirebaseApp(fetchOrders, sendTokenToServer)

        firebaseApp.requestPermissions().then(payload => {
            const messaging = firebaseApp.initializingFirebaseApp();

            firebaseApp.updateAuthUserToken(messaging).then(updateAuth => {
                setFirebaseInstalled(true)
            });
            firebaseApp.onMessageListener(messaging).then(payload => {
                fetchOrders()
                playTone();
                !checkAndAddToNotificationsList(payload.notification) && firebaseApp.pushNotification(payload)
            })

        });
    }



    useEffect(() => {
        if (firebaseApp !== null) {
            listenToCustomChannel(channelsAndEvents.channels.orders, channelsAndEvents.events.new, payload => {
                if (!pageIsActive) {
                    fetchOrders()
                    playTone();
                    !checkAndAddToNotificationsList(payload.notification) && firebaseApp.pushNotification(payload)
                }
            })

            listenToCustomChannel(channelsAndEvents.channels.orders, channelsAndEvents.events.update, payload => {
                fetchOrders();
            })

            pusher();

            window.addEventListener('visibilitychange', () => {
                if (document.hidden)
                    setPageIsActive(false)
                else
                    setPageIsActive(true)
            });
        }


    }, [])


    return (
        <FirebaseContext.Provider value={{firebaseInstalled}}>
            {props.children}
            {/*<button onClick={playTone}>Press</button>*/}
            <NotificationContainer/>

        </FirebaseContext.Provider>
    )
}

export default FirebaseContextProvider;
