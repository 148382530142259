import React, {useState} from 'react';

const RequiredStar = () => {
    return (
        <span style={{color:'#850000'}}> *</span>
    )
}

export default RequiredStar;

const styles = () => {
    return {
        container: {
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            color:'#850000'
        }
    }
}
