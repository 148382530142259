import {createTheme} from "@material-ui/core/styles";
import indigoTheme from "../containers/themes/indigoTheme";
import cyanTheme from "../containers/themes/cyanTheme";
import amberTheme from "../containers/themes/amberTheme";
import orangeTheme from "../containers/themes/orangeTheme";
import pinkTheme from "../containers/themes/pinkTheme";
import blueTheme from "../containers/themes/blueTheme";
import purpleTheme from "../containers/themes/purpleTheme";
import greenTheme from "../containers/themes/greenTheme";

export const INDIGO = "indigo";
export const CYAN = "cyan";
export const AMBER = "amber";
export const DEEP_ORANGE = "deep-orange";
export const PINK = "pink";
export const BLUE = "blue";
export const DEEP_PURPLE = "deep-purple";
export const GREEN = "green";

export const DARK_INDIGO = "dark-indigo";
export const DARK_CYAN = "dark-cyan";
export const DARK_AMBER = "dark-amber";
export const DARK_DEEP_ORANGE = "dark-deep-orange";
export const DARK_PINK = "dark-pink";
export const DARK_BLUE = "dark-blue";
export const DARK_DEEP_PURPLE = "dark-deep-purple";
export const DARK_GREEN = "dark-green";
export const DARK_THEME_COLOR = "dark-theme";

export const getColorTheme = (themeColor, applyTheme) => {
    switch (themeColor) {
        case INDIGO: {
            applyTheme = createTheme(indigoTheme);
            break;
        }
        case CYAN: {
            applyTheme = createTheme(cyanTheme);
            break;
        }
        case AMBER: {
            applyTheme = createTheme(amberTheme);
            break;
        }
        case DEEP_ORANGE: {
            applyTheme = createTheme(orangeTheme);
            break;
        }
        case PINK: {
            applyTheme = createTheme(pinkTheme);
            break;
        }
        case BLUE: {
            applyTheme = createTheme(blueTheme);
            break;
        }
        case DEEP_PURPLE: {
            applyTheme = createTheme(purpleTheme);
            break;
        }
        case GREEN: {
            applyTheme = createTheme(greenTheme);
            break;
        }
        case DARK_INDIGO: {
            applyTheme = createTheme({...indigoTheme, direction: 'rtl'});
            break;
        }
        case DARK_CYAN: {
            applyTheme = createTheme(cyanTheme);
            break;
        }
        case DARK_AMBER: {
            applyTheme = createTheme(amberTheme);
            break;
        }
        case DARK_DEEP_ORANGE: {
            applyTheme = createTheme(orangeTheme);
            break;
        }
        case DARK_PINK: {
            applyTheme = createTheme(pinkTheme);
            break;
        }
        case DARK_BLUE: {
            applyTheme = createTheme(blueTheme);
            break;
        }
        case DARK_DEEP_PURPLE: {
            applyTheme = createTheme(purpleTheme);
            break;
        }
        case DARK_GREEN: {
            applyTheme = createTheme(greenTheme);
            break;
        }
        default :
            createTheme(indigoTheme);
    }
    return applyTheme;
};
