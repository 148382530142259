import React, {useState} from 'react';
import ProductGridItem from "../../../../../components/eCommerce/Products/ProductGridItem";
import {show_sweet_message} from "../../../../../actions/Restaurant";

const GridView = ({history,lang,deleteRecord,locale,setState,localProductList,selectedRestaurant}) => {
    return (
        <>
            {localProductList.map((product, index) => (
                <ProductGridItem key={product.id} product={product} history={history}
                                 selectedRestaurant={selectedRestaurant} currentLanguage={locale.locale}
                                 onPressDelete={(id) => {
                                     setState(show_sweet_message({
                                         type: "warning",
                                         title: lang.confirmDelete,
                                         confirmBtnText: lang.ok,
                                         onConfirm: () => {
                                             deleteRecord(id)
                                         },
                                     }))
                                 }}
                />
            ))}

        </>
    )
}

export default GridView;

