import React, {Component, Fragment, useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {UsersContext} from "../../../../../context/UsersContext";
import {SweetAlertContext} from "../../../../../context/SweetAlertContext";
import {OrdersContext} from "../../../../../context/OrdersContext";
import ProductGridItem from "../../../../../components/eCommerce/Products/ProductGridItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import {Card, CardBody, CardHeader, CardText} from "reactstrap";
import {timeConverterUTC} from "../../../../../constants/DatesManager";
import {useIntl} from "react-intl";
import orderSample from "./orderSample";
import {Identity} from "../../../../../constants/Identity";
import {RestaurantsContext} from "../../../../../context/RestsurantsContext";
import {show_sweet_message} from "../../../../../actions/Restaurant";
import {CollectContext} from "../../../../../context/CollectContext";
import LoadingButton from "../../../../../localComponents/LoadingButton";
import {SettingsContext} from "../../../../../context/SettingsContext";
import Tracking from "./Tracking";

const OrderView = ({
                       selectedOrder = null, setSelectedOrder = () => {
    }
                   }) => {


    const i = useIntl();
    const intl = (id) => {
        return i.formatMessage({id})
    };

    const view = selectedOrder ? selectedOrder : orderSample;

    const {
        id,
        total,
        order_number,
        address_id,
        user_id,
        created_at,
        restaurant_id,
        last_status,
        address,
        user,
        estimated_delivery_time,
        order_details,
        order_histories,
        order_driver
    } = view;

    const {title, location, lat, lng} = address || {title:'', location:'', lat:'', lng:''};

    const {full_name, restaurant, primary_phone_cc, primary_phone} = user;
    const {currency, languages, color, logo_path} = restaurant


    const [open, setOpen] = useState(false);
    const [dataView, setDataView] = useState(false);
    const [nextStatus, setNextStatus] = useState(null);
    const [isWorking, setIsWorking] = useState(false);
    const [estimatedDeliveryTime, setEstimatedDeliveryTime] = useState(null);
    const [selectedDriverId, setSelectedDriverId] = useState(null);
    const {setState} = useContext(CollectContext);
    const {usersList} = useContext(UsersContext);
    const {openAlert} = useContext(SweetAlertContext);
    const {changeOrderStatus} = useContext(OrdersContext);
    const {selectedRestaurant} = useContext(RestaurantsContext);
    const {locale} = useContext(SettingsContext);


    const handleRequestClose = () => {
        setIsWorking(false);
        setSelectedOrder(null);
    };


    const currentNextStatus = () => {
        const currentStatusStyle = last_status.includes("sent") ?
            "text-white bg-primary" :
            last_status.includes("pending") ?
                "bg-amber" :
                last_status.includes("delivered") ?
                    "text-white bg-success" :
                    last_status.includes("canceled") ?
                        "text-white bg-danger" :
                        "text-white bg-grey";

        const nextStatus = last_status.includes("sent") ?
            "pending" :
            last_status.includes("pending") ?
                "shipping" :
                last_status.includes("shipping") ?
                    "delivered" :
                    "canceled";


        return {
            currentStatusStyle,
            nextStatus
        }

    }

    const updateSelectedOrderStatus = () => {
        setIsWorking(true);
        /*
                if (nextStatus === "shipping" && !selectedDriverId) {
                    openAlert({
                        showCancel: false,
                        title: intl('app.orders.select_driver')
                    });

                    return;
                }
                if (nextStatus === "pending" && !estimatedDeliveryTime) {
                    openAlert({
                        showCancel: false,
                        title: intl('app.orders.choose time')
                    });

                    return;
                }
        */

        if (!nextStatus) {
            setState(show_sweet_message({
                open: true,
                type: "error",
                confirmBtnText: "Ok",
                title: intl('statues.failed'),
                body: intl('app.orders.messages.status_error')
            }))
            setIsWorking(false);
            return;
        }
        if (nextStatus == 'pending' && !estimatedDeliveryTime) {
            setState(show_sweet_message({
                open: true,
                type: "error",
                confirmBtnText: "Ok",
                title: intl('statues.failed'),
                body: intl('app.orders.messages.delivery_time_error')
            }))
            setIsWorking(false);
            return;
        }
        selectedOrder && changeOrderStatus(selectedOrder.id, nextStatus, estimatedDeliveryTime, selectedDriverId, handleRequestClose, e => {
            setState(show_sweet_message({
                open: true,
                type: "error",
                confirmBtnText: "Ok",
                title: e.response.data.display.title,
                body: e.response.data.display.body
            }))
        })

    }

    useEffect(() => {
        if (selectedOrder) {
            setDataView(selectedOrder);
            setOpen(true);
        } else {
            setOpen(false);
            setDataView(null);
            setEstimatedDeliveryTime(null);
            setSelectedDriverId(null);
            setNextStatus(null);
        }
    }, [selectedOrder])


    const orderProcess = {
        sent: 0,
        pending: 1,
        shipping: 2,
        delivered: 3,
        canceled: 4
    }

    const SingleInfo = ({label, value}) => {
        return (
            <h4><small>{label}:</small> {`${value}`} </h4>
        )
    }

    const textClass = 'text-left';
    return (
        <Fragment>
            <Dialog open={open} fullScreen TransitionComponent={Slide} onClose={handleRequestClose}>
                <AppBar className="position-relative">
                    <Toolbar>
                        <Typography variant="title" color="inherit" style={{
                            flex: 1,
                        }}>
                            {`${intl('app.orders.prefix')}: ${view.order_number}`}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent className={'row'}>
                    {/*<DialogContentText>*/}
                    <div className="col-lg-3 col-sm-6 col-12 order-lg-3">
                        <div className="col-12">
                            <Card className={`shadow border-0 ${textClass}`}>
                                <CardHeader
                                    className="bg-primary text-white">{intl('app.orders.general_details')}</CardHeader>
                                <CardBody className={'card-body-fixed-height'}>
                                    {/*<CardSubtitle>Sub-heading text</CardSubtitle>*/}
                                    <CardText>
                                        <SingleInfo label={intl('app.orders.total')} value={total}/>
                                        {/*<SingleInfo label={intl('app.orders.comment')} value={comment}/>*/}
                                        <SingleInfo label={intl('app.orders.current_status')} value={last_status}/>
                                        {order_driver && <SingleInfo label={intl('app.orders.driver.name')}
                                                                     value={order_driver?.user?.full_name}/>}
                                        {estimated_delivery_time &&
                                        <SingleInfo label={intl('app.orders.estimated_delivery')}
                                                    value={estimated_delivery_time}/>}

                                        <SingleInfo label={intl('app.orders.created_at')}
                                                    value={timeConverterUTC(created_at, true, true)}/>

                                    </CardText>
                                    <CardText>
                                        {last_status !== "canceled" && last_status !== "delivered" &&
                                        <FormControl className="w-100 row">
                                            <InputLabel
                                                htmlFor="age-native-simple">{intl('app.orders.change_status')}</InputLabel>
                                            <Select
                                                native
                                                variant={'outlined'}
                                                value={nextStatus}
                                                onChange={(v) => {
                                                    setSelectedDriverId(null)
                                                    setEstimatedDeliveryTime(null)
                                                    setNextStatus(v.target.value);
                                                }}
                                                input={<Input id="age-native-simple"/>}>
                                                <option value={null}/>
                                                {orderProcess[last_status] < 1 &&
                                                <option value={"pending"}>{intl('app.orders.pending')}</option>}
                                                {orderProcess[last_status] < 2 &&
                                                <option value={"shipping"}>{intl('app.orders.shipping')}</option>}
                                                {orderProcess[last_status] < 3 &&
                                                <option value={"delivered"}>{intl('app.orders.delivered')}</option>}
                                                <option value={"canceled"}>{intl('app.orders.canceled')}</option>
                                            </Select>
                                        </FormControl>}
                                        <br/>
                                        {nextStatus === "shipping" && <FormControl className="w-100 row">
                                            <InputLabel
                                                htmlFor="age-native-simple">{intl('app.orders.change_driver')}</InputLabel>
                                            <Select
                                                native
                                                variant={'outlined'}
                                                value={selectedDriverId}
                                                onChange={(v) => {
                                                    setSelectedDriverId(v.target.value);
                                                }}
                                                input={<Input id="age-native-simple"/>}
                                            >
                                                <option value={null}/>
                                                {
                                                    Array.isArray(usersList) && usersList.map((user, index) => {
                                                        if (user.user_type === 'driver' && user.restaurant_id == selectedRestaurant.id && user.is_active == 1) {
                                                            return (<option key={user.id}
                                                                            value={user.id}>{user.full_name}</option>)
                                                        }
                                                    })
                                                }
                                            </Select>
                                        </FormControl>}
                                        <br/>

                                        {nextStatus === "pending" && <FormControl className="w-100 row">
                                            <InputLabel
                                                htmlFor="age-native-simple">{intl('app.orders.estimated_delivery')}</InputLabel>

                                            {/*<DateTimePicker
                                            value={estimatedDate}
                                            disablePast
                                            onChange={setEstimatedDate}
                                            label="With Today Button"
                                            showTodayButton
                                        />*/}

                                            <Input title={intl('app.orders.estimated_delivery')}
                                                   value={estimatedDeliveryTime}
                                                   type={'number'}
                                                   inputProps={{
                                                       max: 120,
                                                       min: 1
                                                   }
                                                   }
                                                   endAdornment={<span>{"MM"}</span>}
                                                   onChange={event => {
                                                       setEstimatedDeliveryTime(event.target.value)
                                                   }}/>
                                        </FormControl>}
                                    </CardText>
                                </CardBody>
                                {/*<CardFooter>2 days ago</CardFooter>*/}
                            </Card>
                        </div>
                        <div className="col-12">
                            <Card className={`shadow border-0 ${textClass}`}>
                                <CardHeader
                                    className="bg-primary text-white">{intl('app.orders.user.details')}</CardHeader>
                                <CardBody className={'card-body-fixed-height'}>
                                    <SingleInfo label={intl('app.orders.user.address')} value={location}/>
                                    <SingleInfo label={intl('app.orders.user.name')} value={full_name}/>
                                    <SingleInfo label={intl('app.orders.user.phone')}
                                                value={`+${primary_phone_cc} ${primary_phone}`}/>

                                </CardBody>
                                {/*<CardFooter>2 days ago</CardFooter>*/}
                            </Card>
                        </div>
                    </div>

                    <div className="col-lg-9 col-sm-6 col-12 order-lg-9">
                        <Card className={`shadow border-0 ${textClass}`}>
                            <CardHeader
                                className="bg-primary text-white">{intl('sidebar.eCommerce.productsList')}</CardHeader>
                            <CardBody className={'card-body-fixed-height'}>
                                <div className={'row'}>
                                    {
                                        view.order_details.map(({
                                                                    product_clone,
                                                                    extra_choices_clone,
                                                                    price_type_clone,
                                                                    comment,
                                                                    quantity
                                                                }, index) => {
                                            return (
                                                <ProductGridItem itemCssClass={'col-xl-3 col-md-4 col-sm-6 col-12'}
                                                                 key={index}
                                                                 orderView
                                                                 comment={comment}
                                                                 quantity={quantity}
                                                                 product={product_clone}
                                                                 currentLanguage={locale.locale}
                                                                 extra_choices={extra_choices_clone}
                                                                 _price={price_type_clone} history={null}
                                                                 setDeleteAlertActive={null}
                                                                 setProductWillDelete={null}/>
                                            )
                                        })
                                    }
                                </div>
                            </CardBody>
                            {/*<CardFooter>2 days ago</CardFooter>*/}
                        </Card>
                    </div>


                    {/*<Tracking />*/}

                    {/*</DialogContentText>*/}
                </DialogContent>
                <DialogActions>
                    <LoadingButton display={'button'} loading={isWorking} onClick={updateSelectedOrderStatus}
                                   title={intl('buttons.submit')}/>
                    {/*<Button disabled={!nextStatus} className={"text-white bg-primary"}*/}
                    {/*        onClick={updateSelectedOrderStatus}>*/}
                    {/*    {intl('buttons.submit')}*/}
                    {/*</Button>*/}
                    <Button className={"text-white bg-danger"} onClick={handleRequestClose}>
                        {intl('buttons.close')}
                    </Button>

                </DialogActions>
            </Dialog>
        </Fragment>
    );

}

export default OrderView;

