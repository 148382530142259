import React, {createContext, Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';

import 'constants/Ext';
import {checkParams, logger} from "../../constants/Helper";
// import AddComponent from '../../form';

let counter = 0;

const DataTablesContext = createContext();


const splitJsonKey = (column, key) => {
    const splitDots = key.split(".");

    return checkParams(column, splitDots);
}


const DataTableHead = ({onRequestSort, onSelectAllClick = ()=>{}, order, orderBy, numSelected, rowCount, columns}) => {


    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };


    return (
        <DataTablesContext.Consumer>
            {
                context => (
                    <Fragment>
                        <TableHead style={{padding: 0}}>
                            <TableRow component={'td'} style={{padding: 30}}>
                                {context.clickable && <TableCell padding="checkbox">
                                    <Checkbox color="primary"
                                              indeterminate={numSelected > 0 && numSelected < rowCount}
                                              checked={numSelected === rowCount}
                                              onChange={onSelectAllClick}
                                    />
                                </TableCell>}
                                {columns.map((column, index) => {
                                    return (
                                        <TableCell
                                            key={column.id}
                                            align={column.numeric}
                                            padding={column.disablePadding ? 'none' : 'default'}
                                        >
                                            <Tooltip
                                                title="Sort"
                                                placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                                enterDelay={300}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === column.id}
                                                    direction={'asc'}
                                                    onClick={createSortHandler(column.id)}
                                                >
                                                    {column.label}
                                                    {/*{context.clickable?'d':'s'}*/}
                                                </TableSortLabel>
                                            </Tooltip>
                                        </TableCell>
                                    );
                                }, this)}
                            </TableRow>
                        </TableHead>

                    </Fragment>
                )
            }
        </DataTablesContext.Consumer>
    );
}


let DataTableToolbar = props => {
    const {numSelected} = props;

    return (
        <DataTablesContext.Consumer>
            {
                context => (
                    <Fragment>
                        <Toolbar className="table-header">
                            <div className="title">
                                {numSelected > 0 ? (
                                    <Typography variant="subheading">{numSelected} selected</Typography>
                                ) : (
                                    <Typography variant="title">{context.tableLabel}</Typography>
                                )}
                            </div>
                            <div className="spacer"/>
                            <div className="actions">
                                {numSelected > 0 && (
                                    <Tooltip title="Delete">
                                        <IconButton aria-label="Delete">
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                        </Toolbar>
                    </Fragment>
                )
            }
        </DataTablesContext.Consumer>
    );
};

DataTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    columns: PropTypes.array.isRequired
};


const DataTables = ({columns, columnsData, defaultSortBy, selectedItem, defaultOrder, clickable, isActiveKey, tableLabel}) => {
    const [order, setOrder] = useState(defaultOrder);
    const [orderBy, setOrderBy] = useState(defaultSortBy);
    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const createData = (columns, data) => {

        var counter = 1;
        var _data = [];
        data && data.length > 0 && data.map((item, index) => {

            counter += 1;


            var jsonObject = {};
            jsonObject['id'] = item.id;
            columns.map((_item) => {
                if (_item[isActiveKey]) {
                    return;
                }
                if (_item.renderedComponent) {
                    jsonObject[_item.id] = _item.renderedComponent(item, index);
                } else {
                    jsonObject[_item.id] = splitJsonKey(item, _item.id)
                }
            });


            _data.push(jsonObject);
        })

        return _data;
    }

    useEffect(() => {
        setData(columnsData);
    }, [columnsData]);


    const handleRequestSort = (event, property) => {
        const _orderBy = property;
        let _order = 'desc';

        if (orderBy === property && order === 'desc') {
            _order = 'asc';
        }

        const _data =
            order === 'desc'
                ? data.sort((a, b) => (b[_orderBy] < a[_orderBy] ? -1 : 1))
                : data.sort((a, b) => (a[_orderBy] < b[_orderBy] ? -1 : 1));

        setData(_data);
        setOrder(_order);
        setOrderBy(_orderBy);

    };
    const handleSelectAllClick = (event, checked) => {
        if (checked) {
            setSelected(data.map(n => n.id));
            return;
        }
        setSelected([]);
    };
    const handleKeyDown = (event, id) => {
        if (keycode(event) === 'space') {
            handleClick(event, id);
        }
    };
    const handleClick = (event, id) => {

        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const handleChangePage = (event, page) => {
        setPage(page);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value);
    };
    const _isSelected = id => selected.indexOf(id) !== -1;


    const renderedData = createData(columns, data);

    return (
        <DataTablesContext.Provider value={{
            clickable,
            tableLabel
        }}>
            <div className="table-responsive-material">
                <Table className="">
                    <DataTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={clickable ? handleSelectAllClick : null}
                        onRequestSort={handleRequestSort}
                        rowCount={Array.isArray(data) ? data.length : 0}
                        columns={columns}
                    />
                    {/*<h2>{JSON.stringify(renderedData)}</h2>*/}
                    <TableBody>
                        {renderedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                            const isSelected = _isSelected(n.id);
                            return (
                                <TableRow
                                    style={{backgroundColor: n.blocked == 1 ? "#ffe6c8" : (selectedItem && selectedItem.id == n.id) ? '#e8e8e8' : "#fff"}}
                                    hover
                                    onClick={clickable ? event => handleClick(event, n.id) : null}
                                    onKeyDown={clickable ? event => handleKeyDown(event, n.id) : null}
                                    role={clickable ? "checkbox" : null}
                                    aria-checked={clickable ? isSelected : null}
                                    tabIndex={-1}
                                    key={n.id}
                                    selected={clickable ? isSelected : false}
                                >
                                    {clickable && <TableCell padding="checkbox">
                                        <Checkbox color="primary" checked={isSelected}/>
                                    </TableCell>}
                                    {columns.map((column, index) => {
                                        return (
                                            <TableCell
                                                style={{backgroundColor: n.color}}
                                                padding={clickable && index !== 0 ? "none" : "default"}>{n[column.id]}</TableCell>
                                        );
                                    }, this)}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                count={Array.isArray(data) ? data.length : 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
        </DataTablesContext.Provider>
    );
}


DataTables.propTypes = {
    match: PropTypes.string,
    columns: PropTypes.array,
    columnsData: PropTypes.array,
    defaultSortBy: PropTypes.string,
    defaultOrder: PropTypes.string,
    tableLabel: PropTypes.string,
    clickable: PropTypes.bool,
};
DataTables.defaultProps = {
    columns: [],
    columnsData: [],
    defaultSortBy: null,
    defaultOrder: "asc",
    tableLabel: "Table",
    clickable: true,
}
DataTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    columns: PropTypes.array.isRequired,
    clickable: PropTypes.bool

};
DataTableHead.defaultProps = {
    columns: [],
    clickable: true
}
export default DataTables;
