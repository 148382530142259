import React from 'react';
import ReactDOM from 'react-dom';



const rootEl = document.getElementById('app-site');


// register the _firebase service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
      .register('/firebase-messaging-sw.js', {
        scope: '/firebase-cloud-messaging-push-scope',
      })
      .then(registration => {
        console.log('Registration successful, scope is:', registration.scope)
      })
      .catch(err => {
        console.error('Service worker registration failed, error:', err)
      })
}
// Create a reusable render method that we can call more than once
let render = () => {
  // Dynamically import our main App component, and render it
  const MainApp = require('./MainApp').default;




  ReactDOM.render(
      <MainApp/>,
      rootEl
  );
};

if (module.hot) {
  module.hot.accept('./MainApp', () => {
    const MainApp = require('./MainApp').default;
    render(
        <MainApp/>,
        rootEl
    );
  });
}

render();
