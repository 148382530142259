import React, {useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {Link} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
// import {getAuth, signInWithPhoneNumber, RecaptchaVerifier, signOut} from "_firebase/auth";
import {cleanPhoneNumber, Helper, jsonToDataForm, logger} from "../constants/Helper";
import SweetAlert from "react-bootstrap-sweetalert";
import {Server} from "../constants/Server";
import {toggleLoading} from "../actions";
import {CollectContext} from "../context/CollectContext";
import {MainContext} from "../context/MainContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Normal} from "../constants/Normal";
import {RestaurantsContext} from "../context/RestsurantsContext";

const ForgotPassword = ({history}) => {

    const {setState} = useContext(CollectContext);
    const {alertMessage, loading, showMessage, setServerResponse} = useContext(MainContext);
    const {restaurantJoined} = useContext(RestaurantsContext);

    const [phoneNumber, setPhoneNumber] = useState(null);
    const [firebaseUser, setFirebaseUser] = useState(null);
    const [confirmation, setConfirmation] = useState(null);

    const [newPassword, setNewPassword] = useState(null);
    const [confirmNewPassword, setConfirmNewPassword] = useState(null);


    // const auth = getAuth();

    const onClick = () => {
        let phone = phoneNumber;
        if (phone.includes('+964')) {
            phone = phone.replace('+964', '');
        }
        if (phone.includes('964')) {
            phone = phone.replace('964', '');
        }
        phone = cleanPhoneNumber(phone)

        setPhoneNumber(phone);


        // signInWithPhoneNumber(auth, '+964' + phone,window.recaptchaVerifier )
        //     .then((confirmationResult) => {
        //         setConfirmation(confirmationResult)
        //         // SMS sent. Prompt user to type the code from the message, then sign the
        //         // user in with confirmationResult.confirm(code).
        //         window.confirmationResult = confirmationResult;
        //         // ...
        //     }).catch((error) => {
        //     logger("error", error)
        //     // Error; SMS not sent
        //     // ...
        // });
    }


    const msg = {
        password_not_match: Normal.getText('messages.password_not_match'),
        invalid_code: Normal.getText('app.users.enter_code_error'),
        empty_code: Normal.getText('app.users.enter_code_empty'),
    }

    const onClickSubmit = () => {

        if (newPassword !== confirmNewPassword) {
            alert(msg.password_not_match)
            return;
        }
        setState(toggleLoading(false));
        const data = {
            phone_number: phoneNumber,
            password: newPassword,
            password_confirmation: confirmNewPassword,
        };

        // Server.changePassword(jsonToDataForm(data)).then((response) => {
        //     history.push('/signin');
        //
        //     setFirebaseUser(null);
        //     signOut(getAuth());
        //     setState(toggleLoading(false));
        //     Helper.consoleServerResults(response)
        // }).catch(e => {
        //     alert(msg.invalid_code)
        //     setState(toggleLoading(false));
        //     Helper.consoleServerResults(e.response)
        // });
    };

    const onConfirm = (code) => {
        confirmation.confirm(code).then((result) => {
            // User signed in successfully.
            setConfirmation(null);
            const user = result.user;
            // ...
        }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            // ...
        });
        // [END auth_phone_verify_code]
    }

    useEffect(() => {
        // getAuth().currentUser && signOut(auth);
/*        // window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
        //     'size': 'invisible',
        //     'callback': (response) => {
        //         // reCAPTCHA solved, allow signInWithPhoneNumber.
        //         // onSignInSubmit();
        //     }
        // }, auth);*/

        // getAuth() && getAuth().onAuthStateChanged(setFirebaseUser);
    }, [])

    return (
        <div
            className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">

            <div className="app-login-main-content">
                <div className="app-logo-content d-flex align-items-center justify-content-center"
                     style={{backgroundColor: restaurantJoined ? restaurantJoined.color : "#6b6d77"}}>
                    <Link className="logo-lg" to="/"
                          title={restaurantJoined ? restaurantJoined.restaurant_name : "Top Design"}>
                        <img
                            width={"100%"}
                            src={restaurantJoined ? restaurantJoined.logo_path : require("../assets/images/logo.png")}
                            alt={restaurantJoined ? restaurantJoined.restaurant_name : "Top Design"}
                            title={restaurantJoined ? restaurantJoined.restaurant_name : "Top Design"}
                        />
                    </Link>
                </div>

                <SweetAlert show={confirmation != null}
                            input
                            required
                            inputType="password"
                            disabled={loading}
                    // defaultValue={codeInput}
                            title={<IntlMessages id="app.users.enter_code"/>}
                            validationMsg={msg.empty_code}
                            onConfirm={onConfirm}
                />


                <div className="app-login-content">
                    <div className="app-login-header mb-4">
                        <h1>Welcome to D2D</h1>
                    </div>

                    <div className="app-login-form">
                        {!firebaseUser && <form>
                            <TextField
                                type="tel"
                                id="phoneNumber"
                                required
                                value={phoneNumber}
                                disabled={loading}
                                onChange={(e) => {
                                    setPhoneNumber(e.target.value)
                                }}
                                label={<IntlMessages id="appModule.phone"/>}
                                fullWidth
                                defaultValue=""
                                margin="normal"
                                className="mt-0 mb-4"
                            />
                            <div id={'recaptcha-container'}/>

                            <Button variant="contained" disabled={loading} onClick={onClick} color="primary"
                                    className="text-white">
                                <IntlMessages id="appModule.resetPassword"/>
                            </Button>

                            <Link to="/signIn" title="Reset Password">
                                <IntlMessages id="app.backToSignIn"/>
                            </Link>
                        </form>}


                        {firebaseUser && <form>
                            <TextField
                                type="password"
                                id="newPassword"
                                required
                                value={newPassword}
                                disabled={loading}
                                onChange={(e) => {
                                    setNewPassword(e.target.value)
                                }}
                                label={<IntlMessages id="app.users.password"/>}
                                fullWidth
                                defaultValue=""
                                margin="normal"
                                className="mt-0 mb-4"
                            />
                            <TextField
                                type="password"
                                id="confirmNewPassword"
                                required
                                value={confirmNewPassword}
                                disabled={loading}
                                onChange={(e) => {
                                    setConfirmNewPassword(e.target.value)
                                }}
                                label={<IntlMessages id="app.users.confirm_password"/>}
                                fullWidth
                                defaultValue=""
                                margin="normal"
                                className="mt-0 mb-4"
                            />
                            <div id={'recaptcha-container'}/>

                            <Button variant="contained" disabled={loading} onClick={onClickSubmit} color="primary"
                                    className="text-white">
                                <IntlMessages id="buttons.submit"/>
                            </Button>
                        </form>}
                    </div>
                </div>
            </div>
            {
                loading &&
                <div className="loader-view">
                    <CircularProgress/>
                </div>
            }
        </div>
    );
};

export default ForgotPassword;

