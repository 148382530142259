import React, {useState, createContext, useEffect, useContext} from 'react';
import {AuthContext} from "./AuthContext";
import {compareTwoObjects, Helper, logger} from "../constants/Helper";
import {MainContext} from "./MainContext";
import {RestaurantsContext} from "./RestsurantsContext";
import {Server} from "../constants/Server";
import {pages} from "../constants/Variables";
import {Normal} from "../constants/Normal";

export const ProductsContext = createContext();
const ProductsContextProvider = (props) => {

        const productsListState = Normal.getFromStorage("products_list", true, true);
        const {selectedRestaurant} = useContext(RestaurantsContext);
        const {authUser} = useContext(AuthContext);
        const {pageName} = useContext(MainContext);

        const [productsList, setProductsList] = useState([]);
        const [productsListLoading, setProductsListLoading] = useState(false);

        const fetchProductsServer = () => {
            setProductsListLoading(true)
            Server.getAllProducts().then(response => {
                if(!compareTwoObjects(response.data.data,productsList)){
                    setProductsList(response.data.data);
                }

                setProductsListLoading(false)
                Helper.consoleServerResults(response)
            }).catch(e => {
                setProductsListLoading(false)
                Helper.consoleServerResults(e.response)
            })
        }

        useEffect(() => {
            productsListState && setProductsList(productsListState)
        }, []);

        useEffect(() => {
            selectedRestaurant && authUser && fetchProductsServer()
        }, [selectedRestaurant]);

        useEffect(() => {
            const requestRefresh = pageName === pages.products.view || pageName === pages.products.form || pageName === pages.products.export;
            requestRefresh && selectedRestaurant && authUser && fetchProductsServer()


        }, [pageName]);


        return (
            <ProductsContext.Provider value={{productsList, setProductsList, fetchProductsServer, productsListLoading}}>
                {props.children}
            </ProductsContext.Provider>
        );
    }


export default ProductsContextProvider;
