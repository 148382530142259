import React from "react";
import {isValidArray} from "./Functions";
import {Identity} from "./Identity";

export const usersRules = (authUser = null, selectedRestaurant = null) => {
    let rules = {
        restaurants: false,
        ads: false,
        users: false,
        categories: false,
        products: false,
        offers: false,
        orders: false,
        settings: false,
        notifications: false,
    }

    if (!authUser) {
        return rules;
    }

    const has_posters = selectedRestaurant && selectedRestaurant.has_posters == 1;
    const standard = Identity.isStandardRestaurant(selectedRestaurant);
    const delivery = Identity.isDeliveryRestaurant(selectedRestaurant);

    if (Identity.isSenior(authUser)) {
        rules.restaurants = {
            add: true, view: true
        }
        rules.users = {
            add: true, view: true
        }
        rules.categories = {
            add: true, view: true
        }
        rules.products = {
            add: true, view: true
        }
        rules.offers = {
            add: true, view: true
        }
        rules.ads = has_posters ? {
            add: true, view: true
        } : false
        rules.settings = {
            add: true, view: true
        }
        rules.importExport = {
            import: true, export: true
        }
        if (!standard) {
            rules.orders = {
                edit: true, view: true
            }
            rules.notifications = {
                edit: true, view: true
            }
        }

    } else if (Identity.isAdmin(authUser) && standard) {
        rules.restaurants = false
        rules.users = false
        rules.categories = {
            add: true, view: true
        }
        rules.products = {
            add: true, view: true
        }
        rules.ads = has_posters ? {
            add: true, view: true
        } : false
        rules.settings = false;
        rules.orders = false;
    } else if (Identity.isAdmin(authUser) && delivery) {
        rules.restaurants = false
        rules.users = {
            add: true, view: true
        }
        rules.categories = {
            add: true, view: true
        }
        rules.products = {
            add: true, view: true
        }
        rules.ads = has_posters ? {
            add: true, view: true
        } : false
        rules.settings = false;
        rules.orders = {
            edit: true, view: true
        };
        rules.notifications = {
            edit: true, view: true
        }
    } else if (Identity.isReader(authUser)) {
        rules.restaurants = false
        rules.users = false
        rules.categories = {
            add: false, view: true
        }
        rules.products = {
            add: false, view: true
        }
        rules.ads = false;
        rules.settings = false;
        rules.orders = false;
        rules.notifications = {
            edit: false, view: true
        }
    }else if (Identity.isDataEntry(authUser)) {
        rules.restaurants = false
        rules.users = false
        rules.categories = {
            add: true, view: true
        }
        rules.products = {
            add: true, view: true
        }
        rules.ads = false;
        rules.settings = false;
        rules.orders = false;
        rules.notifications = {
            edit: false, view: true
        }
    }else if (Identity.isAdvertiser(authUser)) {
        rules.restaurants = false
        rules.users = false
        rules.categories = {
            add: false, view: false
        }
        rules.products = {
            add: false, view: false
        }
        rules.ads = true;
        rules.settings = false;
        rules.orders = false;
        rules.notifications = {
            edit: false, view: true
        }
    }

    return rules;
}

export function validatingJSON(json) {
    var checkedjson
    try {
        checkedjson = JSON.parse(json)
    } catch (e) {
    }
    return checkedjson
}

export function consoleLog(body, functionName) {

    // console.log(body);
}

export function ucfirst(name) {
    return name.charAt(0).toUpperCase() + name.slice(1);
}

export function logger(fun, ...arg) {
    fun && console.log('%c ' + fun, 'background: #000; color: #fff; font-size: 14px');
    console.log(arg);
}

export function checkParams(v, params, def = "") {
    let results = v;

    for (let x = 0; x < params.length; x++) {
        const key = params[x];
        if (results && typeof results == "object" && results.hasOwnProperty(key)) {

            results = results[key]

        }
    }


    return typeof results == 'string' || typeof results == 'number' ? results : def;
}


export const cleanPhoneNumber = (phone) => {
    let phoneNumber = phone.split(' ').join('');
    if (phone.substr(0, 1) == 0) {
        phoneNumber = phoneNumber.substr(1, phoneNumber.length - 1);
    }

    return phoneNumber;
}

export const getFormattedDate = (timestamp) => {
    const todayTime = new Date(timestamp);
    var month = todayTime.getMonth() + 1;
    var day = todayTime.getDate();
    var year = todayTime.getFullYear();
    return year + "-" + (month > 9 ? month : 0 + "" + month) + "-" + (day > 9 ? day : 0 + "" + day)
}

export const compareTwoObjects = (first, second) => {
    return first && second && JSON.stringify(first) === JSON.stringify(second);
}

export const jsonToDataForm = (jsonData) => {

    if (typeof jsonData !== 'object') {
        throw new Error('JSON Data must be JSON format');
        return;
    }
    const newFormData = new FormData();

    const jsonKeys = Object.keys(jsonData);
    const jsonValues = Object.values(jsonData);

    jsonValues.map((value, index) => {
        if (value) {
            newFormData.append(jsonKeys[index], value);
        }
    });

    return newFormData;
};


export function searchInObject(a, b, c) {
    let selected = null;

    if (isValidArray(a)) {
        for (let t = 0; t < a.length; t++) {
            const item = a[t];
            if (item[b] == c) {
                selected = item;
            } else {
                selected = searchInObject(item.children, b, c);
            }

            if (!!selected) {
                t = a.length;
            }
        }
    }


    !!selected && logger("selected", selected)
    return selected;
}


export function filterCategories(categoriesList = [], authUser, locale) {
    return isValidArray(categoriesList) ? categoriesList.map((cat) => {

        cat.value = cat.id;
        cat.title = cat.id !== 'All' ? cat[locale + '_name'] : 'All';
        cat.children = filterCategories(cat.children, authUser, locale)
        return cat;
    }) : [];
}

export function makeTreeStructure($categories, $parent_id = null) {

    $categories.sort(function ($a, $b) {
        return (new Date($a['created_at']).getTime()) - (new Date($b['created_at']).getTime())
    });

    return Object.values($categories.map($c => {
            $c['children'] = Object.values(
                $categories.filter(function ($cc) {
                    return $cc['parent_id'] == $c['id'];
                })
            ).filter($c => {
                return !$c['parent_id'];
            })
        })
    )
}

export class Helper {
    static consoleServerResults(response, fun = null) {
        if (response?.data?.display) {
            const {status, display} = response.data;
            const {title, body} = display;

            typeof fun == "function" && fun(response);
            if (status == "success") {
                console.log('%c' + title + " \ %c" + body, 'color: green; font-size: 12px', 'color: white; font-size: 12px');
            } else {
                console.log('%c' + title + " \ %c" + body, 'color: red; font-size: 12px', 'color: white; font-size: 12px');
            }
            // console.log(title, body);
        } else {
            console.error(response)
        }


    }
}
