import React, {useState} from 'react';
import ProductListItem from "../../../../../components/eCommerce/Products/ProductListItem";
import {show_sweet_message} from "../../../../../actions/Restaurant";
import {List} from "@material-ui/core";

const ListView = ({history,lang,deleteRecord,locale,setState,localProductList,selectedRestaurant}) => {
    return (
        <List className={"products-list"}>
            {localProductList.map((product, index) => (
                <ProductListItem key={product.id + '_list'} product={product} history={history}
                                 selectedRestaurant={selectedRestaurant} currentLanguage={locale.locale}
                                 onPressDelete={(id) => {
                                     setState(show_sweet_message({
                                         type: "warning",
                                         title: lang.confirmDelete,
                                         confirmBtnText: lang.ok,
                                         onConfirm: () => {
                                             deleteRecord(id)
                                         },
                                     }))
                                 }}/>
            ))}
        </List>
    )
}

export default ListView;

const styles = () => {
    return {
        container: {
            justifyContent: "center",
            alignItems: "center",
            flex: 1
        }
    }
}
