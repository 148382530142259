export const pages = {
    home: {
        main: 'main',
        login: 'login'
    },
    restaurants: {
        view: 'restaurants_view',
        form: 'restaurant_form',
    },
    products: {
        view: 'products_view',
        form: 'products_add',
        export: 'products_export',
    },
    categories: {
        view: 'categories_view',
        form: 'categories_add',
    },
    agencies: {
        view: 'agencies_view',
    },
    offers: {
        view: 'offers_view',
        form: 'offers_add',
    },
    ads: {
        view: 'ads_view',
        form: 'ads_add',
    },
    orders: {
        view: 'offers_view',
        form: 'offers_add',
    },
    users: {
        view: 'users_view',
        form: 'users_add',
    },
    settings: {
        view: 'users_view',
        form: 'settings_form',
    }
}
