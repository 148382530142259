import React, {useState, createContext, useEffect, useContext, isValidElement} from 'react';
import SweetAlert from "react-bootstrap-sweetalert";

const sample = {
    open: false,
    hasInput: false,
    inputType: "text",
    inputRequired: false,
    validationMsg: "You must enter your password!",
    showCancel: true,
    type:"default",
    cancelBtnBsStyle: "default",
    title: "Title",
    inputPlaceHolder: "",
    confirmBtnText:"Ok",
    confirmBtnStyle:{margin:0},
    disabled: false,
    body: null,
    closeOnCancel: true,
    closeOnConfirm: true,
    onCancel: () => {

    },
    onConfirm: () => {


    },
    onChangeInput: () => {

    }
}
export const SweetAlertContext = createContext();
const SweetAlertContextProvider = (props) => {


    const [options, setOptions] = useState(sample)


    const openAlert = (props = {}) => {

        const newProps = {
            ...sample,
            ...props,
        }

        setOptions({
            ...newProps,
            open: true,
            onCancel: () => {
                newProps.onCancel && newProps.onCancel();
                newProps.closeOnCancel && closeAlert(newProps);
            },
            onConfirm: () => {
                newProps.onConfirm && newProps.onConfirm();
                newProps.closeOnConfirm && closeAlert(newProps);
            }
        })
    }

    const closeAlert = (props = {}) => {

        const newProps = {
            ...options,
            ...props,
        }

        setOptions({
            ...newProps,
            open: false
        })
    }


    return (
        <SweetAlertContext.Provider value={{openAlert, closeAlert}}>
            {props.children}
            <SweetAlert show={options.open}
                        input={options.hasInput}
                        required={options.inputRequired}
                        inputType={options.inputType}
                        validationMsg={options.validationMsg}
                        showCancel={options.showCancel}
                        cancelBtnBsStyle={options.cancelBtnBsStyle}
                        error={options.type === "error"}
                        info={options.type === "info"}
                        warning={options.type === "warning"}
                        success={options.type === "success"}
                        confirmBtnText={options.confirmBtnText}
                        confirmBtnStyle={options.confirmBtnStyle}
                        title={options.title}
                        inputPlaceHolder={options.inputPlaceHolder}
                        disabled={options.disabled}
                        onConfirm={options.onConfirm}
                        onCancel={options.onCancel}
            >
                {typeof options.body == "string"?<h4>{options.body}</h4>:isValidElement(options.body) && options.body}
            </SweetAlert>
        </SweetAlertContext.Provider>
    );
};

export default SweetAlertContextProvider;
