import React from 'react';
import Button from '@material-ui/core/Button';
import {getText, isValidArray} from "../../../constants/Functions";
import {Normal} from "../../../constants/Normal";
import NoImage from "../../../localComponents/NoImage";
import ReactJson from "react-json-view";
import ProductImage from "../../../app/routes/products/routes/view/ProductImage";


const ProductGridItem = ({
                             product = [],
                             extra_choices = [],
                             orderView = false,
                             currentLanguage = 'en',
                             selectedRestaurant = null,
                             quantity,
                             _price = [],
                             comment,
                             itemCssClass = 'col-xl-3 col-md-4 col-sm-6 col-12',
                             onPressDelete = null,
                             history = null
                         }) => {

    const {
        id,
        prices,
        added_by,
        image_file_full_mini,
        image_file,
        category_name,
        is_active,
        created_at,
        rating,
    } = product;




    const restaurantCurrency = selectedRestaurant ? selectedRestaurant.currency : '';
    const lang = currentLanguage.toLowerCase();




    const getName = ($t) => {
        return orderView ? $t[`${currentLanguage}_name`] : $t[currentLanguage]
    }
    const getDetails = ($t) => {
        return orderView ? $t[`${currentLanguage}_details`] : $t[currentLanguage]
    }

    let selectedPrice = '';

    if (orderView) {
        const {product_id, restaurant_id, price} = _price;

        selectedPrice = getName(_price) + ' ' + price + " " + restaurantCurrency + ' x' + quantity;
    } else {
        selectedPrice = isValidArray(prices) ? prices[0][`${lang}_name`] + ' ' + prices[0].price + " " + restaurantCurrency : 0;
    }


    return (
        <div className={itemCssClass}>
            <div className="card product-item">
                <div className="card-header border-0 p-0">
                    <div className={`card-image ${!is_active && 'disabled-card'}`}>
                        <div className="grid-thumb-equal">
              <span className="grid-thumb-cover jr-link">
                <ProductImage url_image={image_file_full_mini} image_file={image_file} />
              </span>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="product-details">
                        <h3 className="card-title fw-regular">{product[`${lang}_name`]}
                            {/*{category_name && <small className="text-grey text-darken-2">{", " + category_name}</small>}*/}
                        </h3>
                        <div className="d-flex ">
                            <h3 className="card-title">{selectedPrice}</h3>
                        </div>
                        <div className="d-flex ">
                            <h3 className="card-title">{comment} </h3>
                        </div>
                        <div className="d-flex flex-row">
                            {/*
              <StarRatingComponent
                name=""
                value={rating}
                starCount={5}
                editing={false}/>
              <strong className="d-inline-block ml-2">{rating}</strong>
*/}
                        </div>
                        <p>{product[`${lang}_details`]}</p>
                    </div>

                    {
                        extra_choices.map(c => {
                            return (
                                <h5>
                                    {c[`${lang}_name`]}

                                    {c.price}
                                </h5>
                            )
                        })
                    }
                    {!orderView && <div>
                        {history && <Button
                            onClick={() => {
                                history.push('/app/products/edit/' + product.id);
                                // return Redirect({'products/add'});
                            }}
                            color="primary"
                            variant="contained"
                            className="jr-btn text-uppercase text-white btn-block">
                            <i className="zmdi zmdi-edit"/>
                            <span>{getText('buttons.edit')}</span>
                        </Button>}
                        {typeof onPressDelete == "function" && <Button
                            onClick={() => {
                                onPressDelete(product.id);
                                // return Redirect({'products/add'});
                            }}
                            color="danger"
                            variant="contained"
                            className="jr-btn jr-btn-sm text-white bg-danger btn-block">
                            <i className="zmdi zmdi-delete"/>
                            <span>{getText('buttons.delete')}</span>
                        </Button>}

                        <Button
                            onClick={() => {
                                history.push('/app/products/product/' + product.id);
                                // return Redirect({'products/add'});
                            }}
                            color="default"
                            variant="contained"
                            className="jr-btn jr-btn-sm text-white bg-secondary btn-block">
                            {/*<i className="zmdi zmdi-delete"/>*/}
                            <span>{getText('app.orders.open')}</span>
                        </Button>
                    </div>}
                </div>
            </div>
        </div>
    )
};

export default ProductGridItem;


