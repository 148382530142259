import React, {useState, createContext, useEffect, useContext} from 'react';
import {
    ADDED_SOMETHING,
    AUTH_RESTAURANT_JOINED,
    BLOCK_USER,
    CHANGE_CURRENCY, CHANGE_DIRECTION, CHANGE_NAVIGATION_STYLE, DARK_THEME, DRAWER_TYPE,
    FETCH_CATEGORIES,
    FETCH_PRODUCTS,
    FETCH_SETTINGS,
    HIDE_MESSAGE,
    HIDE_SWEET_ALERT, HORIZONTAL_MENU_POSITION,
    INIT_URL,
    LOADING,
    PAGE_NAME,
    SELECT_RESTAURANT,
    SET_RULES,
    SHOW_MESSAGE,
    SHOW_SWEET_ALERT,
    SIGNIN_USER,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER,
    SWITCH_LANGUAGE, SWITCH_LANGUAGE_SILENTLY, THEME_COLOR, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH
} from "../constants/ActionTypes";
import {SettingsContext} from "./SettingsContext";
import {AuthContext} from "./AuthContext";
import {MainContext} from "./MainContext";
import {SweetAlertContext} from "./SweetAlertContext";
import {logger} from "../constants/Helper";
import {RestaurantsContext} from "./RestsurantsContext";
import {Normal} from "../constants/Normal";

const rltLocale = ['ar'];
export const CollectContext = createContext();
const CollectContextProvider = (props) => {

    const {bind, pageName, loading, setBind, setPageName, setLoading, showMiniMessage, hideMiniMessage} = useContext(MainContext);
    const {
        setIsDirectionRTL, changeDirection, setLocale, onChangeDarkTheme,
        onChangeThemeColor, setDrawerType, setHorizontalNavPosition, setNavCollapsed, setWidth, setNavigationStyle
    } = useContext(SettingsContext);

    const {setSomethingChanged, setRestaurantJoined, setSelectedRestaurant, setRestaurantCurrency} = useContext(RestaurantsContext);
    const {setInitURL, login, signOut, rules} = useContext(AuthContext);
    const {openAlert, closeAlert} = useContext(SweetAlertContext);


    const setState = ({type, payload = null}) => {
        switch (type) {
            case PAGE_NAME:
                pageName !== payload && setPageName(payload);
                break;
            case LOADING:
                setLoading(payload);
                break;
            case INIT_URL:
                setInitURL(payload);
                break;
            case SHOW_MESSAGE:
                showMiniMessage(payload)
                break;
            case HIDE_MESSAGE:
                hideMiniMessage();
                break;
            case FETCH_CATEGORIES:
                // setCategoriesList(payload.data);
                break;
            case FETCH_PRODUCTS:
                // setProductsList(payload.data);
                break;
            case SIGNOUT_USER:
                logger('logout', 'SIGNOUT_USER')
                signOut()
                break;
            case SIGNIN_USER_SUCCESS:
                login(payload);
                break;
            case BLOCK_USER:
                // blockUser(payload);
                break;
            case SHOW_SWEET_ALERT:
                openAlert(payload ? payload : {});
                break;
            case HIDE_SWEET_ALERT:
                closeAlert(payload ? payload : {});
                break;
            case AUTH_RESTAURANT_JOINED:
                setRestaurantJoined(payload ? payload : null);
                break;
            case SWITCH_LANGUAGE:
                setIsDirectionRTL(rltLocale.includes(payload.locale))
                setLocale(payload);
                Normal.saveToStorage('locale', JSON.stringify(payload), true)
                break;
            case SWITCH_LANGUAGE_SILENTLY:
                setIsDirectionRTL(rltLocale.includes(payload.locale))
                setLocale(payload);
                Normal.saveToStorage('locale', JSON.stringify(payload), true)
                break;
            case CHANGE_DIRECTION:
                changeDirection();
                break;
            case SELECT_RESTAURANT:
                Normal.saveToStorage('selectedRestaurant', payload, true)
                setSelectedRestaurant(payload ? payload : null);
                break;
            case ADDED_SOMETHING:
                setSomethingChanged(new Date().getTime());
                break;
            case CHANGE_CURRENCY:
                setRestaurantCurrency(payload ? payload : null);
                break;
            case FETCH_SETTINGS:
                let _payload = payload.data;
                _payload = {
                    ..._payload,
                    statistics: {
                        ..._payload.statistics,
                        products: _payload.statistics.products,
                        categories: _payload.statistics.categories,
                        languages: _payload.statistics.languages,
                        users_app: _payload.statistics.users_app,
                        users_drivers: _payload.statistics.users_drivers,
                        users_admins: _payload.statistics.users_admins,
                        users_data: _payload.statistics.users_data
                    }
                }
                break;
            case THEME_COLOR:
                onChangeThemeColor(payload.color)
                break;
            case DARK_THEME:
                onChangeDarkTheme()
                break;
            case DRAWER_TYPE:
                setDrawerType(payload.drawerType);
                break;
            case HORIZONTAL_MENU_POSITION:
                setHorizontalNavPosition(payload.payload);
                break;
            case '@@router/LOCATION_CHANGE':
                setNavCollapsed(false);
                break;
            case TOGGLE_COLLAPSED_NAV:
                setNavCollapsed(payload.isNavCollapsed)
                break;
            case WINDOW_WIDTH:
                setWidth(payload.width);
                break;
            case CHANGE_NAVIGATION_STYLE:
                setNavigationStyle(payload.payload)
                break;
        }
    }

    return (
        <CollectContext.Provider value={{setState}}>
            {props.children}
        </CollectContext.Provider>
    );
};

export default CollectContextProvider;
