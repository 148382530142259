import React, {useContext} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import {AuthContext} from "../../../context/AuthContext";
import View from "./routes/view";
import {RestaurantsContext} from "../../../context/RestsurantsContext";


const Users = ({match}) => {

    const {rules} = useContext(AuthContext);


    const {authUser} = useContext(AuthContext);
    const {restaurantsList, selectedRestaurant} = useContext(RestaurantsContext);

    return(
        <div className="app-wrapper">
            <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}/view`}/>
                {/*{rules.users.add &&*/}
                {/*<Route path={`${match.url}/add`} component={Form}/>}*/}
                {/*{rules.users.add &&*/}
                {/*<Route path={`${match.url}/edit/:user_id`} component={Form}/>}*/}
                {rules.users.view &&
                <Route path={`${match.url}/view`} component={View}/>}
                <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
            </Switch>
        </div>
    )
};

export default Users;
