export const channelsAndEvents = {
    channels:{
        users:'users',
        orders:'orders',
        products:'products'
    },
    events:{
        new:'new',
        update:'update'
    }
}

const configs = {
    "admin": {
        "pusher": {
            "channels": {
                "appId": "1357326",
                "key": "45feff936c3091c397e2",
                "secret": "2c4b2e54e3edf35b32e4",
                "cluster": "ap1",
                "useTLS": true
            },
            "beams": {
                "instanceId": "737a6a5b-de6e-4d43-b915-9a3ca807274c",
                "secretKey": "5EA4F1E130D41A4EF1493B349E31EC9EDB2E3DFD766ADB56DA3E5687FF843083"
            }
        },
        "firebase": {
            "public_key": "BAYvLTpBsuibwV3zAbzAcdwlOElAT_6vDBycZ1hrD1bXV9TxY7i-MIu0adBbgeVvOOG_rkv0e93GWfUIyehfvUc",
            "config": {
                "apiKey": "AIzaSyCHuBRxJVkn4lNyBXQaOmSkLI6JsGDg5fY",
                "authDomain": "topdesign-20f17.firebaseapp.com",
                "databaseURL": "https://topdesign-20f17-default-rtdb.firebaseio.com",
                "projectId": "topdesign-20f17",
                "storageBucket": "topdesign-20f17.appspot.com",
                "messagingSenderId": "889642896219",
                "appId": "1:889642896219:web:b68cd935e0e017fdeaa87e",
                "measurementId": "G-GECC0CGRLL"
            }
        }
    },
    "trio-order": {
        "pusher": {
            "channels": {
                "appId": "1357038",
                "key": "3fbd3f5f90cf87dbdad5",
                "secret": "e2ac6c762b79e037b31d",
                "cluster": "ap4",
                "useTLS": true
            },
            "beams": {
                "instanceId": "6f734ced-07e1-4c2b-8b01-379405075ee1",
                "secretKey": "EE5B4F69F24EA4A09B5E2521B9B83155C4FE8FE5D153D6CD51F2A94685C65D22"
            }
        },
        "firebase": {
            "public_key": "BAT4uxXuydmOZ_H1BuZCuNZlmBnC1h8X0iee2E5UzYMswb_Y7TI7HashpTcFH8grkGPiNu4UpLGU5gFAjSoexp8",
            "config": {
                "apiKey": "AIzaSyDsYhzuEhSnb6SI-LXGS-TEAbOUjMtFKrk",
                "authDomain": "trio-aa6e5.firebaseapp.com",
                "databaseURL": "https://trio-aa6e5.firebaseio.com",
                "projectId": "trio-aa6e5",
                "storageBucket": "trio-aa6e5.appspot.com",
                "messagingSenderId": "808499095729",
                "appId": "1:808499095729:web:34fb0223960c6f0aee1a90",
                "measurementId": "G-LLZEW7W62C"
            }
        }
    },
    "kiwimango": {
        "pusher": {
            "channels": {
                "appId": "1357038",
                "key": "3fbd3f5f90cf87dbdad5",
                "secret": "e2ac6c762b79e037b31d",
                "cluster": "ap4",
                "useTLS": true
            },
            "beams": {
                "instanceId": "6f734ced-07e1-4c2b-8b01-379405075ee1",
                "secretKey": "EE5B4F69F24EA4A09B5E2521B9B83155C4FE8FE5D153D6CD51F2A94685C65D22"
            }
        },
        "firebase": {
            "public_key": "BPOV1hZllHYu_JYm3Y3vdP-M4jks6-rZep2e5nK6gfJMxnJIHm5Lv28aKA95f_wXkrCinXb1ffBwqhoSWrgjmfc",
            "config": {
                "apiKey": "AIzaSyBMzD1B71wYToy92hV_qYkRz2c882DLaa8",
                "authDomain": "kiwimango-837a8.firebaseapp.com",
                "databaseURL": "https://kiwimango-837a8-default-rtdb.firebaseio.com",
                "projectId": "kiwimango-837a8",
                "storageBucket": "kiwimango-837a8.appspot.com",
                "messagingSenderId": "21023484945",
                "appId": "1:21023484945:web:e75ff90cacc7f9175f55b0",
                "measurementId": "G-8BD40CBTYV"
            }
        }
    }
}


export const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const subDomain = window.location.host.split('.');


export default (function () {
    return isLocalhost ? configs.kiwimango :configs.hasOwnProperty(subDomain[0])? configs[subDomain[0]]:null;
})()
