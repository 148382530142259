import React, {useState, createContext, useEffect, useContext} from 'react';
import {AuthContext} from "./AuthContext";
import {pages} from "../constants/Variables";
import {compareTwoObjects, Helper} from "../constants/Helper";
import {MainContext} from "./MainContext";
import {serverErrorCatcher} from "../constants/ErrorCodes";
import {Server} from "../constants/Server";
import {Normal} from "../constants/Normal";
import {show_sweet_message} from "../actions/Restaurant";
import {CollectContext} from "./CollectContext";

export const OrdersContext = createContext();
const OrdersContextProvider = (props) => {


    const {loading, setLoading} = useContext(MainContext);
    const [orders_list, setOrderList] = useState([]);


    const {rules, signOut} = useContext(AuthContext);

    const {pageName} = useContext(MainContext)

    const fetchOrders = (_from = 0, _take = 2000) => {

        if (rules.orders) {

            setLoading(true);
            serverErrorCatcher(Server.getAllOrders(_from, _take).then(orders => {

                if (!compareTwoObjects(orders_list, orders.data.data)) {
                    setOrderList(orders.data.data)
                }


                setLoading(false);
            }), 'OrdersContext', 'fetchOrders', null, signOut);
        }
    }

    const changeOrderStatus = (order_id, status, estimated_delivery_time, selectedDriverId, handleRequestClose,onCatch) => {
        if (rules.orders) {
            setLoading(true);
            Server.updateOrderStatus(order_id, status, estimated_delivery_time, selectedDriverId).then(response => {
                fetchOrders();
                setLoading(false);
                handleRequestClose()
                Helper.consoleServerResults(response)
            }).catch(e => {
                onCatch && onCatch(e);
                Helper.consoleServerResults(e.response)
            })

        }
    }

    useEffect(() => {
        const ordersListState = Normal.getFromStorage("orders_list", true, true);

        if (ordersListState) {
            setOrderList(ordersListState);
        }
    }, [])

    useEffect(() => {
        pageName === pages.orders.view && fetchOrders();
    }, [pageName])

    useEffect(() => {

        orders_list.length > 0 && Normal.saveToStorage("orders_list", orders_list, true);
    }, [orders_list])


    return (
        <OrdersContext.Provider value={{orders_list, fetchOrders, changeOrderStatus}}>
            {props.children}
        </OrdersContext.Provider>
    );
}


export default OrdersContextProvider;
