// const webServer = "https://backend-menus.topdesign.pro";
const webServer = "https://restaurants.dotdesign.pro";
// const webServer = "https://backend-menus-development.topdesign.pro";
// const webServer = "http://127.0.0.1:8000";

export default {
  webServer,
  getApiKey: `${webServer}/api/getApiKey`,
  getRestaurantInfo: `${webServer}/api/g___r___info`, //get

  //Authentication
  login_anonymous: `${webServer}/api/auth/login_anonymous`, //post
  login: `${webServer}/api/auth/login`, //post
  register: `${webServer}/api/auth/register`, //post
  changePassword: `${webServer}/api/auth/changePassword`, //post  //Not used yet

  //Auth required login

  logout: `${webServer}/api/auth/logout`, //post
  refreshAuthToken: `${webServer}/api/auth/refresh`, //post
  getMyInfo: `${webServer}/api/auth/me`, //post
  updateToken: `${webServer}/api/auth/updateToken`, //user_id

  getUsers: `${webServer}/api/user/getUsers`, // get  //{type?}
  updateLanguage: `${webServer}/api/user/update/language`, //post //language
  userUpdate: `${webServer}/api/user/update/profile`, //post
  userPassword: `${webServer}/api/user/update/password`, //post
  disableUser: `${webServer}/api/user/disable`, //disable //{user}
  deleteUser: `${webServer}/api/user/delete`, //delete //{user}

  allRestaurants: `${webServer}/api/restaurants/all`, // get
  getRestaurant: `${webServer}/api/restaurants/get`, // get  //{restaurant}
  addRestaurant: `${webServer}/api/restaurants/create`, //post
  deactivateRestaurant: `${webServer}/api/restaurants/delete`, //delete //{restaurant} //{key?}

  getOrders: `${webServer}/api/order/get`,
  updateOrderStatus: `${webServer}/api/order/updateStatus`, //{offer}

  importProducts: `${webServer}/api/imp_exp/products/import`,
  exportProducts: `${webServer}/api/imp_exp/products/export`,

  getSettings: `${webServer}/api/settings/get`,
  saveSettings: `${webServer}/api/settings/save`,
  getLanguages: `${webServer}/api/settings/languages`,
  getCountries: `${webServer}/api/settings/countries`,
  getCities: `${webServer}/api/settings/cities`,

  getProducts: `${webServer}/api/products/_get`,
  addProduct: `${webServer}/api/products/create`,
  deleteProduct: `${webServer}/api/products/delete`,
  sortProducts: `${webServer}/api/products/sort`,

  addCategory: `${webServer}/api/categories/create`,
  deleteCategory: `${webServer}/api/categories/delete`,
  getCategories: `${webServer}/api/categories/_get`,
  sortCategories: `${webServer}/api/categories/sort`,

  addAd: `${webServer}/api/ads/create`,
  deleteAd: `${webServer}/api/ads/delete`,
  getAds: `${webServer}/api/ads/get`,

  sendMessage: `${webServer}/api/notifications/create`,
};
