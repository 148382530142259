export default  {
    "id": "fa5f85b0-9f2e-11eb-a544-b7038f291ad7",
    "total": "9200",
    "order_number": "#21040001",
    "address_id": "d5b89920-9e9c-11eb-b1b4-21b317fd50b3",
    "user_id": "81070920-9de3-11eb-957b-17c383bd7908",
    "created_at": "2021-04-17 03:42:48",
    "updated_at": "2021-04-17 03:42:48",
    "restaurant_id": "a832d97e-2c82-4b4d-abbb-9418b8bc4ee1",
    "last_status": "sent",
    "address": {
        "id": "d5b89920-9e9c-11eb-b1b4-21b317fd50b3",
        "title": "البيت",
        "location": "Halsey Street Keon Park Victoria AU 3060",
        "lat": "-37.70164561396241",
        "lng": "144.98462274653016",
        "deleted_at": null
    },
    "user": {
        "id": "81070920-9de3-11eb-957b-17c383bd7908",
        "first_name": "Fouad",
        "last_name": "Albadin",
        "email": "fouad.joseph.albadin@gmail.com",
        "username": "1_6121",
        "primary_phone_cc": "964",
        "primary_phone": "7518725265",
        "type": "user",
        "restaurant_id": "a832d97e-2c82-4b4d-abbb-9418b8bc4ee1",
        "phone_verified_at": null,
        "last_login": null,
        "is_active": "1",
        "deleted_at": null,
        "created_at": "2021-04-15 12:10:01",
        "updated_at": "2021-04-15 13:02:18",
        "full_name": "Fouad Albadin",
        "statistics": {
            "categories": 1,
            "products": 2
        },
        "restaurant": {
            "id": "a832d97e-2c82-4b4d-abbb-9418b8bc4ee1",
            "restaurant_name": "Trio Delivery",
            "domain_prefix": "trio-order",
            "image_file": "Trio delivery_6072d0dfd9388.png",
            "color": "#ff0000",
            "currency": "IQD",
            "is_active": "0",
            "country_id": "57ce8ca1-de39-4cec-b94c-2b7795dfc302",
            "related_to_restaurant_id": null,
            "city": "Erbil",
            "type": "delivery",
            "bundle_app_ids": "com.topdesign.trio.userapp,com.topdesign.trio.driverapp",
            "firebase_authentication": {
                "apiKey": "AIzaSyDsYhzuEhSnb6SI-LXGS-TEAbOUjMtFKrk",
                "authDomain": "trio-aa6e5.firebaseapp.com",
                "databaseURL": "https://trio-aa6e5.firebaseio.com",
                "projectId": "trio-aa6e5",
                "storageBucket": "trio-aa6e5.appspot.com",
                "messagingSenderId": "808499095729",
                "appId": "1:808499095729:web:34fb0223960c6f0aee1a90",
                "measurementId": "G-LLZEW7W62C"
            },
            "created_at": "2021-04-11 10:35:12",
            "updated_at": "2021-04-16 13:49:18",
            "deleted_at": null,
            "firebase_key": "AAAAvD5NKLE:APA91bGnO155mGUS4FbRlO_SBRpLrcw3tORRJjjU9wId1IdQ0g0jgvUh-C8JSIqODEKwGyHYiitXhZYVifp2izqmpyfkoa9HAnOZYW3A3rkd49_YIUoX2NxkXM8F511wm5oSSBaNxbs-",
            "upgraded": "1",
            "has_posters": "0",
            "app_version": null,
            "country": [
                "57ce8ca1-de39-4cec-b94c-2b7795dfc302",
                "iraq"
            ],
            "languages": [
                {
                    "value": "f3bccf10-0619-436e-810d-6231492ac39c",
                    "label": "English",
                    "code": "en",
                    "language": null
                },
                {
                    "value": "d4d257b9-1056-4bbc-8a46-da08c00b98d7",
                    "label": "العربية",
                    "code": "ar",
                    "language": null
                },
                {
                    "value": "20dd1ca0-4e12-4093-ad2f-29aeedf4339a",
                    "label": "كوردي (سورانی)",
                    "code": "kr",
                    "language": null
                }
            ],
            "statistics": {
                "products": 2,
                "categories": 1,
                "users": 3
            },
            "logo_path": "https://backend-menus.topdesign.pro/storage/logos/Trio delivery_6072d0dfd9388.png"
        },
        "agentExist": false,
        "user_detail": {
            "language": "ar",
            "firebase_token": "cc8rThzcTECmthexXrXE-B:APA91bGjNXobcQHbKyyAZu6guRay0FAitSY7lDPKy5FZapm42nBNg5XcrBM31tWMXTTmM4COfR5Y22U-wbDWbPGGBAqRKRGCXg5NQUeDlae0vvwyALNS_i_T9ZTTPwx93BTMa0rzWddV",
            "gender": null,
            "birthday": null,
            "device_info": "{\"device_model\":\"iPhone 12 Pro Max\",\"device_system_number\":\"14.4.1\",\"device_system_name\":\"iOS\",\"unique_id\":\"F6AC6DC6-6532-4C58-A3E6-8E57CA5C9FB4\",\"device_type\":\"Apple\",\"device_id\":\"iPhone13,4\"}",
            "image_file": "_default.png",
            "image_file_path": "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
        },
        "user_addresses": [
            {
                "id": "d5b89920-9e9c-11eb-b1b4-21b317fd50b3",
                "title": "البيت",
                "location": "Halsey Street Keon Park Victoria AU 3060",
                "lat": "-37.70164561396241",
                "lng": "144.98462274653016",
                "deleted_at": null
            }
        ]
    },
    "estimated_delivery_time": null,
    "created_at_human": "3 minutes ago",
    "order_details": [
        {
            "id": "fa603360-9f2e-11eb-ae88-81d1c045496b",
            "quantity": "7",
            "comment": "بدون نايونيز",
            "product_clone": {
                "id": "9faf3820-5bbd-4eed-8cbd-2ba631c18ec4",
                "image_file_full": "https://backend-menus.topdesign.pro/storage/images/trio-order/product 2_6078f459d4ceb.png",
                "image_file_full_mini": "https://backend-menus.topdesign.pro/storage/images/trio-order/mini_product 2_6078f459d4ceb.png",
                "restaurant_id": "a832d97e-2c82-4b4d-abbb-9418b8bc4ee1",
                "category_id": "6a96944f-9c42-4857-ab4e-37b4cc73f3f6",
                "is_active": "1",
                "kr_name": null,
                "kr_details": null,
                "ar_name": "منتج ٢",
                "ar_details": null,
                "en_name": "Product 2",
                "en_details": null,
                "tr_name": null,
                "tr_details": null,
                "ku_name": null,
                "ku_details": null,
                "prices": [
                    {
                        "product_id": "9faf3820-5bbd-4eed-8cbd-2ba631c18ec4",
                        "restaurant_id": null,
                        "created_at": "2021-04-16 02:20:11",
                        "updated_at": "2021-04-16 02:20:11",
                        "price_type_id": "6544a451-b3b4-43b0-989a-584eccad8688",
                        "price": "500.00",
                        "kr_name": "بچووک",
                        "ar_name": "صغير",
                        "en_name": "Small",
                        "tr_name": "Küçük",
                        "ku_name": null,
                        "id": "6544a451-b3b4-43b0-989a-584eccad8688"
                    },
                    {
                        "product_id": "9faf3820-5bbd-4eed-8cbd-2ba631c18ec4",
                        "restaurant_id": null,
                        "created_at": "2021-04-16 02:20:11",
                        "updated_at": "2021-04-16 02:20:11",
                        "price_type_id": "789d6c91-b337-4fe7-86df-395ee0ee7de2",
                        "price": "600.00",
                        "kr_name": "مامناوەند",
                        "ar_name": "متوسط",
                        "en_name": "Medium",
                        "tr_name": "Orta",
                        "ku_name": null,
                        "id": "789d6c91-b337-4fe7-86df-395ee0ee7de2"
                    },
                    {
                        "product_id": "9faf3820-5bbd-4eed-8cbd-2ba631c18ec4",
                        "restaurant_id": null,
                        "created_at": "2021-04-16 02:20:11",
                        "updated_at": "2021-04-16 02:20:11",
                        "price_type_id": "c93c9519-dbaa-4e0c-a2b8-951a80c1ec88",
                        "price": "800.00",
                        "kr_name": "گەورە",
                        "ar_name": "كبير",
                        "en_name": "Large",
                        "tr_name": "büyük",
                        "ku_name": null,
                        "id": "c93c9519-dbaa-4e0c-a2b8-951a80c1ec88"
                    }
                ],
                "choices": [
                    {
                        "product_id": "9faf3820-5bbd-4eed-8cbd-2ba631c18ec4",
                        "restaurant_id": "a832d97e-2c82-4b4d-abbb-9418b8bc4ee1",
                        "created_at": "2021-04-16 02:20:11",
                        "updated_at": "2021-04-16 02:20:11",
                        "extra_choice_id": "04d55c67-f72d-42f1-821b-2f6794321c5e",
                        "price": "10.00",
                        "kr_name": null,
                        "ar_name": "حد",
                        "en_name": "Spicy",
                        "tr_name": null,
                        "ku_name": null
                    },
                    {
                        "product_id": "9faf3820-5bbd-4eed-8cbd-2ba631c18ec4",
                        "restaurant_id": "a832d97e-2c82-4b4d-abbb-9418b8bc4ee1",
                        "created_at": "2021-04-16 02:20:11",
                        "updated_at": "2021-04-16 02:20:11",
                        "extra_choice_id": "5261f938-cc66-4918-8194-f0d3cb0daf73",
                        "price": "20.00",
                        "kr_name": null,
                        "ar_name": "بابيكيو",
                        "en_name": "BBQ Souce",
                        "tr_name": null,
                        "ku_name": null
                    }
                ]
            },
            "price_type_clone": {
                "product_id": "9faf3820-5bbd-4eed-8cbd-2ba631c18ec4",
                "restaurant_id": null,
                "created_at": "2021-04-16 02:20:11",
                "updated_at": "2021-04-16 02:20:11",
                "price_type_id": "789d6c91-b337-4fe7-86df-395ee0ee7de2",
                "price": "600.00",
                "kr_name": "مامناوەند",
                "ar_name": "متوسط",
                "en_name": "Medium",
                "tr_name": "Orta",
                "ku_name": null,
                "id": "789d6c91-b337-4fe7-86df-395ee0ee7de2"
            },
            "order_id": "fa5f85b0-9f2e-11eb-a544-b7038f291ad7",
            "product_id": "9faf3820-5bbd-4eed-8cbd-2ba631c18ec4",
            "created_at": "2021-04-17 03:42:48",
            "updated_at": "2021-04-17 03:42:48",
            "restaurant_id": "a832d97e-2c82-4b4d-abbb-9418b8bc4ee1",
            "extra_choices_clone": [
                {
                    "product_id": "9faf3820-5bbd-4eed-8cbd-2ba631c18ec4",
                    "restaurant_id": "a832d97e-2c82-4b4d-abbb-9418b8bc4ee1",
                    "created_at": "2021-04-16 02:20:11",
                    "updated_at": "2021-04-16 02:20:11",
                    "extra_choice_id": "04d55c67-f72d-42f1-821b-2f6794321c5e",
                    "price": "10.00",
                    "kr_name": null,
                    "ar_name": "حد",
                    "en_name": "Spicy",
                    "tr_name": null,
                    "ku_name": null
                }
            ]
        },
        {
            "id": "fa60fa20-9f2e-11eb-b690-cb2b07e5f4c8",
            "quantity": "10",
            "comment": "مع توم زيادة",
            "product_clone": {
                "id": "3eb82ce1-5fa8-4b5e-bc2e-13662ea2aafd",
                "image_file_full": "https://backend-menus.topdesign.pro/storage/images/trio-order/product 1_6078f373e5b35.png",
                "image_file_full_mini": "https://backend-menus.topdesign.pro/storage/images/trio-order/mini_product 1_6078f373e5b35.png",
                "restaurant_id": "a832d97e-2c82-4b4d-abbb-9418b8bc4ee1",
                "category_id": "6a96944f-9c42-4857-ab4e-37b4cc73f3f6",
                "is_active": "1",
                "kr_name": null,
                "kr_details": null,
                "ar_name": "منتج ١",
                "ar_details": null,
                "en_name": "Product 1",
                "en_details": null,
                "tr_name": null,
                "tr_details": null,
                "ku_name": null,
                "ku_details": null,
                "prices": [
                    {
                        "product_id": "3eb82ce1-5fa8-4b5e-bc2e-13662ea2aafd",
                        "restaurant_id": null,
                        "created_at": "2021-04-16 02:16:20",
                        "updated_at": "2021-04-16 02:16:20",
                        "price_type_id": "6544a451-b3b4-43b0-989a-584eccad8688",
                        "price": "200.00",
                        "kr_name": "بچووک",
                        "ar_name": "صغير",
                        "en_name": "Small",
                        "tr_name": "Küçük",
                        "ku_name": null,
                        "id": "6544a451-b3b4-43b0-989a-584eccad8688"
                    },
                    {
                        "product_id": "3eb82ce1-5fa8-4b5e-bc2e-13662ea2aafd",
                        "restaurant_id": null,
                        "created_at": "2021-04-16 02:16:20",
                        "updated_at": "2021-04-16 02:16:20",
                        "price_type_id": "789d6c91-b337-4fe7-86df-395ee0ee7de2",
                        "price": "300.00",
                        "kr_name": "مامناوەند",
                        "ar_name": "متوسط",
                        "en_name": "Medium",
                        "tr_name": "Orta",
                        "ku_name": null,
                        "id": "789d6c91-b337-4fe7-86df-395ee0ee7de2"
                    },
                    {
                        "product_id": "3eb82ce1-5fa8-4b5e-bc2e-13662ea2aafd",
                        "restaurant_id": null,
                        "created_at": "2021-04-16 02:16:20",
                        "updated_at": "2021-04-16 02:16:20",
                        "price_type_id": "c93c9519-dbaa-4e0c-a2b8-951a80c1ec88",
                        "price": "400.00",
                        "kr_name": "گەورە",
                        "ar_name": "كبير",
                        "en_name": "Large",
                        "tr_name": "büyük",
                        "ku_name": null,
                        "id": "c93c9519-dbaa-4e0c-a2b8-951a80c1ec88"
                    }
                ]
            },
            "price_type_clone": {
                "product_id": "3eb82ce1-5fa8-4b5e-bc2e-13662ea2aafd",
                "restaurant_id": null,
                "created_at": "2021-04-16 02:16:20",
                "updated_at": "2021-04-16 02:16:20",
                "price_type_id": "789d6c91-b337-4fe7-86df-395ee0ee7de2",
                "price": "300.00",
                "kr_name": "مامناوەند",
                "ar_name": "متوسط",
                "en_name": "Medium",
                "tr_name": "Orta",
                "ku_name": null,
                "id": "789d6c91-b337-4fe7-86df-395ee0ee7de2"
            },
            "order_id": "fa5f85b0-9f2e-11eb-a544-b7038f291ad7",
            "product_id": "3eb82ce1-5fa8-4b5e-bc2e-13662ea2aafd",
            "created_at": "2021-04-17 03:42:48",
            "updated_at": "2021-04-17 03:42:48",
            "restaurant_id": "a832d97e-2c82-4b4d-abbb-9418b8bc4ee1",
            "extra_choices_clone": []
        }
    ],
    "order_histories": [
        {
            "id": "fa613700-9f2e-11eb-b0f4-a96fa626b153",
            "seen": "0",
            "status": "sent",
            "estimated_delivery_time": null,
            "order_id": "fa5f85b0-9f2e-11eb-a544-b7038f291ad7",
            "created_at": "2021-04-17 03:42:48",
            "updated_at": "2021-04-17 03:42:48",
            "created_at_human": {
                "ar": "منذ دقائق 3",
                "en": "3 minutes ago",
                "kr": "منذ دقائق 3"
            }
        }
    ],
    "order_driver": null
}
