import React, {useContext} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";
import Export from "./Export";
import {AuthContext} from "../../../context/AuthContext";
import Import from "./Import";


const ImportExport = ({match}) => {

    const {rules} = useContext(AuthContext);

    return(
        <div className="app-wrapper">
            <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}i`}/>
                {rules.importExport.import &&
                <Route path={`${match.url}/i`} component={Import}/>}
                {rules.importExport.export &&
                <Route path={`${match.url}/e`} component={Export}/>}
                <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
            </Switch>
        </div>
    )
};

export default ImportExport;
