import React, {useEffect, useRef, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import IntlMessages from "../../util/IntlMessages";


const TextInput = ({
                       id,
                       label,
                       style = null,
                       required,
                       value,
                       onChange,
                       onFocus,
                       helperText,
                       loading,
                       type = "text",
                       multiline = false,
                       rowsMax
                   }) => (
    <TextField
        id={id}
        label={label}
        required={required}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        multiline={multiline}
        style={style}
        margin="normal"
        InputLabelProps={{
            shrink: true,
        }}
        rowsMax={rowsMax}
        type={type}
        helperText={helperText}
        disabled={loading}
        fullWidth
    />
)

const imagesTypes = ["jpeg", "jpg", "gif", "bmp", "png"];
const videosTypes = ["mp4"];
const ImageInput = ({
                        source = null,
                        onLine = false,
                        returnString = false,
                        onChange = null,
                        maxSize = 7,
                        label = "Image Cover",
                        loading = false,
                        validate = false,
                        errorFunc = null,
                        allowImages = true,
                        allowVideos = false,
                        fileInfo = null,
                        metadata = null
                    }) => {

    const [imagePreview, setImagePreview] = useState(null);
    const [videoPreview, setVideoPreview] = useState(null);
    const [renderPreview, setRenderPreview] = useState(null);
    const [inputEvent, setInputEvent] = useState(null);
    const [acceptedFile, setAcceptedFile] = useState(false);
    const [fileSize, setFileSize] = useState(0);


    const refContainer = useRef(null);


    useEffect(() => {
        fileInfo && metadata && fileInfo(metadata);
    }, []);

    useEffect(() => {
        acceptedFile && onChange && onChange(inputEvent);
    }, [acceptedFile]);

    useEffect(() => {

        if (source && typeof source == "string" && !inputEvent) {
            let mediaType = source.split('.').pop();

            if (imagesTypes.indexOf(mediaType) !== -1) {
                if (!imagePreview && source && allowImages) {
                    setImagePreview(source);
                }
            }
            if (videosTypes.indexOf(mediaType) !== -1) {
                if (!videoPreview && source && allowVideos) {
                    setVideoPreview(source);
                }
            }
        }

    }, [source]);

    const renderImage = () => {
        var file, img;
        img = new Image();
        let reader = new FileReader();
        file = inputEvent;

        reader.onload = e => {
            var img = new Image();
            img.src = e.target.result;
            returnString && onChange && onChange(e.target.result);

            img.onload = function (e) {
                var w = this.width;
                var h = this.height;
                var size = file.size / (1024 * 1024);

                fileInfo && fileInfo({
                    width: w,
                    height: h,
                    size: size
                })

            }
        }
        reader.onloadend = () => {
            !returnString && onChange && onChange(file);
            setImagePreview(reader.result);
            setVideoPreview(null);
        };

        reader.readAsDataURL(file)
    }

    const renderVideo = () => {

        setFileSize(inputEvent.size);


        setVideoPreview(inputEvent);
        setImagePreview(null);
    };


    useEffect(() => {
        imagePreview && allowImages && setRenderPreview(<img style={{width: "45%"}} src={imagePreview}/>);

        videoPreview && allowVideos && setRenderPreview(
            <video
                controls={true}
                style={{width: "100%"}}
                onLoadedMetadata={e => {
                    // var size = fileSize / (1024 * 1024);

                    fileInfo && fileInfo({
                        height: e.target.videoHeight,
                        width: e.target.videoWidth
                    });
                }}
            >
                <source src={typeof videoPreview == "string" ? videoPreview : URL.createObjectURL(videoPreview)}
                        type="video/mp4"/>
            </video>
        )
    }, [videoPreview, imagePreview]);

    useEffect(() => {
        inputEvent && onInputChange();
    }, [inputEvent]);


    const onInputChange = () => {
        let file = inputEvent;
        let type = null;
        let currentType = file.type.split("/").pop();

        if (allowImages && imagesTypes.indexOf(currentType) !== -1) {
            type = "image";
            renderImage();
        }

        if (allowVideos && videosTypes.indexOf(currentType) !== -1) {
            type = "video";
            renderVideo();
        }

        if (type == null) {
            errorFunc && errorFunc({
                message: "Type not supported"
            });
        } else {
            setAcceptedFile(true);
        }

    }

    const ErrorHint = () => (
        <Typography variant="span" color={"error"} component="span"><IntlMessages
            id="sidebar.cards.form.validate"/></Typography>
    );

    return (
        <div>

            <TextInput
                id="image"
                ref={refContainer}
                label={label}
                style={{width: onLine ? '50%' : '100%'}}
                type={"file"}
                helperText={source == null && validate && <ErrorHint/>}
                onChange={(e) => {
                    setRenderPreview(null);

                    let file = e.target.files[0];
                    var size = file.size / (1024 * 1024);

                    if (size > maxSize) {
                        errorFunc && errorFunc({
                            message: "File is too large"
                        });

                        e.target.value = null;
                    } else {
                        setInputEvent(file);
                    }


                }}
                margin="normal"
                fullWidth
                loading={loading}
            />
            {renderPreview}
        </div>
    )

}


export default ImageInput;
