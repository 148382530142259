import React, {useEffect, useState, useContext, useRef} from 'react';
import {Link} from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    changePageName,
    hideMessage,
    toggleLoading,
    userSignInSuccess
} from "../actions";
import {pages} from "../constants/Variables";
import {RestaurantsContext} from "../context/RestsurantsContext";
import {MainContext} from "../context/MainContext";
import {CollectContext} from "../context/CollectContext";
import {AuthContext} from "../context/AuthContext";
import {logger} from "../constants/Helper";
import {Normal} from "../constants/Normal";
import {Checkbox, FormControlLabel} from "@material-ui/core";

const SignIn = ({history}) => {

    const {alertMessage, loading, showMessage, setServerResponse} = useContext(MainContext);
    const {setState} = useContext(CollectContext);
    const {authUser, signIn} = useContext(AuthContext);
    const {restaurantJoined} = useContext(RestaurantsContext);


    const [loginDetails, setLoginDetails] = useState({
        primary_phone: '',
        username: '',
        email: '',
        password: '',
    });
    const [rememberMe, setRememberMe] = useState(false);


    useEffect(() => {
        const e = Normal.getFromStorage('login_email', false, true)
        const ph = Normal.getFromStorage('login_primary_phone', false, true)
        const u = Normal.getFromStorage('login_username', false, true)
        const p = Normal.getFromStorage('login_password', false, true)
        if (p && (!!u || !!ph || !!e)) {
            setLoginDetails({
                primary_phone: ph,
                username: u,
                email: e,
                password: p,
            })

            setRememberMe(true);
        }
        setState(changePageName(pages.home.login))
    }, []);


    useEffect(() => {
        if (showMessage) {

            setTimeout(() => {
                setState(hideMessage());
            }, 100);
        }
        if (authUser) {
            history.push('/');
        }
        setState(toggleLoading(false));
    }, [showMessage, authUser]);


    const signInUser = () => {
        setState(toggleLoading(true));

        const {primary_phone, username, email, password} = loginDetails;

        try {
            let restaurant_id = "";
            if (restaurantJoined && restaurantJoined.hasOwnProperty("id") && restaurantJoined.id.length == 36) {
                restaurant_id = restaurantJoined.id;
            }

            signIn({...loginDetails, restaurant_id}).then((res) => {
                setServerResponse(res);
                if (rememberMe) {
                    Normal.saveToStorage('login_email', email, true)
                    Normal.saveToStorage('login_primary_phone', primary_phone, true)
                    Normal.saveToStorage('login_username', username, true)
                    Normal.saveToStorage('login_password', password, true)
                }
                setState(userSignInSuccess(res.data.data));
                window.location.replace('/');
            }).catch(e => {
                logger("error", e)
                if (e?.response?.status == 401) {
                    setServerResponse(e.response);
                    setState(toggleLoading(true));
                }

                // setServerResponse(e.response)
            })
        } catch (error) {
            logger("catch", error)
            // setServerResponse(error.response)
            // setState(showAuthMessage('error', '', error));

        }

    }
    const loginFieldsRef = useRef();



    return (
        <div
            className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <div className="app-login-main-content">

                <div className="app-logo-content d-flex align-items-center justify-content-center"
                     style={{backgroundColor: restaurantJoined ? restaurantJoined.color : "#6b6d77"}}>
                    <Link className="logo-lg" to="/"
                          title={restaurantJoined ? restaurantJoined.restaurant_name : "Top Design"}>
                        <img
                            width={"100%"}
                            src={restaurantJoined ? restaurantJoined.logo_path : require("../assets/images/logo.png")}
                            alt={restaurantJoined ? restaurantJoined.restaurant_name : "Top Design"}
                            title={restaurantJoined ? restaurantJoined.restaurant_name : "Top Design"}
                        />
                    </Link>
                </div>

                <div className="app-login-content">
                    <div className="app-login-header mb-4">
                        {restaurantJoined &&
                        <h1>Welcome to {restaurantJoined.restaurant_name} Restaurant</h1>}
                        {!restaurantJoined && <h1>Welcome to TopDesign Company</h1>}
                        {/*<h1>{JSON.stringify(authUser)}</h1>*/}
                    </div>

                    <div className="app-login-form">
                        <form>

                            <fieldset ref={loginFieldsRef}>
                                <TextField
                                    label={<IntlMessages id={`appModule.${loginDetails.primary_phone?'phone':loginDetails.email?'email':'username'}`}/>}
                                    fullWidth
                                    disabled={loading}
                                    onChange={(event) => {
                                        const text = event.target.value;
                                        const firstChar = text.charAt(0);
                                        const phone = Normal.is_numeric(firstChar);
                                        if (phone) {
                                            setLoginDetails(prevState => ({
                                                    ...prevState,
                                                    primary_phone: text,
                                                    email: '',
                                                    username: ''
                                                }),
                                            );
                                        } else {
                                            if (Normal.isEmails(text)) {
                                                setLoginDetails(prevState => ({
                                                        ...prevState,
                                                        primary_phone: '',
                                                        email: text,
                                                        username: ''
                                                    }),
                                                );
                                            } else {
                                                setLoginDetails(prevState => ({
                                                        ...prevState,
                                                        primary_phone: '',
                                                        email: '',
                                                        username: text,
                                                    }),
                                                );
                                            }

                                        }
                                    }}
                                    defaultValue={loginDetails.username || loginDetails.email || loginDetails.primary_phone}
                                    margin="normal"
                                    className="mt-1 my-sm-3"
                                />
                                <TextField
                                    type="password"
                                    label={<IntlMessages id="appModule.password"/>}
                                    fullWidth
                                    disabled={loading}
                                    onChange={(event) => {
                                        const text = event.target.value;

                                        setLoginDetails(prevState => ({
                                            ...prevState,
                                            password: text
                                        }))
                                    }}
                                    defaultValue={loginDetails.password}
                                    margin="normal"
                                    className="mt-1 my-sm-3"
                                />
                            </fieldset>

                            <fieldset>
                                <div className="mt-1 mb-2 d-flex justify-content-between align-items-center">
                                    <FormControlLabel
                                        control={
                                            <Checkbox color="primary"
                                                      checked={rememberMe}
                                                      onClick={() => setRememberMe(prevState => !prevState)}
                                                      value="gilad"
                                            />
                                        }
                                        label={<IntlMessages id="appModule.rememberMe"/>}
                                    />

                                    <div>
                                        <Link to="/forget_password" title="Reset Password">
                                            <IntlMessages id="appModule.forgotPassword"/>
                                        </Link>
                                    </div>
                                </div>


                                <Button variant="contained" disabled={loading} color="primary"
                                        className="jr-btn text-white btn-primary"
                                        onClick={signInUser}>
                                    <IntlMessages id="appModule.signIn"/>
                                </Button>

                            </fieldset>


                        </form>
                    </div>
                </div>

            </div>
            {
                loading &&
                <div className="loader-view">
                    <CircularProgress/>
                </div>
            }
            {/*{showMessage && NotificationManager.error(alertMessage)}*/}

        </div>
    );
}


export default SignIn;
