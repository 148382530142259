import React, {useContext, useEffect, useState} from 'react';
import IntlMessages from 'util/IntlMessages';
import DataTable from '../../../../../components/Datatables';
import SweetAlert from "react-bootstrap-sweetalert";
import {ButtonGroup} from "reactstrap";
import Button from "@material-ui/core/Button";
import {withRouter} from "react-router";
import {Edit} from "@material-ui/icons";
import 'antd/dist/antd.css';
import {changePageName} from "../../../../../actions";
import {pages} from "../../../../../constants/Variables";
import {UsersContext} from "../../../../../context/UsersContext";
import {AuthContext} from "../../../../../context/AuthContext";
import {MainContext} from "../../../../../context/MainContext";
import {CollectContext} from "../../../../../context/CollectContext";
import Drawer from "@material-ui/core/Drawer";
import CustomScrollbars from "../../../../../util/CustomScrollbars";
import {useIntl} from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Form from "../form";
import ViewContact from "../view-contact";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {getText} from "../../../../../constants/Functions";
import {Server} from "../../../../../constants/Server";
import {SettingsContext} from "../../../../../context/SettingsContext";
import {Identity} from "../../../../../constants/Identity";
import {RestaurantsContext} from "../../../../../context/RestsurantsContext";
import Avatar from "@material-ui/core/Avatar";


const View = ({history}) => {


    const {setState} = useContext(CollectContext);
    const {usersList, fetchUsers} = useContext(UsersContext);
    const {authUser} = useContext(AuthContext)
    const {pageName} = useContext(MainContext)
    const {selectedRestaurant} = useContext(RestaurantsContext)

    let filterOptions = [
        {
            id: 1,
            name: getText('app.users.all'),
            type: 'all',
            icon: 'zmdi-menu'
        }, {
            id: 2,
            name: getText('app.users.admin'),
            type: 'admin',
            icon: 'zmdi-time-restore'

        }, {

            id: 3,
            name: getText('app.users.user'),
            type: 'user',
            icon: 'zmdi-star'
        }, {

            id: 4,
            name: getText('app.users.driver'),
            type: 'driver',
            icon: 'zmdi-star'
        },  {

            id: 6,
            name: getText('app.users.data'),
            type: 'data',
            icon: 'zmdi-star'
        }
    ];

    if (Identity.isStandardRestaurant(selectedRestaurant) || Identity.isSenior(authUser)) {
        filterOptions.push({

            id: 5,
            name: getText('app.users.advertiser'),
            type: 'advertiser',
            icon: 'zmdi-star'
        })
    }

    if (Identity.isSenior(authUser)) {
        filterOptions.push({

            id: 6,
            name: getText('app.users.senior'),
            type: 'senior',
            icon: 'zmdi-star'
        })
    }

    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        open: false,
        showCancel: false,
        type: "success",
        btnText: getText('buttons.submit'),
        title: "Title",
        text: "Some Text",
        onCancel: () => {
            setMessage({
                ...message,
                open: false
            })
        },
    });

    const [editableUser, setEditableUser] = useState(null);
    const [linkedUser, setLinkedUser] = useState(null);

    //new
    const {width} = useContext(SettingsContext);
    const [drawerState, setDrawerState] = useState(false);
    const [user] = useState({
        name: "Robert Johnson",
        email: "robert.johnson@example.com",
        avatar: "https://via.placeholder.com/150x150"
    });


    const [viewUser, setViewUser] = useState(null);
    const [selectedSectionId, setSelectedSectionId] = useState(1);
    const [contactList, setContactList] = useState([]);
    const [filterOption, setFilterOption] = useState("all");

    const [openForm, setOpenForm] = useState(false)

    const columns = [
        {
            id: 'image',
            align: true,
            disablePadding: false,
            label: '#',
            renderedComponent: (row, index) => {
                return (
                    <Avatar
                        alt='...'
                        imgProps={{
                            style:{
                                height:'100%'
                            }
                        }}
                        src={row.image_file_full}
                        className="user-avatar "
                    />

                )
            }
        },
        {
            id: 'name',
            align: true,
            disablePadding: false,
            label: getText('app.users.full_name')
        },
        {
            id: 'username',
            align: true,
            disablePadding: false,
            label: getText('app.users.username')
        },
        {
            id: 'email',
            align: true,
            disablePadding: false,
            label: getText('app.users.email')
        },
        {
            id: 'phone',
            align: true,
            disablePadding: false,
            label: getText('app.users.phone')
        },
        /*
                {
                    id: 'type_label',
                    align: true,
                    disablePadding: false,
                    label: getText('app.users.type')
                },
        */
        {
            id: 'actions', align: true, disablePadding: false, label: '#', renderedComponent: (row, index) => {
                const is_active = row.is_active == 1;
                return (
                    <ButtonGroup vertical={false}>
                        <Button style={{marginLeft: 5}}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setLinkedUser(null);
                                    setEditableUser(row);
                                    setOpenForm(true)
                                }}
                                href={""}>
                            <Edit/>
                        </Button>
                        <Button style={{marginLeft: 5}}
                                variant="contained"
                                disabled={authUser.id === row.id}
                                className={is_active ? "bg-danger text-white" : "bg-success text-white"}
                                onClick={() => {
                                    setMessage({
                                        ...message,
                                        type: "error",
                                        showCancel: true,
                                        btnText: getText('buttons.submit'),
                                        onConfirm: () => {
                                            disableRecord(row)
                                        },

                                        title: <h2
                                            className="text-danger">{is_active ? getText('app.users.disable_message_confirm') : getText('app.users.enable_message_confirm')}</h2>,
                                        text: is_active ? getText('app.users.disable_message_success') : getText('app.users.enable_message_success'),
                                        open: true
                                    });

                                }}
                                href={""}>
                            <h5 style={{margin: 0}}>{is_active ? getText('app.users.disable') : getText('app.users.enable')}</h5>
                        </Button>
                        {Identity.isSenior(authUser) && <Button style={{marginLeft: 5}}
                                 variant="contained"
                                 disabled={authUser.id === row.id}
                                 className={"bg-danger text-white"}
                                 onClick={() => {
                                     setMessage({
                                         ...message,
                                         type: "error",
                                         showCancel: true,
                                         btnText: getText('buttons.delete'),
                                         onConfirm: () => {
                                             deleteRecord(row)
                                         },
                                         title: <h2
                                             className="text-danger">{getText('app.users.delete_message_confirm')}</h2>,
                                         text: getText('app.users.delete_message_success'),
                                         open: true
                                     });
                                 }}
                                 href={""}>
                            <h5 style={{margin: 0}}>{getText('app.users.delete')}</h5>
                        </Button>}
                        <Button style={{marginLeft: 5}}
                                variant="contained"
                                className={"bg-success text-white"}
                                onClick={() => {
                                    setViewUser(row)
                                }}
                                href={""}>
                            <h5 style={{margin: 0}}>{getText('app.users.view_contact')}</h5>
                        </Button>
                    </ButtonGroup>

                )
            }
        },
    ];


    useEffect(() => {
        pageName !== pages.users.view && setState(changePageName(pages.users.view))

        setContactList(usersList);

    }, [])

    useEffect(() => {
        if (Array.isArray(usersList)) {
            if (filterOption === 'all') {
                setContactList(usersList);
            } else {
                setContactList(usersList.filter((contact) => contact.user_type === filterOption));
            }
        }
    }, [usersList])


    useEffect(() => {

        makeDatatablesFromUsers();

    }, [contactList]);


    const makeDatatablesFromUsers = () => {
        if (contactList) {

            let _users_list = [];
            Array.isArray(contactList) && contactList.map((user, index) => {

                if (Identity.isDeliveryRestaurant(selectedRestaurant)) {
                    if (user.restaurant_id !== selectedRestaurant.id) {
                        return;
                    }
                }

                let _user = {};
                _user.id = user.id;
                _user.name = user.full_name;
                _user.username = user.username;
                _user.image_file_full = user.image_file_full;
                _user.email = user.email;
                if (user.hasOwnProperty('primary_phone') && user.primary_phone) {
                    _user.phone = "0" + user.primary_phone;
                }
                _user.type_label = getText(`app.users.${user.user_type}`);
                _user.user_type = user.user_type;
                _user.is_active = user.is_active;
                _users_list.push(_user);
            });

            setTableData(_users_list);
        }

    };
    const ContactSideBar = (user) => {
        return <div className="module-side">
            <div className="module-side-header">
                <div className="module-logo">
                    <i className="zmdi zmdi-account-box mr-4"/>
                    <span><IntlMessages id="app.users"/></span>
                </div>
            </div>

            <div className="module-side-content">
                <CustomScrollbars className="module-side-scroll scrollbar"
                                  style={{height: width >= 1200 ? 'calc(100vh - 200px)' : 'calc(100vh - 80px)'}}>

                    <div className="module-add-task">
                        <Button className="jr-btn btn-block" variant="contained" color="primary" aria-label="add"
                                onClick={() => {
                                    setOpenForm(true)
                                }}>
                            <i className="zmdi zmdi-account-add zmdi-hc-fw"/>
                            <span>{getText('app.users.add')}</span>
                        </Button>
                    </div>

                    <div className="module-side-nav">
                        <ul className="module-nav">
                            {filterOptions.map(option => <li key={option.id} className="nav-item">
                  <span
                      className={`jr-link ${option.id === selectedSectionId ? 'active' : ''}`} onClick={
                      () => onFilterOptionSelect(option)
                  }>
                    <i className={`zmdi ${option.icon}`}/>
                    <span>{option.name}</span>
                  </span>
                                </li>
                            )}

                        </ul>
                    </div>
                </CustomScrollbars>
            </div>
        </div>

    };//Updated
    const onFilterOptionSelect = (option) => {
        switch (option.type) {
            case 'all': {
                setSelectedSectionId(option.id);
                setFilterOption(option.name);
                setContactList(usersList);
                break;
            }
            case 'senior':
            case 'admin':
            case 'driver':
            case 'user':
            case 'data':
            case 'agent': {
                setSelectedSectionId(option.id);
                setFilterOption(option.type);
                setContactList(usersList.filter((contact) => contact.user_type == option.type));
                break;
            }
            default: {
                setSelectedSectionId(option.id);
                setFilterOption(option.type);
                setContactList(usersList);
            }
        }

    };//Updated
    const onToggleDrawer = () => {
        setDrawerState(!drawerState)
    };//Updated


    const deleteRecord = (user) => {

        setLoading(true);

        Server.deleteUser(user.id).then(r => {
            fetchUsers()
            setMessage({
                ...message,
                type: "success",
                btnText: getText('buttons.submit'),
                title: getText('app.users.delete'),
                text: getText('app.users.delete_message_success'),
                open: true,
                onConfirm: () => {
                    fetchUsers()
                }
            });
        }).catch(e => {
            setMessage({
                ...message,
                type: "error",
                btnText: getText('buttons.submit'),
                title: getText('notification.error'),
                text: getText('app.message_failed'),
                open: true
            });
        }).finally(() => {
            setLoading(false);
        })
    };

    const disableRecord = (user) => {

        setLoading(true);

        Server.disableUser(user.id).then(r => {
            fetchUsers()
            setMessage({
                ...message,
                type: "success",
                btnText: getText('buttons.submit'),
                title: user.is_active ? getText('app.users.disable') : getText('app.users.enable'),
                text: user.is_active ? getText('app.users.disable_message_success') : getText('app.users.enable_message_success'),
                open: true,
                onConfirm: () => {

                }
            });
        }).catch(e => {
            setMessage({
                ...message,
                type: "error",
                btnText: getText('buttons.submit'),
                title: getText('notification.error'),
                text: getText('app.message_failed'),
                open: true
            });
        }).finally(() => {
            setLoading(false);
        })
    };


    return (
        <div style={{marginTop: 15}}>
            <div className="app-module animated slideInUpTiny animation-duration-3">
                <div className="d-block d-xl-none">
                    <Drawer
                        open={drawerState}
                        onClose={onToggleDrawer}>
                        {ContactSideBar(user)}
                    </Drawer>
                </div>
                <div className="app-module-sidenav d-none d-xl-flex">
                    {ContactSideBar(user)}
                </div>


                <div className="module-box">
                    <DataTable
                        columns={columns}
                        // selectedItem={selectedCompany}
                        columnsData={tableData}
                        tableLabel={getText('app.users.view')}
                        clickable={false}
                    />
                    {/*{DataTable({
                        columns:tableColumns,
                        // selectedItem={selectedCompany}
                        columnsData:tableData,
                        tableLabel:"Users Table",
                        clickable:false
                    })}*/}

                </div>

                <ViewContact setViewUser={setViewUser} viewUser={viewUser} userId={viewUser ? viewUser.id : null}
                             onSubmit={() => {
                                 setViewUser(null)
                             }}/>

                <Dialog open={openForm} onClose={() => {
                }}>
                    <DialogTitle>{getText('app.users.edit_contact')}</DialogTitle>
                    <DialogContent>

                        <Form editableUserId={editableUser ? editableUser.id : null} linkedTo={linkedUser}
                              onSubmit={() => {
                                  setOpenForm(false)
                              }}/>
                    </DialogContent>
                </Dialog>


                <SweetAlert show={message.open}
                            error={message.type === "error"}
                            info={message.type === "info"}
                            warning={message.type === "warning"}
                            success={message.type === "success"}
                            confirmBtnText={message.btnText}
                            showCancel={message.showCancel}
                            onCancel={message.onCancel}
                            title={message.title}
                            confirmBtnStyle={{margin: 0}}
                            onConfirm={() => {
                                if (message.hasOwnProperty("onConfirm")) {
                                    message.onConfirm();
                                }
                                setMessage({
                                    ...message,
                                    open: false
                                });
                            }}>
                    {message.text}
                </SweetAlert>


            </div>
        </div>
    );
};


export default withRouter(View);

