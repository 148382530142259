import React, { useEffect, useState, useContext } from "react";
import IntlMessages from "util/IntlMessages";
import { withRouter } from "react-router-dom";
import "antd/dist/antd.css";
import { SettingsContext } from "../../../../../context/SettingsContext";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import CircularFab from "./CircularFab";
import { getText } from "../../../../../constants/Functions";
import { MainContext } from "../../../../../context/MainContext";
import TextInput from "../../../../../localComponents/TextInput/TextInput";
import { pages } from "../../../../../constants/Variables";
import { changePageName } from "../../../../../actions";
import { CollectContext } from "../../../../../context/CollectContext";
import ContainerHeader from "../../../../../components/ContainerHeader";
import CardBox from "../../../../../components/CardBox";
import ReactJson from "react-json-view";
import { AuthContext } from "../../../../../context/AuthContext";

const View = ({ history, match, classes }) => {
  const { languagesOptions, saveSettings, pricesTypes } =
    useContext(SettingsContext);
  const { authUser } = useContext(AuthContext);
  const { loading, pageName } = useContext(MainContext);
  const { setState } = useContext(CollectContext);

  const [_pricesTypes, setPricesTypes] = useState([]);

  const priceTypeSample = () => {
    const s = {
      id: "",
      isDeletable: true,
      deleted: false,
      title: {},
    };
    languagesOptions.map((language, index) => {
      s.title[language.code] = "";
    });
    return s;
  };

  useEffect(() => {
    setState(changePageName(pages.settings.form));
  }, []);

  useEffect(() => {
    if (languagesOptions.length > 0) {
      if (pricesTypes) {
        setPricesTypes(pricesTypes);
      } else {
        _pricesTypes.length === 0 &&
          setPricesTypes((prevState) => [
            ...prevState,
            { ...priceTypeSample() },
          ]);
      }
    }
  }, [languagesOptions, pricesTypes]);

  const addRow = () => {
    setPricesTypes((prevState) => [...prevState, { ...priceTypeSample() }]);
  };

  const deleteRow = (deletedIndex) => {
    setPricesTypes((prevState) =>
      prevState.filter((i, n) => {
        if (n === deletedIndex && i.id) {
          i.deleted = true;
          return n;
        }
        return true;
      })
    );
  };

  const onChangeRow = (value, language, index) => {
    const { code } = language;
    const n = [..._pricesTypes];
    n[index].title[code] = value;
    setPricesTypes(n);
  };

  const priceTypesRow = (type, index) => {
    const { title, isDeletable, deleted } = type;
    return (
      <div
        className={`flex-row row justify-content-center align-items-center fade show ${
          deleted && "alert-secondary"
        }`}
        key={"price_type_row_" + index}
      >
        {languagesOptions.map((language, _index) => {
          const _id = language.value;
          {
            return (
              <div key={_id + _index} className={"col-lg-2 col-md-4 col-sm-6"}>
                <TextInput
                  id={_id + _index}
                  value={title[language.code]}
                  placeholder={type?.type}
                  onChange={(event) => {
                    let value = event.target.value;
                    onChangeRow(value, language, index);
                  }}
                  // submitting={loading}
                  label={language.label}
                />
              </div>
            );
          }
        })}

        {isDeletable && !deleted && (
          <div className={"col-lg-2 col-md-4 col-sm-6"}>
            <Fab
              onClick={() => {
                deleteRow(index);
              }}
              className="jr-fab-btn bg-secondary text-white jr-btn-fab-sm pull-right"
            >
              <i className="zmdi zmdi-delete zmdi-hc-lg" />
            </Fab>
          </div>
        )}

        {deleted && <span>Click save to to save changes</span>}
      </div>
    );
  };

  return (
    <div className="animated slideInUpTiny animation-duration-3">
      <ContainerHeader
        title={<IntlMessages id="app.products.add" />}
        match={match}
      />
      <div className="row">
        <CardBox styleName={"col-lg-8 justify-content-center"}>
          <form className="row" noValidate autoComplete="off">
            <div className="col-md-12 col-12">
              <h4 className="font-weight-light">
                <IntlMessages id="app.settings.prices_types" />
              </h4>
              {!_pricesTypes && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 400,
                    height: 200,
                  }}
                >
                  <CircularProgress />
                </div>
              )}

              {_pricesTypes && _pricesTypes.map(priceTypesRow)}
              <Fab
                onClick={addRow}
                className="jr-fab-btn bg-secondary text-white jr-btn-fab-sm pull-right"
              >
                <i className="zmdi zmdi-plus zmdi-hc-lg" />
              </Fab>
            </div>
            <div
              className="col-md-12 col-12"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <CircularFab
                onClick={() => {
                  saveSettings(_pricesTypes);
                }}
                loading={loading}
                label={getText("app.settings.save")}
              />
            </div>
          </form>
        </CardBox>
        {authUser.type === "senior" && (
          <CardBox
            cardStyle={{ height: "auto" }}
            styleName={"col-lg-4"}
            heading={<IntlMessages id="app.settings.prices_types" />}
          >
            <ReactJson src={_pricesTypes} displayDataTypes={false} collapsed />
          </CardBox>
        )}
      </div>
    </div>
  );
};

export default withRouter(View);
