import React, {useState, createContext, useEffect, useContext} from 'react';
import {
    Helper,
    usersRules
} from "../constants/Helper";
import {Server} from "../constants/Server";
import {MainContext} from "./MainContext";
import {pages} from "../constants/Variables";
import {AuthContext} from "./AuthContext";
import FullScreenLoading from "../localComponents/Loading/FullScreen";
import {Normal} from "../constants/Normal";

export const RestaurantsContext = createContext();
const RestaurantsContextProvider = (props) => {
        const {setLoading, pageName, setServerResponse} = useContext(MainContext);
        const {authUser, setRules} = useContext(AuthContext);
        const selectedRestaurantState = Normal.getFromStorage("selectedRestaurant", true, true);
        const restaurantJoinedState = Normal.getFromStorage("restaurantJoined", true, true);


        const [restaurantsList, setRestaurantsList] = useState(null);
        const [restaurantJoined, setRestaurantJoined] = useState('no');
        const [selectedRestaurant, setSelectedRestaurant] = useState(selectedRestaurantState);
        const [somethingChanged, setSomethingChanged] = useState(restaurantJoinedState);
        const [restaurantCurrency, setRestaurantCurrency] = useState("");


        const fetchAllRestaurants = () => {
            Server.getAllRestaurants().then((response) => {
                setRestaurantsList(response.data.data);
                Helper.consoleServerResults(response)
            }).catch(e => {
                Helper.consoleServerResults(e.response)
            })
        };

        const fetchRestaurantInfo = () => {
            Server.getRestaurantInfo().then((response) => {
                const {data} = response.data
                if (!data.id) {
                    document.getElementById("AppTitle").text = "Restaurants Menus Dashboard";
                } else {
                    const {id, restaurant_name, firebase_authentication} = data;
                    document.getElementById("AppTitle").text = restaurant_name;
                }



                setRestaurantJoined(!data.id ? null : data);

                Helper.consoleServerResults(response)
            }).catch(e => {
               setRestaurantJoined(null)
                Helper.consoleServerResults(e.response)
            })
        }

        const submitRestaurant = (formData, fun = null) => {
            Server.addNewRestaurant(formData)
                .then(response => {

                    setLoading(false);
                    Helper.consoleServerResults(response, setServerResponse)

                    typeof fun == "function" && fun();

                    setServerResponse(response)
                    fetchAllRestaurants();
                    /*setMessage({
                        open: true,
                        type: "success",
                        btnText: getText('buttons.ok'),
                        title: getText('buttons.done'),
                        text: restaurantId ? getText('app.restaurants.updated_success') : getText('app.restaurants.created_success'),
                        onConfirm: () => {
                            history.push('/app/restaurants/view');
                            setLoading(false);
                        }
                    });*/


                })
                .catch(e => {
                    setServerResponse(e.response)
                    Helper.consoleServerResults(e.response, setServerResponse)
                    setLoading(false)
                    /*setMessage({
                        open: true,
                        type: "error",
                        btnText: getText('buttons.ok'),
                        title: getText('error.title'),
                        text: restaurantId ? getText('app.restaurants.updated_failed') : getText('app.restaurants.created_failed')
                    });*/

                })
        }

        const deleteRestaurant = ({restaurant_id, delete_key = "deactivate"}, fun = null) => {
            restaurant_id && delete_key && Server.deleteRestaurant(restaurant_id, delete_key).then(response => {
                typeof fun == "function" && fun();

                fetchAllRestaurants();
                Helper.consoleServerResults(response, setServerResponse)
            }).catch(e => {
                Helper.consoleServerResults(e.response, setServerResponse)
            });
        }


        useEffect(() => {
            !!authUser && !selectedRestaurant && setRules(usersRules(authUser, false));
            !!authUser && !!selectedRestaurant && setRules(usersRules(authUser, selectedRestaurant));
        }, [authUser, selectedRestaurant])

        useEffect(() => {
            if (!selectedRestaurant) {
                document.getElementById("AppTitle").text = "Restaurants Menus Dashboard";
            } else {
                const {id, restaurant_name, firebase_authentication} = selectedRestaurant;
                document.getElementById("AppTitle").text = restaurant_name;
            }

            selectedRestaurant && setRules(usersRules(authUser, selectedRestaurant));
            selectedRestaurant && authUser && setRestaurantCurrency(selectedRestaurant.currency);
            selectedRestaurant && Normal.saveToStorage("selectedRestaurant", selectedRestaurant, true);
        }, [selectedRestaurant])

        useEffect(() => {
            restaurantJoined && restaurantJoined !== 'no' && setSelectedRestaurant(restaurantJoined);
            if (Normal.getSubDomain()) {
                restaurantJoined && Normal.saveToStorage("restaurantJoined", restaurantJoined, true);
            }

        }, [restaurantJoined])

        useEffect(() => {
            fetchRestaurantInfo();
            // logger("selectedRestaurantState", selectedRestaurantState)
        }, [])
        useEffect(() => {
            (pageName === pages.users.form || pageName === pages.restaurants.form || pageName === pages.restaurants.view) && fetchAllRestaurants();
        }, [pageName])

        // const label = restaurantJoined?`Load ${restaurantJoined.restaurant_name} Restaurant`:'Load Restaurants';

        return (
            <RestaurantsContext.Provider
                value={{
                    restaurantJoined,
                    setRestaurantJoined,
                    selectedRestaurant,
                    setSelectedRestaurant,
                    restaurantCurrency,
                    setRestaurantCurrency,
                    somethingChanged,
                    setSomethingChanged,
                    restaurantsList,
                    setRestaurantsList,
                    fetchAllRestaurants,
                    submitRestaurant,
                    deleteRestaurant
                }}>

                {restaurantJoined === 'no' && <FullScreenLoading text={'Load Restaurant'}/>}
                {restaurantJoined !== 'no' && props.children}
            </RestaurantsContext.Provider>
        );
    }
;

export default RestaurantsContextProvider;
