import React from "react";
import saLang from "../lngProvider/entries/ar_SA";
import EnLang from "../lngProvider/entries/en-US";
import {Normal} from "./Normal";
// import {useIntl} from "react-intl";


export const getText = (id) => {


    try {
        const react_intl = require('react-intl')
        const i = react_intl.useIntl();
        return i.formatMessage({id});
    } catch (e) {
        const loc = Normal.getFromStorage('locale',true,true);
        let cachedLocale;
        try {
            if(loc){
                cachedLocale = loc
            }else{

                throw new Error('none');
            }

        }catch (e){
            cachedLocale = {
                languageId: 'english',
                locale: 'en',
                name: 'English',
                icon: 'us'
            }
        }
        const langs = {
            ar: saLang,
            en: EnLang
        }
        return langs[cachedLocale?.locale]['messages'][id];
    }


}

export function isValidArray(array,greaterThan=0) {
    return array && Array.isArray(array) && array.length > greaterThan
}

export function isSafari(){
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') != -1) {
        if (ua.indexOf('chrome') > -1) {
            return false; //Chrome
        } else {
            return true; //Safari
        }
    }
}

export function playRinging(){
    const audio = new Audio('https://backend-menus.topdesign.pro/sounds/notifications.mp3');
    audio.loop = true;

    console.log("Ringing");

    return  audio;

}
