import React, {useContext, useEffect, useState} from 'react';
import CardBox from 'components/CardBox/index';
import IntlMessages from 'util/IntlMessages';
import DataTable from 'components/Datatables';
import SweetAlert from "react-bootstrap-sweetalert";
import {ButtonGroup} from "reactstrap";
import Button from "@material-ui/core/Button";
import {withRouter} from "react-router";
import {Folder} from "@material-ui/icons";
import {changePageName} from "actions";
import {pages} from "constants/Variables";
import {OrdersContext} from "../../../../../context/OrdersContext";
import OrderView from "./OrderView";
import {AuthContext} from "../../../../../context/AuthContext";
import {MainContext} from "../../../../../context/MainContext";
import {CollectContext} from "../../../../../context/CollectContext";
import {timeConverterUTC} from "../../../../../constants/DatesManager";
import Filter from "./filter";
import {UsersContext} from "../../../../../context/UsersContext";
import {RestaurantsContext} from "../../../../../context/RestsurantsContext";
import {getText} from "../../../../../constants/Functions";
import {SettingsContext} from "../../../../../context/SettingsContext";

const View = ({history, match}) => {


    const {orders_list} = useContext(OrdersContext);
    const {authUser, configuration} = useContext(AuthContext)
    const {usersList} = useContext(UsersContext)
    const {pageName} = useContext(MainContext)
    const {locale} = useContext(SettingsContext)
    const {restaurantCurrency, selectedRestaurant} = useContext(RestaurantsContext)
    const {setState} = useContext(CollectContext);


    const [selectedOrder, setSelectedOrder] = useState(null);

    const isSenior = authUser.type === "senior";


    const [tableData, setTableData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [message, setMessage] = useState({
        open: false,
        type: "success",
        btnText: "Ok",
        title: "Title",
        text: "Some Text"
    });

    const [filters, setFilters] = useState({
        user_id: '',
        state: '',
        total_range: [0, 50000],
        date_range: ['1-06-2021', (new Date()).getTime()]
    })


    const columns = [
        {
            id: 'order_number',
            align: true,
            disablePadding: false,
            label: getText('app.orders.order_number'),
        },
        {id: 'user.full_name', align: true, disablePadding: false, label: getText('app.orders.user')},
        {id: 'total', align: true, disablePadding: false, label: getText('app.orders.total')},
        {
            id: 'created_at',
            align: true,
            disablePadding: false,
            label: getText('app.orders.date') + ' (Baghdad Time)',
            renderedComponent: ({created_at}, index) => {
                return timeConverterUTC(created_at, true, true)
            }
        },
        {
            id: 'state',
            align: true,
            disablePadding: false,
            label: getText('app.orders.status'),
            renderedComponent: ({last_status}, index) => {

                const statusStyle = last_status.includes("sent") ?
                    "text-white bg-primary" :
                    last_status.includes("pending") ?
                        "bg-amber" :
                        last_status.includes("delivered") ?
                            "text-white bg-success" :
                            last_status.includes("canceled") ?
                                "text-white bg-danger" :
                                "text-white bg-grey";


                return (
                    <div className={` badge text-uppercase ${statusStyle}`}>{getText('app.orders.'+last_status)}</div>
                )
            }
        },
        {
            id: 'actions', align: true, disablePadding: false, label: '#', renderedComponent: (row, index) => {

                const disableDelete = !isSenior && !row.id;
                const className = disableDelete ? "bg-default" : "bg-danger";
                return (
                    <ButtonGroup vertical={false}>
                        <Button style={{marginLeft: 5}}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setSelectedOrder(row)
                                    // history.push('/app/categories/edit/' + row.id);
                                }}
                                href={""}>
                            <Folder style={{marginInlineEnd: 10}}/> {getText('app.orders.open')}
                        </Button>
                    </ButtonGroup>

                )

            }
        },
    ];


    useEffect(() => {

        setState(changePageName(pages.orders.view))

        setTableColumns(columns);
    }, [locale]);



    useEffect(() => {
        fetchOrders();
    }, [orders_list, filters]);


    const fetchOrders = () => {
        if (orders_list) {
            // console.log('before',{orders_list})
            let _orders_list = orders_list;

            if (filters.user_id) {
                _orders_list = _orders_list.filter(item => item.user_id == filters.user_id)
                // console.log('user_id',{_orders_list})
            }
            if (filters.state) {
                _orders_list = _orders_list.filter(item => item.last_status == filters.state)
                // console.log('state',{_orders_list})
            }
            if (filters.total_range) {
                _orders_list = _orders_list.filter(item => parseFloat(item.total) >= filters.total_range[0] && parseFloat(item.total) <= filters.total_range[1])
                // console.log('total_range',{_orders_list},filters.total_range)
            }
            if (filters.date_range) {
                _orders_list = _orders_list.filter(item => new Date(item.created_at).getTime() >= new Date(filters.date_range[0]).getTime() && new Date(item.created_at).getTime() <= new Date(filters.date_range[1]).getTime())
                // console.log('date_range',{_orders_list})
            }


            // console.log('after',{_orders_list})
            setTableData(_orders_list);
        }
    };


    return (
        <div>
            {/*<EmptyPage data={orders_list} className="animated slideInUpTiny animation-duration-3">*/}


            <div className="row">
                <CardBox styleName="col-lg-12" cardStyle="mb-0 p-0" heading={<IntlMessages id="app.orders"/>}
                         headerOutside>
                    <Filter filters={[
                        {
                            type: 'single-select',
                            label: getText('app.orders.user'),
                            value: filters.user_id,
                            data: usersList.filter(item => item.user_type == 'user' && item.restaurant_id == selectedRestaurant.id),
                            label_key: 'full_name',
                            label_alternative_key: 'phone_number',
                            value_key: 'id',
                            onSelect: (id) => {
                                setFilters(prevState => ({
                                    ...prevState,
                                    user_id: id
                                }))
                            }
                        },
                        {
                            type: 'single-select',
                            label: getText('app.orders.status'),
                            value: filters.state,
                            data: [
                                {label: getText('app.orders.sent'), value: 'sent'},
                                {label:  getText('app.orders.pending'), value: 'pending'},
                                {label:  getText('app.orders.shipping'), value: 'shipping'},
                                {label:  getText('app.orders.delivered'), value: 'delivered'},
                                {label:  getText('app.orders.canceled'), value: 'canceled'},
                            ],
                            label_key: 'label',
                            value_key: 'value',
                            onSelect: (state) => {
                                setFilters(prevState => ({
                                    ...prevState,
                                    state
                                }))
                            }
                        },
                        {
                            type: 'range-select',
                            label: getText('app.orders.total'),
                            value: filters.total_range,
                            min: 0,
                            max: 50000,
                            valueLabelFormat: (value) => {
                                return `${value} ${restaurantCurrency}`
                            },
                            onChange: (total_range) => {
                                setFilters(prevState => ({
                                    ...prevState,
                                    total_range
                                }))
                            }
                        },
                        {
                            type: 'range-select',
                            label: getText('app.orders.date'),
                            value: filters.total_range,
                            min: 1625097600000,
                            max: new Date().getTime(),
                            style: {
                                margin: '0 5%'
                            },
                            step: 8.64e+7,
                            valueLabelFormat: (value) => {
                                return timeConverterUTC(new Date(value), false, true)
                            },
                            onChange: (date_range) => {
                                setFilters(prevState => ({
                                    ...prevState,
                                    date_range
                                }))
                            }
                        }
                    ]}/>


                    <DataTable
                        columns={tableColumns}
                        // selectedItem={selectedCompany}
                        columnsData={tableData}
                        tableLabel={getText('app.orders')}
                        clickable={false}
                    />
                </CardBox>

            </div>


            <SweetAlert show={message.open}
                        error={message.type === "error"}
                        info={message.type === "info"}
                        warning={message.type === "warning"}
                        success={message.type === "success"}
                        confirmBtnText={message.btnText}
                        title={message.type}
                        confirmBtnStyle={{margin: 0}}
                        onConfirm={() => {
                            message.onConfirm && message.onConfirm()
                            setMessage({
                                ...message,
                                open: false
                            });
                        }}>
                {message.text}
            </SweetAlert>


            <OrderView selectedOrder={selectedOrder} setSelectedOrder={setSelectedOrder}/>

        </div>
    );
};


export default withRouter(View);

